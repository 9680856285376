/**
 * Component to Show Formula Calculation
 *
 *
 * @author Ashique Mohammed
 */

import React, { useEffect, useState, useContext } from 'react';

import './qr-scanner.scss';

import { Scanner } from '@yudiel/react-qr-scanner';

import { Modal, Button, message, Skeleton, Form, Input, Typography, Tag, Empty } from 'antd';

import { ApiUtils, GlobalContext, Location, RangePicker } from 'soxo-bootstrap-core'

import * as moment from 'moment-timezone';

const { Title } = Typography;

/**
 * Qr Scanner
 * 
 * @returns 
 */
export default function QrScanner() {

    var param = Location.search();


    var starttime = moment.tz().startOf('day'),
        endtime = moment
            .tz()
            .endOf('day')
            .startOf('day');

    const { user } = useContext(GlobalContext);

    const [loading, setLoading] = useState(true);

    // 
    const [patients, setPatients] = useState([])

    //Setting Range by default
    const [range, setRange] = useState([starttime, endtime]);


    useEffect(() => {

        loadGuests(range);

    }, [])


    //If starttime and endtime are in uprl
    if (param.start_time) {
        updateRange();
    }

    function updateRange() {
        starttime = moment.tz(param.start_time, 'Asia/Calcutta').startOf('day');

        endtime = moment.tz(param.end_time, 'Asia/Calcutta').startOf('day');

        return starttime, endtime;
    }

    /**
     * 
     */
    async function loadGuests(range) {

        setLoading(true);

        const start_time = moment(range[0]).format('MM/DD/YYYY');

        const end_time = moment(range[1]).format('MM/DD/YYYY');


        const { data = [] } = await ApiUtils.get({
            url: `event-trans/listing-events?start_date=${start_time}&end_date=${end_time}`
            // url: `event-trans/listing-events?start_date=${moment().format("MM/DD/YYYY")}&end_date=${moment().format("MM/DD/YYYY")}`
        })

        setPatients(data);

        setLoading(false);
    }


    /**
     * Read of data
     */
    const onRead = async (opNumber) => {

        // We have to confirm once the opno is present in the front end list . 
        const patient = patients.filter(
            (pat) => pat.reference_number.trim().toLowerCase() === opNumber.toLocaleLowerCase()
        )?.[0];

        const locationIds = JSON.parse(user.other_details).location_ids;

        const eventId = locationIds[patient.package_id];

        const result = await ApiUtils.post({
            url: 'event-trans/create-event',
            formBody: {
                event_id: eventId,
                refrence_id: patient.reference_id,
            }
        })

        if (result.success) {

            Location.navigate({
                url: `event-details/${patient.reference_id}?opb_id=${patient.reference_id}&opno=${patient.reference_number}`
                // url: `/qr-scanner-detail/${item.reference_id}`,
            });

            message.success('Date saved succesfully')

        } else {

            message.success('Date saved succesfully')

        }
    }


    function updateTime(selectedRange) {

        setRange(selectedRange);

        // setPage(1);

        Location.search({
            //  limit: 10 ,
            start_time: moment(selectedRange[0]).format('MM/DD/YYYY'),
            end_time: moment(selectedRange[1]).format('MM/DD/YYYY'),
        });

        loadGuests(selectedRange);
    }

    return (
        <div className='qr-scanner'>

            {
                loading
                    ?
                    <Skeleton /> : <>
                        <div className='page-header'>
                            <RangePicker
                                allowClear={false}
                                inputReadOnly
                                format={'DD/MM/YYYY'}
                                value={range}
                                onChange={time => {
                                    updateTime(time, range);
                                }}
                                ranges={{
                                    Today: [moment(), moment()],

                                    Yesterday: [
                                        moment()
                                            .subtract(1, 'days')
                                            .startOf('day'),
                                        moment()
                                            .subtract(1, 'days')
                                            .endOf('day'),
                                    ],

                                    'This Week': [moment().startOf('week'), moment().endOf('week')],

                                    'Last Week': [
                                        moment()
                                            .subtract(1, 'week')
                                            .startOf('week'),
                                        moment()
                                            .subtract(1, 'week')
                                            .endOf('week'),
                                    ],

                                    'This Month': [moment().startOf('month'), moment().endOf('month')],

                                    'Last Month': [
                                        moment()
                                            .subtract(1, 'month')
                                            .startOf('month'),
                                        moment()
                                            .subtract(1, 'month')
                                            .endOf('month'),
                                    ],
                                }}
                            />

                            <QrButton
                                onRead={onRead}
                            />
                        </div>
                        <CardList dataSource={patients} />
                    </>}

        </div>
    );
}

/**
 * 
 * @param {*} param0 
 * @returns 
 */
function CardList({ dataSource, }) {

    /**
     * 
     * @param {*} item 
     */
    function onClick(item) {

        Location.navigate({
            url: `event-details/${item.reference_id}?opb_id=${item.reference_id}&opno=${item.reference_number}`
        });

        // dispatch({ type: 'index', payload: item.rowIndex });
    }

    return <>

        {
            dataSource.length
                ?
                <>

                    {dataSource.map((item, index) => {
                        // to={`/lab-detail/${item.BillID}`}
                        return (
                            <div
                                key={index}
                                className="report-item card card-shadow"
                                onClick={() => {
                                    onClick(item);
                                }}>

                                <div>
                                    <Title level={5} className="title">
                                        {item.name}
                                    </Title>

                                    <p className="title">
                                        {item.package_name}
                                    </p>

                                    <p className="title">
                                        {item.reference_number}
                                    </p>

                                    <p className="title">
                                        {item.sub_event_name} - <Tag color='processing'>{item.status}</Tag>

                                    </p>
                                </div>
                            </div>
                        );
                    })}
                </>
                :
                <>

                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />

                </>}
    </>
}



const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 8 },
};

const tailLayout = {
    wrapperCol: { span: 8 },
};


/**
 * QR Button
 * 
 * @param {*} param0 
 * @returns 
 */
function QrButton({ onRead }) {

    const [loading, setLoading] = useState(false);

    // const qrReader = useRef(null)
    const [qrScan, setQrScan] = useState(false)

    function toggleQr() {

        setQrScan(true)
    }

    /**
     * 
     * 
     * @param {*} err 
     * @param {*} result 
     */
    const onScan = (result) => {

        let scannedText = ''

        if (result && result.length) {

            scannedText = result[0];

            if (scannedText.rawValue) {

                onRead(scannedText.rawValue)
            } else {

                message.warning('Failed to Scan');

            }
        }
    }

    /**
     * In case the scan fails , we allow manual entry
     * 
     * @param {*} values 
     */
    const onFinish = values => {

        console.log(values);

        if (values.opno) {

            setQrScan(false);

            onRead(values.opno)
        }
    };




    return (<>

        <Button size="small" type="primary floating-button second" onClick={toggleQr}>
            Scan QR
        </Button>
        <Modal
            // width="100vw"
            className="fullscreen-modal"

            style={{ top: 0 }}

            width={'100%'} height="100%"
            destroyOnClose
            title="Scan QR"
            visible={qrScan}
            footer={null}
            onCancel={() => {
                setQrScan(false);
            }}>

            <>

                <Scanner
                    ViewFinder={null}
                    facingMode='environment'
                    classNames={'qr-scanner-widget'}
                    styles={{
                        container: {
                            width: '100%',
                            margin: '0',
                            padding: '0',
                        },
                    }}
                    videoStyle={{
                        width: '100%',
                        height: '100%',
                        border: 'solid',
                        borderWidth: '4px',
                        // borderColor: qrData.length === 0 ? 'red' : 'green',
                    }}
                    containerStyle={{ width: '100%', height: '100%', }}
                    onScan={onScan} />

                {/* <BarcodeScannerComponent
                    width={'100%'}
                    // height={500}
                    onUpdate={onUpdate}
                /> */}

            </>

            <div className='custom-form'>



                <Form
                    {...layout}
                    layout='vertical'
                    name="basic"
                    // initialValues={{ email: 'afil.ahammed@gmail.com', password: '123456' }}
                    onFinish={onFinish}
                // onFinishFailed={onFinishFailed}
                >
                    <Form.Item
                        label="OP No"
                        name="opno"
                        rules={[{ required: true, message: 'Please input op number' }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item {...tailLayout}>
                        <Button loading={loading} type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </Modal>
    </>)
}