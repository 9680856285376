/**
 * Component to Show Result entry  guset information
 *
 *
 * @author Nihala Dilshi
 */

import React, { useEffect, useState } from 'react';

import './opreg-card.scss';

import { Avatar, Typography, Row, Col } from 'antd';

import { useTranslation } from 'soxo-bootstrap-core';

import { UserOutlined } from '@ant-design/icons';

const { Title, Text } = Typography;

/**
 * 
 */
export default function OpregCard({ opReg = {} }) {

     // To Translate to another language
    const { t } = useTranslation();

    return (
        <>
            <div>
                <div className="card card-shadow">
                    <Row gutter={[16, 16]}>
                        <Col xs={24} sm={12} md={8} lg={6} xl={4}>
                            <div style={{ display: 'flex', gap: '0.2rem' }}>
                                <Avatar shape="round" size={50} icon={<UserOutlined />} />

                                <div className="information">
                                    <div>
                                        <Text level={2} type="secondary">
                                            {t('NAME')}
                                        </Text>
                                    </div>
                                    <div>
                                        <span>
                                            <strong>{opReg ? opReg.op_fname + ' ' + opReg.op_lname : null}</strong>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </Col>

                        <Col xs={24} sm={12} md={8} lg={6} xl={3}>
                            <div className="information">
                                <Text type="secondary">{t('DOB')}</Text>
                                <div>
                                    <span>
                                        {/* <strong>{opReg && opReg.op_dob ? DateUtils.formatDate(opReg.op_dob) : null}</strong> */}
                                    </span>
                                </div>
                            </div>
                        </Col>
                        <Col span={3}>
                            <div className="information">
                                <Text type="secondary">{t('PHONE')}</Text>
                                <span>
                                    <strong>{opReg ? opReg.op_phone : null}</strong>
                                </span>
                            </div>
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={6} xl={4}>
                            <div className="information">
                                <Text type="secondary">{t('GENDER')}</Text>
                                <div>
                                    <span>
                                        <strong>{opReg ? opReg.op_gender : null}</strong>
                                    </span>
                                </div>
                            </div>
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={6} xl={4}>
                            <div className="information">
                                <Text type="secondary">{t('PLACE')}</Text>
                                <span>
                                    <strong>{opReg ? opReg.op_place : null}</strong>
                                </span>
                            </div>
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={6} xl={3}>
                            <div className="information">
                                <Text type="secondary">{t('EMAIL')}</Text>
                                <div>
                                    <span>
                                        <strong>{opReg ? opReg.op_email : null}</strong>
                                    </span>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    );
}
