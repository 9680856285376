/**
 * Template Master
 *
 *
 *
 *
 */

import React from 'react';

import Model from './models';

import { ApiUtils, BaseAPI } from 'soxo-bootstrap-core';

class DocDetail extends BaseAPI {
    get id() {
        return 'docdet_id';
    }

    get getEndpoint() {
        return 'doc-details';
    }

    get path() {
        return `doc-details`;
    }

    get getName() {
        return this.modelName;
    }

    get modelName() {
        return `DocDetails`;
    }
    /**
     *
     * Retrieves document details based on various parameters and conditions.
     * Constructs the URL for the API request based on the provided parameters to fetch documents.
     *
     * @param {*} documentModalVisible
     * @param {*} op_no
     * @param {*} visit_id
     * @param {*} bill_id
     * @param {*} mode
     * @param {*} subMode
     * @param {*} user_id
     * @param {*} da_id
     * @returns
     */

    getDocuments = (documentModalVisible, op_no, visit_id, bill_id, mode, subMode, user_id, da_id,isNuradesk) => {
        //For doctor login should show all documents uploaded against the visit
        // if (subMode) {
        //     url = `doc-details/get-details?mode=${mode}&id=${visit_id}&submode=${subMode}&userId=${user_id}&op_no=${op_no}`;
        // } else {
        //     if (da_id) {
        //         url = `doc-details/get-details?mode=${mode}&appointment_id=${da_id}&userId=${user_id}&&op_no=${op_no}&isNuradesk=${true}`;
        //     } else {
        //         //For lab login should show all documents uploaded against the visit from lab only
        //         url = `doc-details/get-details?mode=${mode}&id=${bill_id}&userId=${user_id}&&op_no=${op_no}`;
        //     }
        // }
        let baseParams = `mode=${mode}&userId=${user_id}&op_no=${op_no}`;
        let url = `doc-details/get-details?`;
        // Construct the URL based on the presence of subMode, da_id, and other parameters.
        if (subMode) {
            url += `${baseParams}&id=${visit_id}&submode=${subMode}`;
        } else if (da_id) {
            url += `${baseParams}&appointment_id=${da_id}&isNuradesk=${isNuradesk}`;
        } else {
            url += `${baseParams}&id=${bill_id}&isNuradesk=${isNuradesk}`;
        }
        //To view all documents corresponding to the patient
        if (documentModalVisible) {
            url += `&viewAllDocuments=${documentModalVisible}`;
        }

        return ApiUtils.get({url });
    };

    /**
     * Sends a request to delete a specific document by its ID.
     *
     * @param {number} id - The ID of the document to be deleted.
     *
     *
     */
    deleteDocuments = (id) => {
        return ApiUtils.delete({
            url: `doc-details/remove-file/${id}`,
        });
    };
    // Upload files to docdetails
    uploadFiles = (formData) => {
        return fetch(process.env.REACT_APP_endpoint + `doc-details/upload-file`, {
            // Your POST endpoint
            method: 'POST',
            headers: {
                //   'App-Type': 313,
                //   'App-Version': '1.0.1',
                Authorization: 'Bearer ' + localStorage.access_token,
                // type:'multipart/formData'
            },
            //   credentials: 'include',
            body: formData,
        }).then(
            (result) => {
                return result.json();
            },
            (error) => {
                console.log(error);
                return error;
            },
            (progress) => {
                console.log(progress);
                return progress;
            }
        );
    };

    dataLog = (formBody) => {
        return ApiUtils.post({
            url: `doc-details/data-log`,
            formBody,
        });
    };
    documentUpload = (formBody, onPremiseUrl) => {
        return fetch(onPremiseUrl + `/doc-details/document-upload`, {
            // Your POST endpoint
            method: 'POST',
            headers: {
                //   'App-Type': 313,
                //   'App-Version': '1.0.1',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.access_token,
                // type:'multipart/formData'
            },

            //   credentials: 'include',
            body: JSON.stringify(formBody),
        }).then(
            (result) => {
                return result.json();
            },
            (error) => {
                console.log(error);
                return error;
            },
            (progress) => {
                console.log(progress);
                return progress;
            }
        );
    };

    /**
     * Function would load the Image from the file location
     *
     * @param {*} fileName
     * @returns
     */
    loadFile = async (config, fileName, type, props) => {
        let pdfArrayBuffer = null;

        let pdfUrl = null;

        let apiURL = null;

        // Payload for the request
        let payload = {
            method: 'GET',
            headers: {
                // 'Content-Type': 'application/json',
                db_ptr: process.env.REACT_APP_DB_PTR,

                // ...headers,
                Authorization: 'Bearer ' + localStorage.access_token,
            },
        };
        if ((props && props.consent == 'consent') || (config && config.documentType == 'report')) {
            apiURL = (config.baseUrl || props.onPremiseUrl) + `/doc-details/load-file?location=${fileName}&type=${type}`;
        } else {
            apiURL = (config.baseUrl || props.onPremiseUrl) + 'files/read-file-pdf?file=' + `${fileName}&type=${type}`;
        }
        // var apiURL = (config.baseUrl || process.env.REACT_APP_ON_PREMISE_URL) + `/doc-details/load-file?location=${fileName}&type=${type}`
        // var apiURL = 'http://localhost:3800/' + 'files/read-file-test?file=' + `${fileName}&type=${type}`
        return await fetch(apiURL, payload)
            .then((response) => {
                pdfUrl = response.url;
                return response.arrayBuffer();
            })
            .then((arrayBuffer) => {
                pdfArrayBuffer = arrayBuffer;

                return pdfArrayBuffer;
            })
            .catch((error) => {
                console.error('Error fetching PDF:', error);
            });
    };

    /**
     * Load the files by id
     *
     * @param {*} id
     */
    loadFilesById = (id, onPremiseUrl) => {
        return ApiUtils.get({
            baseUrl: onPremiseUrl,
            url: `/doc-details/load-file-by-id?id=${id}`,
            // url: `doc-details/load-file-img?docdet_location=${fileName}`,
            // url: `doc-details/load-file-by-id?id=${id}`,
        });
    };
}

export default DocDetail;
