/**
 * @description
 * @author Jinishakappil
 *
 *  This Component is used to switch branches
 *
 *
 */
import React, { useState, useEffect, useContext } from 'react';

import { Branch } from '../../../../models';

import { message, Select } from 'antd';

import { LoadingOutlined } from '@ant-design/icons';

import { GlobalContext } from 'soxo-bootstrap-core'

import './branch-switcher.scss';

const { Option } = Select;

export default function BranchSwitcher({ callback }) {

    const [branches, setBranches] = useState([]);

    const [loading, setLoading] = useState(true);

    const { dispatch } = useContext(GlobalContext);

    const [selected, setSelected] = useState(localStorage.db_ptr ? localStorage.db_ptr : process.env.REACT_APP_DB_PTR);

    useEffect(() => {
        getBranches();
    }, []);

    let details;

    /**
     * Get Branch List
     */
    function getBranches() {
        setLoading(true);

        Branch.getBranches().then(async (result) => {

            // parsing the data
            details = await result.result.map((ele) => {
                return {
                    ...ele,
                    ...JSON.parse(ele.br_otherdet1),
                };
            });

            setBranches(details);

            setLoading(false);
        });
    }

    /**
     * Selecting index of branches . And setting name of branch
     *
     * @param {*} value
     */
    function onBranchSwitching(index) {

        // To check the access of switching loaction 
        Branch.getProfile(index).then(async (result) => {

            if (result) {

                // The User info that we need to update to the store
                let userInfo = {
                    ...result,
                    ...{ loggedCheckDone: true },
                };

                localStorage.setItem('userInfo', JSON.stringify(userInfo));

                dispatch({ type: 'user', payload: userInfo });

                callback(index);

                setSelected(index);

            }
        })
    }

    return (
        <div className="branches">
            {loading ? (
                <LoadingOutlined></LoadingOutlined>
            ) : (
                <Select value={selected} onSelect={onBranchSwitching}>
                    {branches.map((ele, key) => {
                        return <Option key={key} value={ele.index}>{ele.br_desc}</Option>;
                    })}
                </Select>
            )}
        </div>
    );
}
