/**
 * Doctor notes  screen
 *
 * @author Jinishakappil
 * @author Ashique
 * @description
 *
 *
 */
import ClinicalInformation from '../emr/components/clinical-information/clinical-information';

import React, { useEffect, useState, useRef, useContext } from 'react';

import VisitInfo from '../emr/components/visit-info/visit-info';

import PatientListResearch from '../imaging/components/patient-list/patient-list';

import './doctor-notes-screen.scss';

import { Form, Typography, Button, Checkbox, Input, message, Skeleton, Col } from 'antd';

import { Location, ReferenceSelect, GlobalContext, useTranslation, ExtraInfoDetail } from 'soxo-bootstrap-core';

import { Questionaire } from './questionaire/questionaire';

import DoctorNotesLabResult from './lab-result/lab-result-doctor-notes';

import { Clinicals, Samples, Doctors, Reports, Appointments } from '../../models';

import { Trends } from './trends/trends';

// import ExtraInfoDetail from '../extra-info-details/extra-info-details';
// import ExtraInfoDetail from '../extra-info-details/extra-info-details';

const { Title } = Typography;

const { TextArea } = Input;

const formItemLayout = {
    layout: 'vertical',
};

/**
 *
 *
 * @param {*} param0
 * @returns
 */
export default function DoctorNotesScreening({
    mode,
    extraInfo, // To manage extra info component
    modeValue, // To mension mode for script execution
    title, // To mension title of extra info
    icon, // To mension icon
    disableCompare,
    disablePagination,
    doctorNote,
    enableClincalEdit,
    enableDoctorSelection,
    disableCheckBox,
    disableDoctorNotes,
    disableQuestionnaire,
    disableTrends,
    disablePublishReport,
    ...props
}) {
    // Variable to save the guest details
    const [guest, setGuest] = useState({});

    const [physican, setPhysican] = useState(false);

    const [loading, setLoading] = useState(true);

    var urlParams = Location.search();

    // const [doctorCode, setDoctorCode] = useState(null);
    const [doctorName, setDoctorName] = useState('');

    const [doctorCode, setDoctorCode] = useState(null);
    const [isDoctorEditable, setIsDoctorEditable] = useState(true);
    const { user = {} } = useContext(GlobalContext); 

    const [referenceId, setReferenceId] = useState(urlParams.opb_id);

    const [form] = Form.useForm();

    const { t, i18n } = useTranslation(); // To Translate to another language

    // let referenceId = urlParams.opb_id;

    useEffect(() => {
        loadGuestDetails();
        fetchAuthDetails();
    }, []);

    /**
     *
     */
    const loadGuestDetails = () => {
        if (urlParams.opb_id) getDoctorNotes(referenceId);
    };

    /**
     * loading servicebaseclinical tran data
     */
    async function getDoctorNotes(referenceId) {
        setLoading(true);
        await Clinicals.getVitalInformation(referenceId).then((result) => {
            let data = result.result[0];

            let doctorCode;
            if (data) {
                doctorCode = data.srvbcd_doctorverifiedcode;
            }

            // set initial values for form
            if (data && data.srvbcd_doctorverified === 'Y') {
                setPhysican(true);
            } else {
                setPhysican(false);
            }

            form.setFieldsValue({ notes: data ? data.srvbcd_doctornotes : null, doctor_code: doctorCode ? doctorCode : null });

            setLoading(false);
        });
    }

    // Function to fetch auth details and check doctor_code
    const fetchAuthDetails = async () => {
        try {
            const { doctor_code, name: doctor_name } = user || {}; 
            
            if (doctor_code) {
                setDoctorCode(doctor_code);
                // doctor_code exists, prepopulate the name and disable editing if enableDoctorSelection is false
                setDoctorName(doctor_name);
            } else {
                // Clear the doctor name if no doctor_code is present
                setDoctorName('');
            }
            setLoading(false);
        } catch (error) {
            console.error('Error fetching profile:', error);
            setLoading(false);
        }
    };
    /**
     *
     * @param {*} e
     */
    function clinicalDataVerifictaion(e) {
        if (e.target.checked) {
            setPhysican(true);
        } else {
            setPhysican(false);
        }
    }

    /**
     * Create/ update record in servicebase clinical tran
     * @param {*} values
     */
    async function onFinish(values) {
        // formbody
        let formBody = {
            doctor_notes: values.notes,
            status: physican ? 'Y' : 'N',
            id: referenceId,
            doctor_visible: doctorNote,
            doctor_code: values.doctor_code,
        };
        let result = await Samples.serviceEntry(formBody);
        if (result.success) {
            message.success('Successfully Updated');
            Location.back();
        } else {
            message.error('failed');
        }
    }

    return (
        <div className="doctor-screening">
            <div className="doctor-note ">
                <div className="page-header">
                    <div className="right page-actions">
                        {/*Extra Info component start  */}
                        {extraInfo ? (
                            <ExtraInfoDetail
                                {...urlParams}
                                // record={urlParams}
                                modeValue={modeValue}
                                title={title}
                                icon={'Button'}
                            />
                        ) : null}
                        {/*Extra Info component end  */}

                        {/* Trends Component */}
                        {disablePublishReport ? null : <MedicantActionButton opNo={guest.opno} config={props.publish_report} />}
                        {/* Trends Component */}

                        {/* Trends Component */}
                        {disableTrends ? null : <Trends reference_id={referenceId} />}
                        {/* Trends Component */}
                    </div>
                    <div className="right page-actions">
                        {/* Questionnaire Component */}
                        {disableQuestionnaire ? null : <Questionaire reference_id={referenceId} mode={mode} />}
                        {/* Questionnaire Component */}
                    </div>
                </div>

                {/** Section one start here.it icnclude guestinformation and clinicalinformation  */}
                <div className="section-1">
                    {/* <div className='doctor-note-section'> */}
                    <div className="guest-information">
                        <VisitInfo
                            mode={'opreg'}
                            disableCheckBox={disableCheckBox}
                            reference_number={urlParams.opno}
                            enableBillnumber={true}
                            startLoading={() => setLoading(true)}
                            onReceiveGuestDetails={(patient) => {
                                setGuest(patient);
                            }}
                            callback={async (values, bills) => {
                                // If the bills length is 1
                                // if (bills.length) {

                                //     // Set to the first guest
                                //     setGuest(bills[0]);
                                // }

                                setReferenceId(values);
                                // If the patient is changed using change pateint button, the url is changed according to the newly selcted customer
                                // For that opb_id, opb_bno and opb_opno are taken from the list of bills

                                let record = bills.filter((ele) => ele.opb_id === values)[0];

                                let redirectLink;

                                if (props.path) {
                                    redirectLink = props.path;
                                }

                                redirectLink = redirectLink.replace(':id', record.opb_id);

                                if (urlParams.opb_id !== record.opb_id)
                                    Location.navigate({
                                        url: `${redirectLink}?opb_id=${record.opb_id}&opb_no=${record.opb_bno}&opno=${record.opb_opno}`,
                                    });

                                // Get doctor notes of the selected bill_id
                                await getDoctorNotes(record.opb_id);
                            }}
                        ></VisitInfo>
                    </div>

                    <div className="clinical-information">
                        {!loading ? (
                            <ClinicalInformation
                                mode={mode}
                                visit_id={referenceId}
                                enableModel={enableClincalEdit}
                                refrence_number={urlParams.opno}
                                refrence_id={urlParams.opb_id}
                            ></ClinicalInformation>
                        ) : null}
                    </div>
                    {/* </div> */}
                </div>
                {/** section one end */}

                {disableDoctorNotes ? null : (
                    <div className="section-2" style={{ marginTop: '20px' }}>
                        <div className="second-container">
                            <div className="page-title" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div className="page-header-left" style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                    {disableCompare ? null : (
                                        <Button type="primary">
                                            {/* <FontAwesomeIcon icon={faShuffle} className="fa-icon fa-sharp" />&nbsp;&nbsp; */}
                                            {t('Compare')}
                                        </Button>
                                    )}
                                </div>
                            </div>
                            {!loading ? (
                                <div className="summary" style={{ display: 'flex', gap: '6px' }}>
                                    <div className="tech-entry-information ">
                                        <PatientListResearch
                                            match={''}
                                            mode={'TECH'}
                                            disablePagination={disablePagination}
                                            doctorNote={doctorNote}
                                            reference_id={referenceId}
                                            {...props}
                                        ></PatientListResearch>
                                    </div>
                                    <div className="result-entry-details">
                                        <DoctorNotesLabResult {...props} mode={mode} bill_id={referenceId}></DoctorNotesLabResult>
                                    </div>
                                </div>
                            ) : null}
                            {/** physcian notes section start */}
                            {!loading ? (
                                <div>
                                    <Form {...formItemLayout} form={form} onFinish={onFinish}>
                                        <Col xs={10} md={10} xl={10}>
                                            <Form.Item
                                                name={'doctor_code'}
                                                label={t('Doctor')}
                                                rules={[
                                                    {
                                                        required: enableDoctorSelection, 
                                                        message: t('Please enter doctor'),
                                                    },
                                                ]}
                                                // initialValue={doctorCode} // Pre-fill the doctor_code if available
                                            >
                                                <ReferenceSelect
                                                    field="do_code"
                                                    label={t('do_name')}
                                                    mode="nura-base"
                                                    model={Doctors}
                                                    disabled={doctorCode ? !enableDoctorSelection : false} // Editable if doctor_code is absent or enableDoctorSelection is true
                                                    placeholder={doctorCode ? doctorName : t('Select a doctor')} // Show doctor_name only if doctor_code is present
                                                />

                                            </Form.Item>
                                        </Col>
                                        <Form.Item name="notes" label={t('Notes')} rules={[{ required: true, message: t('Please Enter Notes') }]}>
                                            <TextArea rows={10} maxLength={4000}></TextArea>
                                        </Form.Item>
                                        <Form.Item name="" label="">
                                            <Checkbox checked={physican} onChange={clinicalDataVerifictaion}>
                                                {' '}
                                                {t('Clinical Data verified')}
                                            </Checkbox>
                                        </Form.Item>
                                        <Form.Item>
                                            <Button type="primary" htmlType="submit">
                                                {t('Submit')}
                                            </Button>
                                        </Form.Item>
                                    </Form>
                                </div>
                            ) : null}

                            {/** physcian note section end */}
                        </div>
                    </div>
                )}
                {/**section 2 starts here . it include radiology information and lab details */}
            </div>
        </div>
    );
}

/**
 *
 */
function MedicantActionButton({ opNo, config }) {


    //
    const { user = {} } = useContext(GlobalContext);


    const { t, i18n } = useTranslation(); // To Translate to another language

    /**
     *
     */
    const callButton = async () => {
        const result = await Reports.callMedicantExe({ config, user, opNo });
    };

    return (
        <>
            <div className="paga-actions">
                {opNo ? (
                    <>
                        <Button
                            type="primary"
                            size={'small'}
                            onClick={() => {
                                callButton();
                            }}
                        >
                            {t('Generate Report')}
                        </Button>
                    </>
                ) : null}
            </div>
        </>
    );
}
