/**
 * Component to Show Result entry  guset information
 *
 *
 * @author Nihala Dilshi
 */

import React, { useEffect, useState } from 'react';

import './result-entry-info.scss';

import moment from 'moment';

import { Avatar, Typography, Row, Col } from 'antd';

import { useTranslation } from 'soxo-bootstrap-core';
import { Opreg } from '../../../../models';
import DisplayNature from '../../../common/components/display-nature/display-nature';

import { UserOutlined } from '@ant-design/icons';

const { Title, Text } = Typography;

export default function EntryInfo({ guest = {} }) {
    const { t, i18n } = useTranslation(); // To Translate to another language

    const [nature, setNature] = useState(null);

    const [data,setData]=useState();

    let gender = null;
    if (guest.opb_gender === 'Male') {
        gender = 'Male';
    } else if (guest.opb_gender === 'M') {
        gender = 'Male';
    }

  
    let opNo = guest.opb_opno;

  

    useEffect(() => {
         // Ensure opNo exists before fetching data
            toGetData(opNo);
        
    }, []);

    //  for getting 'nature and emirate id'
    const toGetData = async () => {
        const response = await Opreg.getOpregDetails(opNo);
         // Check if response and result exist
         if (response && response.result) {
            const nature = response.result.nature;

            setData(response.result); // Assuming response.result has other data
            
      
       
       
       
         // Check if nature exists and then set it
         if (nature) {
            setNature(nature); 
        }
    }
       
    };

 
    return (
        <>
            <div className="guest-info card card-shadow">
                {nature ? <DisplayNature nature={nature} /> : null}
                <Row gutter={[16, 16]} style={{ paddingBottom: '1rem' }}>
                {/* <Row> */}
                    <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                        <div style={{ display: 'flex', gap: '0.2rem' }}>
                            {/* <Avatar shape="round" size={50} icon={<UserOutlined />} /> */}

                            <div className="information">
                                <div>
                                    <Text level={2} type="secondary">
                                        {t('NAME')}
                                    </Text>
                                </div>
                                <div>
                                    <span>
                                        <strong>{guest.opb_name}</strong>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </Col>

                    <Col xs={24} sm={12} md={8} lg={6} xl={3}>
                        <div className="information">
                            <Text type="secondary">{t('AGE')}</Text>
                            <span>
                                <strong>{guest.opb_age}</strong>
                            </span>
                        </div>
                    </Col>
                    <Col span={3}>
                        <div className="information">
                            <Text type="secondary">{t('GENDER')}</Text>
                            <span>
                                <strong>{guest.opb_gender}</strong>
                            </span>
                        </div>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                        <div className="information">
                            <Text type="secondary">{t('APPOINTMENT DATE')}</Text>
                            <span>
                                <strong>{moment(guest.opb_tm).format('dddd, MMM Do')}</strong>
                            </span>
                        </div>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                        <div className="information">
                            <Text type="secondary">{t('MOBILE')}</Text>
                            <span>
                                <strong>{guest.opb_mobile}</strong>
                            </span>
                        </div>
                    </Col>
                    </Row>
                    <Row gutter={[16, 16]} >
                    <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                        <div className="information">
                            <Text type="secondary">{t('ORDER')}</Text>
                            <span>
                                <strong>{guest.opb_bno}</strong>
                            </span>
                        </div>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                        <div className="information">
                            <Text type="secondary">{t('OP NO')}</Text>
                            <span>
                                <strong>{guest.opb_opno}</strong>
                            </span>
                        </div>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                        <div className="information">
                            <Text type="secondary">{t('Emirates ID')}</Text>
                            <span>
                              {data ?( <strong>{data.NationalId}</strong>):null}
                            </span>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    );
}
