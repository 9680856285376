/***
 *
 * Creating a new componet is used to Upload file by selecting  . In this componet opens a modal to upload documents
 *
 * @description
 * @author Hasna
 */
import React, { useState, useEffect, useContext } from 'react';

import { Button, Form, Input, Modal, Table, message, Typography, Alert, Space, Select, Popconfirm, Tag } from 'antd';

import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';

import { FileUpload, useTranslation, GlobalContext,PdfViewer } from 'soxo-bootstrap-core';

import moment from 'moment-timezone';

import { DocDetails, DocFileTypes } from '../../../../../models';

const { TextArea } = Input;

const { Option } = Select;

const { Title } = Typography;

const { Search } = Input;

var layout = {
    layout: 'vertical',
};
let reportTypes = {
    RAW: 'json',
    RPT: 'pdf',
    IMG: 'png',
};

export function DocumentUpload({
    documentModalVisible,
    documentHistory,
    op_no,
    bill_id,
    visit_id,
    labVisit,
    histories,
    subMode,
    documentMode,
    uploadDocument,
    deleteDocument,
    documentCode,
    documentType,
    readonly,
    da_id,
    baseUrl,
    isNuradesk,
    ...props
}) {
    const [isModalVisible, setIsModalVisible] = useState(false);

    const [documents, setDocuments] = useState([]);

    const [fileDetails, setFileDetails] = useState([]);

    const [mounted, setMount] = useState(0);

    const { user = {} } = useContext(GlobalContext);

    var [query, setQuery] = useState('');

    const { t, i18n } = useTranslation(); // To Translate to another language


    useEffect(() => {
        loadDocuments(visit_id, bill_id);

        getFileDetails();
    }, []);

    /**
     * Loads the documents based on the provided visit ID and bill ID.
     * Filters the documents to only include active ones (docdet_active === 'Y').
     *
     * @param {number} visit_id - The visit ID to load documents for.
     * @param {number} bill_id - The bill ID to load documents for.
     */
    async function loadDocuments(visit_id, bill_id) {
        var result = await DocDetails.getDocuments(documentModalVisible, op_no, visit_id, bill_id, documentMode, subMode, user.id, da_id, isNuradesk);
        if (result.data.length) {
            let document = result.data.filter((data) => data.docdet_active === 'Y');
            setDocuments(document);
        } else {
            setDocuments([]);
        }
    }
    /**
     * Fetches the available file types from the API and filters the results based on document code.
     * Updates the state with the filtered file details.
     */
    function getFileDetails() {
        DocFileTypes.get().then((result) => {
            let data = result.result.filter((value) => value.docft_code === 'DOC'); // Filter file types by 'documentCode' attribute

            setFileDetails(data);
        });
    }

    /**
     * Search data
     *
     *
     */

    function onSearch(event) {
        setQuery(event.target.value);
    }

    /**
     * Filter data based on search
     *
     *
     */

    let filtered = documents.filter((record) => {
        query = query.toUpperCase();

        if (query) {
            if (record.docdet_filename && record.docdet_filename.toUpperCase().indexOf(query) != -1) {
                return true;
            } else if (record.opv_dt && record.opv_dt.indexOf(query) != -1) {
                return true;
            }
        } else {
            return true;
        }
    });

    /**
     * Deletes a document record based on its ID and removes it from the current document list.
     *
     * @param {Object} key - Contains document details, including the document ID (key.docdet_id).
     * @param {number} rowIndex - The index of the document in the documents array.
     */

    const deleteDoc = (key, rowIndex) => {
        let response = DocDetails.deleteDocuments(key.docdet_id); // Sends a request to delete the document by its ID.

        documents.splice(rowIndex, 1); // Removes the document from the array by index.

        setDocuments([...documents]);

        message.success('Record deleted');
        // Triggers a re-render or refresh by updating the mount state.
        setMount((prev) => {
            return prev + 1;
        });
    };

    /**
     * Opens the modal dialog to display additional content or actions.
     */
    const showModal = () => {
        setIsModalVisible(true);
    };

    return (
        <div className="action">
            <div className="page-header">
                <div>
                    <Title level={5}>{t('DOCUMENTS')}</Title>

                    {/* <Alert message={t('Have any additional document that you want to record for the customer, Add it here')} type="info" /> */}
                </div>
                <div>
                    {documentHistory ? (
                        <div style={{ display: 'flex' }}>
                            <Search
                                placeholder="Enter Search Value"
                                allowClear
                                style={{ width: 300, marginTop: '10px', marginBottom: '20px' }}
                                onChange={onSearch}
                            />
                        </div>
                    ) : null}
                </div>

                {/** Button For Document upload  */}
                {/** If the histories returns true then hide the button (Show the emr screen contains patient history tab) else show the button.*/}
                {!uploadDocument ? (
                    <Button size="small" disabled={histories || uploadDocument || readonly || documentHistory} onClick={showModal}>
                        <PlusOutlined />
                        {t('Add Document')}
                    </Button>
                ) : null}
            </div>

            <div className="page-content">
                <Table
                    scroll={{ x: true }}
                    pagination={false}
                    dataSource={filtered}
                    columns={[
                        {
                            title: '#',
                            dataIndex: 'index',
                            render: (value, item, index) => index + 1,
                        },

                        {
                            title: t('Document Name'),
                            dataIndex: 'docdet_filename',
                            key: 'docdet_filename',
                        },

                        {
                            title: t('Document Type'),
                            dataIndex: 'docdet_nativefiletype',
                            key: 'docdet_nativefiletype',
                        },

                        {
                            title: t('Document Time'),
                            key: 'uploaded_time',
                            render: (ele) => {
                                // return DateUtils.formatDate(ele.docdet_uploaded_time);

                                return moment.tz(ele.docdet_uploaded_time, '').format('DD/MM/YYYY HH:mm a');
                            },
                        },
                        {
                            title: 'Viewed Status',
                            key: 'viewed_status',
                            render: (ele) => {
                                return (
                                    <span>
                                        <Tag color={ele.viewed_status === 'Y' ? 'green' : 'orange'}>
                                            {ele.viewed_status === 'Y' ? 'Viewed' : 'Not Viewed'}
                                        </Tag>
                                    </span>
                                );
                            },
                        },

                        {
                            title: t('Remarks'),
                            dataIndex: 'docdet_remarks',
                            key: 'docdet_remarks',
                        },

                        {
                            title: t('Document'),
                            key: 'action',
                            render: (record) => {
                                return (
                                    <Space size="middle">
                                        <ViewModal record={record} baseUrl={baseUrl} />
                                    </Space>
                                );
                            },
                        },

                        {
                            title: 'Delete',
                            key: 'delete',
                            render: (text, record, rowIndex) => {
                                return (
                                    <Popconfirm title="Are you sure" onConfirm={() => deleteDoc(record, rowIndex)}>
                                        <Button disabled={deleteDocument || histories || documentHistory} size={'small'} type="dashed">
                                            <DeleteOutlined />
                                        </Button>
                                    </Popconfirm>
                                );
                            },
                        },
                    ]}
                />
            </div>

            {/** Document modal section start  */}

            <Modal
                title={t('Add Document')}
                visible={isModalVisible}
                cancelButtonProps={{ style: { display: 'none' } }}
                okButtonProps={{ style: { display: 'none' } }}
                onOk={() => setIsModalVisible(false)}
                onCancel={() => setIsModalVisible(false)}
                destroyOnClose={true}
            >
                <UploadFile
                    opNo={op_no}
                    billId={bill_id}
                    appointment_id={da_id}
                    visitId={visit_id}
                    fileDetails={fileDetails}
                    labVisit={labVisit}
                    documentMode={documentMode}
                    documentCode={documentCode}
                    documentType={documentType}
                    url={baseUrl}
                    callback={() => {
                        setIsModalVisible(false);
                        loadDocuments(visit_id, bill_id);
                    }}
                />
            </Modal>

            {/** Document modal section End  */}
        </div>
    );
}

/**
 * Handles the file upload process by adding the selected files to the form's state.
 * @param {Array} attachments - Array of file attachments selected by the user.
 */
function UploadFile({ opNo, billId, visitId, callback, labVisit, fileDetails, documentMode, appointment_id, documentCode, documentType, url }) {
    const [form] = Form.useForm(); // Initialize Ant Design form

    const [loading, setLoading] = useState(false); // State to control the loading status of the submit button

    const { t, i18n } = useTranslation(); // To Translate to another language

    /**
     *  Upload file
     */
    function onUpload(attachments) {
        var files = [];

        attachments.map((attachment) => {
            files.push(attachment);
        });
        form.setFieldsValue({ files: files });
    }
    // setting initialvalues accoding to the user
    let defaultfileName = !labVisit ? fileDetails[0].docft_deffilename : fileDetails[1].docft_deffilename;

    /**
     * Converts an ArrayBuffer to Base64 format
     * @param {ArrayBuffer} buffer - File data in ArrayBuffer format
     * @returns {String} - Base64 encoded string
     */
    function arrayBufferToBase64(buffer) {
        let binary = '';
        const bytes = new Uint8Array(buffer);
        const len = bytes.byteLength;
        for (let i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return window.btoa(binary);
    }

    /**
     * Handles form submission and file upload
     * @param {Object} values - Form values including file and metadata
     */
    async function onFinish(values) {
        setLoading(true); // Enable loading state during the upload process

        let formBody;

        var formData = new FormData(); // Create FormData object for file upload

        // Append each uploaded file to the FormData object
        values.files.map((file) => {
            formData.append('files', file);
        });

        // If documentMode is OPBILL then set bill_id as docdet_refptr else set visti
        let docdet_refptr;
        let docdet_refid;
        let docdet_refdetails;

        // Determine document reference pointer and reference details based on documentMode
        if (documentMode === 'OPVISIT') {
            docdet_refptr = visitId;
            docdet_refdetails = 'OPREG details';
        } else {
            if (documentMode === 'OPBILL') {
                formBody = {
                    ...formBody,
                    bill_id: billId,
                    docdet_refptr: billId,
                    docdet_refid: billId,
                    docdet_refdetails: 'OPBILL details',
                };
            } else {
                formBody = {
                    ...formBody,
                    appointment_id: appointment_id,
                    docdet_refptr: appointment_id,
                    docdet_refid: appointment_id,
                    docdet_refdetails: 'APPPOINTMENT details',
                };
            }
        }
        // For image files, append file metadata to the form
        if (documentType === 'IMG') {
            // Filetype , remarks, and op_no  passed to backend
            // If filename is present it is recorded as the filename by default
            formData.append('docdet_filename', values.filename ? values.filename : values.fileType);
            formData.append('docdet_remarks', values.remarks);
            formData.append('docdet_refptr', docdet_refptr); // bill_id or
            formData.append('docdet_refid', docdet_refid); // bill_id , da_id
            formData.append('docdet_refno', opNo); // opno,billno
            formData.append('docdet_refmode', documentMode);
            // formData.append('docdet_refmode', 'OPREG');
            formData.append('docdet_refdetails', docdet_refdetails); // Appointment Details,billetails
            formData.append('appointment_id', appointment_id);
            formBody = formData;
        } else {
            // For pdf  files, convert the file to Base64 format
            const arrayBuffer = await values.files[0].arrayBuffer();

            // Convert ArrayBuffer to Base64
            const base64String = await arrayBufferToBase64(arrayBuffer);

            formBody = {
                ...formBody,
                docdet_filename: values.filename ? values.filename : values.fileType,
                docdet_remarks: values.remarks,
                // docdet_refptr: docdet_refptr,
                // docdet_refid: docdet_refid,
                docdet_refno: opNo,
                docdet_refmode: documentMode,
                docdet_refdetails: docdet_refdetails,
                document_type: 'pdf', //documentType
                document_code: documentCode, //documentCode
                files: base64String,
            };
        }
        // Call the API to upload the document
        // File is uploaded and recorded in docdetails
        DocDetails.documentUpload(formBody, url.baseUrl).then((result) => {
            if (result.message === 'success') {
                message.success('File Uploaded SuccessFully');
                setLoading(false);
                callback();
            }
        });
    }

    // setting deafault filetype according to the user.
    // Set default file type based on whether it's a lab visit or not
    let defaultfileTypes = !labVisit ? fileDetails[0].docft_desc : fileDetails[1].docft_desc;

    return (
        <Form {...layout} form={form} onFinish={onFinish} initialValues={{ filename: defaultfileName, fileType: defaultfileTypes }}>
            <Form.Item label={t('Select File Type')} name="fileType" rules={[{ required: true, message: t('Please select file type') }]}>
                <Select>
                    {/* {fileDetails.map((item, key) => (

                        <Option key={key} value={item.docft_id}>
                            {item.docft_desc}
                        </Option>
                    ))} */}

                    {!labVisit ? (
                        // setting options according to role
                        <Option value={t('Lab Reports')}>{fileDetails[0].docft_desc}</Option>
                    ) : (
                        <Option value={t('Medical Reports')}>{fileDetails[1].docft_desc}</Option>
                    )}
                </Select>
            </Form.Item>

            <br />
            <Form.Item label={t('File Name')} name="filename">
                <TextArea rows={1} />
            </Form.Item>
            <Form.Item label={t('Upload File')} name="files" rules={[{ required: true, message: t('Please select file') }]}>
                <FileUpload callback={(attachment) => onUpload(attachment)} autoUpload={false} />
            </Form.Item>

            <Form.Item
                label={t('Remarks')}
                name="remarks"
                rules={[
                    { required: true, message: t('Please enter remarks') },
                    {
                        max: 250,
                        message: t('Length cannot exceed 250 characters !'),
                    },
                ]}
            >
                <TextArea rows={4} />
            </Form.Item>

            <Form.Item>
                <Button loading={loading} htmlType="submit" type="primary">
                    {t('Submit')}
                </Button>
            </Form.Item>
        </Form>
    );
}

/**
 * To view files uploaded
 * @param { } param0
 * @returns
 */
function ViewModal({ record, baseUrl, callback }) {

    const [IsViewVisible, setIsViewVisible] = useState(false);

    // Variable to convert BytesArray
    var [byteArray, setByteArray] = useState([]);

    const { user = {} } = useContext(GlobalContext);

    const [fileURL, setFileURL] = useState();

    const { t, i18n } = useTranslation(); // To Translate to another language

    const loadFile = async (record) => {
        // Extract file type from the record
        let type = record.docdet_nativefiletype;
        DocDetails.loadFile(baseUrl, record.docdet_location, record.docdet_nativefiletype).then((result) => {
            if (result) {
                // Create a Blob from the result data
                var fileImage = new Blob([result], { type: `application/${type}` });
                var fileURL = URL.createObjectURL(fileImage);

                // var bytearray = Object.keys(result);
                // var arrayelement = Object.values(result);

                // var uint8Array = new Uint8Array(bytearray.length);

                // for (var i = 0; i < uint8Array.length; i++) {
                //     uint8Array[i] = arrayelement[i];
                // }

                // for (var i = 0; i < bytearray; i++) {
                //     var ascii = arrayelement.charCodeAt(i);
                //     uint8Array[i] = ascii;
                // }

                // var report = new Blob([uint8Array], {
                //     type: 'image/jpeg',
                // });

                // To view image
                setFileURL(fileURL);

                // To view pdf
                setByteArray({ data: fileURL });

                // return uint8Array;
            } else {
                setIsViewVisible(false);
                message.warning(result.message);
            }
        });
        let formBody = {
            source: 'MEDWEB',
            module: 'EMR',
            table_name: 'DocDetails',
            primary_key: 'docdet_id',
            primary_key_value: record.docdet_id,
            edited_by: user.id,
            edited_value: 'VIEW',
            edited_field: 'docdet_location',
            branch_id: 1,
            firm_id: 1,
            action: 'VIEW',
        };

        // const dataLog = await DocDetails.dataLog(formBody);

        // callback(dataLog);
    };

    const viewOk = () => {
        setIsViewVisible(false);
    };

    const viewCancel = () => {
        setIsViewVisible(false);
    };

    const viewModal = () => {
        setIsViewVisible(true);
        loadFile(record);
    };

    return (
        <>
            <a onClick={viewModal}>{t('View')}</a>

            <Modal
                width={690}
                title={t('Document')}
                visible={IsViewVisible}
                onOk={viewOk}
                onCancel={viewCancel}
                cancelButtonProps={{ style: { display: 'none' } }}
            >
                <Space>
                    {record.docdet_nativefiletype === 'pdf' ? (
                        <div>
                            <PdfViewer url={fileURL} />{' '}
                            {/* <iframe align="center" src={byteArray.data} width="100%" height="700px"></iframe> */}
                        </div>
                    ) : (
                        <img width={650} src={fileURL} />
                    )}
                </Space>
            </Modal>
        </>
    );
}
/**
 * To download image
 * @param { } param0
 * @returns
 */
function FileViewer({ src, filType }) {
    console.log(src);

    let fileLink;

    if (filType === 'image/png') {
        fileLink = 'image.png';
    } else if (filType === 'application/msword') {
        fileLink = 'document.doc';
    }

    const Download = () => {
        const link = document.createElement('a');
        link.href = src;
        link.download = fileLink;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    return (
        <>
            <Button onClick={Download} type="primary" style={{ float: 'right' }}>
                Download
            </Button>
            <img width={650} src={src} style={{ paddingTop: '10px' }} />
            {/* <iframe
                title="Document Viewer"
                src={src}
                style={{ width: '100%', height: '800px', border: 'none' }}
            /> */}
        </>
    );
}
