/**
 * Registration Model
 *
 *
 * To seperate all login for Registration Process
 *
 */

import React, { useState, useEffect, useContext, useRef } from 'react';

import { Tag } from 'antd';

import Model from './models';

import { ApiUtils } from 'soxo-bootstrap-core';

import moment from 'moment-timezone';

class ResultEntry extends Model {
    constructor() {
        super();

        this.fields = [
            {
                field: 'name',
                caption: 'Name',
            },
            {
                field: 'amount',
                caption: 'Amount',
            },
        ];

        // this.columns = ;
    }

    get id() {
        return 'cold_id';
    }

    get getEndpoint() {
        return 'colldetails';
    }

    get path() {
        return `collections`;
    }

    get getName() {
        return this.modelName + `s`;
    }

    get modelName() {
        return `collection`;
    }

    get columns() {
        return [
            {
                caption: 'Staff',
                field: 'staff.name',
                key: 'staff',
            },
            {
                caption: 'Weight',
                field: 'weight',
                key: 'weight',
            },
            {
                caption: 'Date',
                field: 'created_at',
                key: 'created_at',
            },
        ];
    }

    /**
     *
     *
     * @returns
     */
    getResultColumns() {
        return [
            {
                title: '#',
                key: 'lbtr_particulrs',
                render: (value, item, index) => index + 1,
            },

            {
                title: 'Description',
                dataIndex: 'lbtrsd_particulrs',
                key: 'lbtrsd_particulrs',
            },

            {
                title: 'Value',
                render: (item, record) => {
                    return (
                        <>
                            {/* Valus */}
                            {item.lbtrsd_result}
                        </>
                    );
                },
                key: 'lbtrsd_result',
            },

            {
                width: 150,
                title: 'Range',
                dataIndex: 'lbtrsd_refrange',
                key: 'lbtrsd_refrange',
            },

            {
                title: 'Unit',
                dataIndex: 'lbtrsd_unit',
                key: 'lbtrsd_unit',
            },

            {
                title: 'Actions',
                key: 'actions',
                render: (record, index) => {
                    return (
                        <>
                            <Tag color="success">Done</Tag>
                        </>
                    );
                },
            },
        ];
    }

    /**
     *
     */
    createNew = (id) => {
        return ApiUtils.post({ url: 'opbill/kiosk/' + id });
    };

    /**
     * Function to save lab results
     * @param {*} formBody
     * @returns
     */

    saveLabResult = (formBody) => {
        return ApiUtils.post({
            // baseUrl: 'http://localhost:4000/dev',
            url: 'labtresult/save-lab-result',
            formBody,
        });
    };
    
    saveResults = (props) => {
        var { patient, selectedreport, labReportResult, user, technician, chiefTechnician, remarks } = props;

        console.log(labReportResult);
        var formBody = {
            lbtrs_refmode: 'B',
            lbtrs_refid: patient.opbill[0].opb_id,
            lbtrs_itemptr: selectedreport.lbtr_itemptr || selectedreport.lbt_itemptr, //there is an issue with the itempointer being incorrect in prod.
            lbtrs_issamcolld: 'Y',
            // lbtrs_samcollddttm: '2021-02-05T14:22:59.000Z',
            lbtrs_istestdone: 'Y',
            // lbtrs_testdonedttm: moment.tz(process.env.REACT_APP_TIMEZONE).format('YYYY-MM-DD hh:mm:ss a'),
            lbtrs_repnos: 1,
            lbtrs_isoutsourced: 'N',
            lbtrs_remarks: remarks,
            lbtrs_user: user.email,
            lbtrs_chieftechnician: chiefTechnician,
            lbtrs_technician: technician,
            lbtrs_isclosed: 'N',
            rows: labReportResult.entries.map((entry) => {
                return {
                    perticular_id: entry.lbtr_id,
                    lbtrsd_result: entry.values && entry.values.result ? entry.values.result : '',
                    lbtrsd_codeptr: entry.lbtr_code,
                };
            }),
        };
        return ApiUtils.post({ url: 'labtresult/create-labresult', formBody });
    };

    /**
     * Update existing results
     *
     * @param {*} id
     * @param {*} formBody
     * @returns
     */
    // updateResults = (records) => {
    //     return Promise.all(records.map(this.updateIndividualResult));
    // };

    // updateIndividualResult = (record) => {
    //     if (record.values) {
    //         var formBody = {
    //             lbtrsd_result: record.values.result.toString(),
    //         };
    //         return ApiUtils.put({ url: `labtresultd/${record.lbtrsd_id}`, formBody });
    //     }
    // };

    /**
     *  This Api is used to Update Existing results To labtresultd   and labresult
     */
    updateResults = (records) => {
        let resultdetails = [];

        //lbtrsd_id and value of each edited value is pushed into an array
        records.record.map((ele) => {
            if (ele.values) {
                resultdetails.push({
                    id: ele.lbtrsd_id,

                    result: ele.values.result,
                });
            }
        });

        //Passed to api to update values
        var formBody = {
            id: records.labresult.lbtrs_id,

            technician: records.form.technician,

            chief_technician: records.form.chief_technician,

            remarks: records.form.remarks,
            // Updating lab result in labresult details table
            resultdetails: resultdetails,
        };

        return ApiUtils.put({ url: `labtresult/edit-result`, formBody });
    };
}

export default ResultEntry;
