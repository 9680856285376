/**
 *
 * Component for Previewing templates andsending mail/ sms/ whatsapp
 *
 * @description
 * @author Sneha
 *
 */

import React, { useEffect, useState } from 'react';

// import './print-preview.scss';

import axios from 'axios';

import { ApiUtils } from 'soxo-bootstrap-core';

import { Button, Form, Skeleton, Empty, Radio, Input, Typography, Divider, message, Select } from 'antd';

import { MessageTemplates } from '../../models';

import { PdfViewer } from 'soxo-bootstrap-core';

const { Text } = Typography;

/**
 *
 *
 * @param {*} param0
 * @returns
 */
export default function PreviewMails({ refereneceId, sendMail, templatecode, props, config = {
    recipient: {}
} }) {

    // Extracting the recipient 
    const { recipient } = config;

    const [initial, setInitial] = useState({
        email: recipient.email
    })

    // Variable to save email
    // const [recipient, setRecipient] = useState(config.email);

    // Variable to convert BytesArray
    var [byteArray, setByteArray] = useState(null);

    const [options, setOptions] = useState([])

    //Variable to handle preview button loading
    const [btnloading, setBtnLoading] = useState(false);

    //Variable to handle button loading
    const [submitButtonLoading, setSubmitButtonLoading] = useState(false);

    // Variable to disable and enable button
    const [disable, setDisable] = useState(false);
    // Default to 'email'
    const [mode, setMode] = useState('email');

    const [blob, setBlob] = useState();

    const [reportBlob, setReportBlob] = useState();

    const [file, setFile] = useState()
    //State to set templateCode
    const [templateCode, setTemplateCode] = useState(templatecode[0]);

    const [form] = Form.useForm();

    const { Option } = Select;

    /**
     * This is done to preview pdf on first load
     */
    useEffect(() => {

        preview();

        getTemapltes();

    }, []);

    /**
     * Function to preview result
     *
     *
     */
    async function preview(values) {

        // if (labResults.length && templateCode) {
        setBtnLoading(true);

        let formbody = {
            //Template mode will be MessageTemplates(table which templates are taken)
            mode: config.templateMode,

            id: refereneceId,
            //Template to choose from select
            template_code: templateCode

        };

        let payload = {
            method: 'GET',
            responseType: 'blob',
            headers: {
                db_ptr: props.settings.headers.db_ptr.toString(),
                // db_ptr: process.env.REACT_DB_PTR,
                Authorization: 'Bearer ' + localStorage.access_token,
            },

        };

        let url = process.env.REACT_APP_endpoint + `reporting/print-preview`

        await axios.post(`${url}`, formbody, payload)

            .then((res) => {

                var report = new Blob([res.data], {

                    type: 'application/pdf',

                });

                setBlob(res.data);

                setReportBlob(report);

                let fileURL = URL.createObjectURL(report, { type: `application/pdf` });

                setFile(fileURL);

                let reportData = {
                    report_url: fileURL,
                    report_remote_path: url,
                    report_remote_token: localStorage.access_token
                }

                setByteArray(reportData.report_url)

                setBtnLoading(false);

            })
            .catch(() => ({

                error: true,

                data: null,
            }))
    }
    /**
        * Function to preview result
        *
        *
        */
    async function getTemapltes() {
        //Template mode will be MessageTemplates table
        let formBody = {
            id: templatecode, // Templatecode
            mode: templateMode //Template mode will be MessageTemplates(table which templates are taken)
        }

        try {

            // if (templateMode === 'MessageTemplates') {

            await MessageTemplates.getMsgTemplate({ formBody }).then(async (response) => {

                setOptions(response.data)

            });
            // }

        } catch (error) {
            console.error('Error during getting templates:', error);
        }
    }


    /**
     * To Send the message
     */
    async function sendMessage(values) {

        setSubmitButtonLoading(true);

        let formBody = {
            id: refereneceId,
            template_code: templateCode,
            email: values.email,
            mode: mode,
            message_template: templateCode
        }

        const result = await ApiUtils.post({
            url: `reporting/send-preview`,
            formBody,
        });

        setSubmitButtonLoading(false);

        if (result.success) {

            message.success(result.message)

        } else {

            message.error(result.message)
        }
    }


    /**
     * Function to update the form value
     * 
     * @param {*} field 
     * @param {*} value 
     */
    function onUpdate(field, value) {

        // Update the form with value
        form.setFieldsValue({ [field]: value })
    }
    //setting mode
    let templateMode = config.templateMode;

    /**
        * 
        * Setting selected code
        */
    function selectedCode(event) {
        setTemplateCode(event)
    }

    return (
        <div className="detail-contents">
            <div className="left-sections">

                <div className="bottom">
                    <div className="card-shadow card" >
                        {/* Preview Action Form */}
                        <Form
                            onFinish={sendMessage}
                            form={form}
                            layout='vertical'
                            initialValues={{ ...initial }}
                        >

                            {/* Template Code */}
                            <Form.Item name="template_code" label="Template">

                                {options ? (

                                    <Select
                                        defaultValue={templatecode[0]}
                                        onChange={selectedCode}
                                    >
                                        {/* {options.length ? ( */}
                                        <>
                                            {
                                                options.map((data, key) => (

                                                    <Option value={data.code}>

                                                        {data.description}

                                                    </Option>
                                                ))
                                            }
                                        </>
                                        {/* ) : null} */}

                                    </Select>
                                ) : null}
                            </Form.Item>
                            {/* Template Code Ends */}


                            {/* If the attribute is true the button will show */}
                            {sendMail ? (
                                <>
                                    <Divider plain >SEND VIA</Divider>

                                    <Form.Item name="mode">
                                        <Radio.Group defaultValue='email' onChange={(e) => setMode(e.target.value)} value={mode}>
                                            <Radio value="email">Email</Radio>
                                            {/* <Radio value="phone">Phone</Radio> */}
                                        </Radio.Group>
                                    </Form.Item>

                                    {mode === 'email' && (
                                        <Form.Item name='email' label="Email">
                                            {props.isEmailEditable ? (
                                                <Input
                                                    type="email"
                                                    onChange={(e) => onUpdate('email', e.target.value)}
                                                />
                                            ) : (
                                                <Input
                                                    type="email"
                                                    readOnly
                                                />
                                            )}
                                        </Form.Item>
                                    )}

                                    {/* {mode === 'phone' && (
                                        <Form.Item
                                            name="whatsapp"
                                            label="WhatsApp Number"
                                            rules={[
                                                { required: true, message: 'Enter WhatsApp number!' },
                                                { pattern: /^[0-9]{10,}$/, message: 'Please enter a valid number!' },
                                            ]}
                                        >
                                            <Input placeholder="Enter WhatsApp Number" />
                                        </Form.Item>
                                    )} */}
                                </>
                            ) : null}
                            <Button size='small' onClick={preview} loading={btnloading} disabled={disable} className="preview-button" type="secondary">
                                Preview
                            </Button>
                            {/*  */}
                        </Form>

                    </div>
                </div>
            </div>

            <div className="right-sections">
                {/* Send Button */}
                {sendMail ? (
                    <Button disabled={disable} loading={submitButtonLoading} className="preview-button" type="primary" onClick={() => form.submit()} style={{ marginBottom: '10px', display: 'flex', justifyContent: 'flex-end' }}>
                        Send
                    </Button>
                ) : null}
                {/* Report Preview */}
                {byteArray || btnloading ? (
                    <div className="report-container">
                        {btnloading ? (
                            <>
                                <Skeleton />
                            </>
                        ) : (
                            <div>

                                <PdfViewer url={byteArray} />
                                {/* <iframe src={byteArray} style={{ width: '100%', height: '573px' }} /> */}
                            </div>
                        )}
                    </div>
                ) : (
                    <Empty />
                )}
                {/* Report Preview Ends */}
            </div>
        </div>
    );
}