/**
 *
 * Component for displaying patient nature
 *
 *
 */

import React, { useState, useEffect } from 'react';
import { Badge, Avatar ,Ribbon,Card} from 'antd';

import './display-nature.scss';

// Define a mapping of nature codes to colors
const natureColor = {
    'General': 'green', 
    'Royal': 'gold',
    'Executive':'purple',
   
    'Default': 'blue', // Default color if nature value is not in colorMap
  };

  export default function DisplayNature({ nature }) {
    // Clean up and log the nature value 
    const cleanedNature = nature.trim();
   
  
    // Get the color from the map, default to 'blue' if nature is not in colorMap
    const ribbonColor = natureColor[cleanedNature] || natureColor['Default'];
  
   
    
    return (
      <div className="display-nature">
        <Badge.Ribbon text={cleanedNature} color={ribbonColor}>
          
        </Badge.Ribbon>
      </div>
    );
  }