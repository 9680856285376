import React from 'react';

import { useState, useEffect } from 'react';

import { Tag, Typography, Row, Col, Button, Popconfirm, message, Avatar } from 'antd';

import './guest-info.scss';

import { Location, ApiUtils, useTranslation, DateUtils } from 'soxo-bootstrap-core';

import moment from 'moment';
import DisplayNature  from '../display-nature/display-nature';


// const { t, i18n} = useTranslation();
const { Title, Text } = Typography;

let paidValues = {
    Y: 'Paid',
    N: 'Pending',
    P: 'Partially Paid',
};

export default function GuestInfo({
    guest = {},
    // data of process status
    data = {},
    id,
    guestreg = {},
    guestdr = {},
    guestopreg = {},
    guestprereg = {},
    guestitm = {},
    guestitem = {},
    guestevent = {},
    params = {},
    urlParams = {},
    disableQuestionnaire = false,
    nature
}) {
    // Guest
    let { opbill = {} } = guest;

    if (!opbill) {
        opbill = { opb_paidflg: 'N' };
    }

    const [key, setKey] = useState(null);

    const { t, i18n } = useTranslation(); // To Translate to another language
    

    useEffect(() => {
        // getToken(guest.da_id)
        if (guest && guest.da_id) getQuestionnaireKey(guest.da_id);
    }, [guest]);

    /**
     * Get the questionnaire key
     *
     * @param {*} id
     */
    async function getQuestionnaireKey(id) {
        const result = await ApiUtils.get({ url: `doctor-appointment/questionnaire-key/${id}` });

        setKey(result.key);
    }

    //function to Reschedule Appointment
    function rescheduleAppointment(record) {
        let url = `/new-registrations/slots?da_doctorptr=${record.da_doctorptr}&da_packagetypevalue=${record.da_packagetypevalue}&appointId=${record.da_id}`;

        Location.navigate({ url: url });
    }




    /**
     * Edit Registration
     *
     *
     */

    function editRegistration() {
        //function to Edit Appointmnet
        var date = moment(guestreg.da_date).format('DD/MM/YYYY');
        // Add time to da_date, to pass selected_time in the url for edit
        date = date + ' ' + guestreg.da_aptime;
        Location.navigate({
            url: `/new-registrations/edit-registration?id=${guestreg.op_id}&edit=true&redirect=registration`,
        });
    }

    console.log("dctrappnmnet",guest)

    // function proceedBilling() {
    //     // &prereg_id=10553&mode=Online

    //     let url = `/new-registration/bill?op_no=${guest.da_newopno}&opreg_id=${guest.da_referenceid}&da_id=${guest.da_id}&da_packagetypevalue=${guest.da_packagetypevalue}`;

    //     Location.navigate({ url: url });
    // }

    /**
     * Redirect to bill if the customer is registered but not billed
     */
    function proceedBilling() {
        Location.navigate({
            url: `/multiple-candidate/bill?op_no=${guestdr.da_newopno}&appointmentId=${guestdr.da_id}`,
        });
    }

    // //function to Start Questionnaire
    // function goQues(id, edit) {
    //     if (edit) {
    //         window.open(`${process.env.REACT_APP_QUES_KEY}/user/questionnaire?id=${id}&key=${key}&edit=true`, '_blank');
    //     } else {
    //         window.open(`${process.env.REACT_APP_QUES_KEY}/user/questionnaire?id=${id}&key=${key}`, '_blank');
    //     }
    // }

    function goQues(id, edit) {
        if (edit) {
            window.open(`${process.env.REACT_APP_QUES_KEY}/user/questionnaire?id=${id}&key=${key}&edit=true&index=${guest.da_branchptr}`, '_blank');
        } else {
            window.open(`${process.env.REACT_APP_QUES_KEY}/user/questionnaire?id=${id}&key=${key}&index=${guest.da_branchptr}`, '_blank');
        }
    }
    //function to Edit Appointmnet
    var date = moment(guest.da_date).format('DD/MM/YYYY');
    // Add time to da_date, to pass selected_time in the url for edit
    date = date + ' ' + guest.da_aptime;

    function editAppointment() {
        Location.navigate({
            url: `/new-registrations/edit-guest?da_id=${guest.da_id}&da_packagetypevalue=${guest.da_packagetypevalue}&id=${guest.da_referenceid}&prereg_opno=${guest.da_newopno}&selected_time=${date}&edit=true&redirect=appointment`,
        });
    }

    //Appointment data fiiling for icarus
    function CompleteProfile() {
        Location.navigate({
            url: `/new-registrations/complete-profile?da_id=${guest.da_id}&da_packagetypevalue=${guest.da_packagetypevalue}&id=${guest.da_referenceid}&prereg_opno=${guest.da_newopno}&selected_time=${date}&edit=true&redirect=appointment`,
        });
    }

   

    //to check if Date of Birth is available
    var age = null;

    if (guestprereg && !guestprereg.prereg_dob) {
        age = guestprereg.prereg_ageyear;
    }

    //Apointmnet Guest Deatil
   

    if (guest.da_id) {
        return (
            <>
                {/* {(guestprereg?.prereg_batch_mode === 'PARENT' || guestprereg?.prereg_batch_mode === 'CHILD') ? (
                        <div>
                            <Button
                                size="middle"
                                type="primary"
                                style={{ marginRight: '5px' }}
                                onClick={CompleteProfile}
                            >
                                Complete Profile
                            </Button>
                        </div>
                    ) : null} */}

                {/*Guest Information */}

                <div className="guest-info card-shadow card">
                    {/* <div>
                        <h6>{guest && guest.da_fname ? (guest.da_fname.toUpperCase()) : null} {guest && guest.da_lname ? (guest.da_lname.toUpperCase()) : null} booked an appointment for {moment(guest.da_time).format('dddd, MMM Do')}</h6>
                    </div> */}

                    {/*Guest Detail */}
                    <div>
                        <Title
                            // style={}
                            level={5}
                        >
                            {t('GUEST INFORMATION')}
                            {nature ? (<DisplayNature nature={nature}/>):null}

                        </Title>
                    </div>
                    <div style={{ display: 'contents' }}>
                        {/* First Row */}

                        <Row>
                            <Col span={6}>
                                <div style={{ display: 'flex', gap: '0.2rem' }}>
                                    <Avatar style={{ backgroundColor: 'gray', margin: 0 }} size="large">
                                        {guest.da_fname.charAt(0).toUpperCase()}
                                    </Avatar>

                                    <div className="information">
                                        <div>
                                            <Text level={2} type="secondary">
                                                {t('NAME')}
                                            </Text>
                                        </div>
                                        <div>
                                            <span>
                                                <strong>
                                                    {guest && guest.da_title ? guest.da_title : null}{' '}
                                                    {guest && guest.da_fname ? guest.da_fname.toUpperCase() : null}{' '}
                                                    {guest && guest.da_lname ? guest.da_lname.toUpperCase() : null}{' '}
                                                </strong>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col span={6}>
                                <div className="information">
                                    <Text type="secondary">{t('DATE')}</Text>
                                    <span>
                                        {/* <strong>{moment(guest.da_time).format('dddd, MMM Do')}</strong> */}
                                        <strong>{DateUtils.formatDate(guest.da_time)}</strong>
                                    </span>
                                </div>
                            </Col>
                            <Col span={4}>
                                <div className="information">
                                    <Text type="secondary">{t('AGE')}</Text>
                                    <span>
                                        <strong>{guestprereg && guestprereg.prereg_ageyear ? guestprereg.prereg_ageyear : null}</strong>
                                    </span>
                                </div>
                            </Col>
                            <Col span={5}>
                                <div className="information">
                                    <Text type="secondary">{t('DOB')}</Text>
                                    <span>
                                        <strong>{guestprereg && guestprereg.prereg_dob ? DateUtils.formatDate(guestprereg.prereg_dob):null}</strong>
                                    </span>
                                </div>
                            </Col>
                            <Col span={3}>
                                <div className="information">
                                    <Text type="secondary">{t('GENDER')}</Text>
                                    <span>
                                        <strong>{guestprereg && guestprereg.prereg_gender ? guestprereg.prereg_gender : (guest ? guest.da_gender : null)}</strong>
                                    </span>
                                </div>
                            </Col>
                        </Row>

                        {/* Second Row */}
                        <Row>
                            <Col span={8}>
                                <div className="information">
                                    <Text type="secondary">{t('ADDRESS')}</Text>
                                    {guestprereg ? (
                                        (guestprereg && guestprereg.prereg_address1) || (guestprereg && guestprereg.prereg_address2) ? (
                                            <Row>
                                                <span>
                                                    <strong>
                                                        {guestprereg && guestprereg.prereg_address1 ? guestprereg.prereg_address1 : (guest ? guest.da_add1 : null)},
                                                        {/* <br /> */}
                                                        {guestprereg && guestprereg.prereg_address2 ? guestprereg.prereg_address2 : (guest ? guest.da_add2 : null)},
                                                        {/* <br /> */}
                                                        {guestprereg && guestprereg.prereg_zip ? guestprereg.prereg_zip : null}
                                                    </strong>
                                                </span>
                                            </Row>
                                        ) : guest.da_opno ? null : (
                                            <Row>
                                                <small style={{ color: 'orange' }}>{t('Remarks')}:{t('Address is Required')}</small>
                                            </Row>
                                        )
                                    ) : guest ? (
                                        <Row>
                                            <span>
                                                <strong>
                                                    {guest.da_add1}
                                                    <br />
                                                    {guest.da_add2}
                                                    <br />
                                                    {guest.da_zip1}
                                                    {/* {guestprereg && guestprereg.da_place ? guestprereg.da_place : null} */}
                                                </strong>
                                            </span>
                                        </Row>
                                    ) : null}
                                </div>
                            </Col>

                            <Col span={8}>
                                <div className="information">
                                    <Text type="secondary">{t('Email')}</Text>

                                    <span>
                                        {' '}
                                        <strong>{guestprereg && guestprereg.prereg_email ? guestprereg.prereg_email : (guest ? guest.da_email : null)} </strong>
                                    </span>
                                </div>
                            </Col>

                            <Col span={8}>
                                <div className="information">
                                    <Text type="secondary">{t('USERNAME')}</Text>

                                    <span>
                                        {' '}
                                        <strong>{guestprereg && guestprereg.prereg_username ? guestprereg.prereg_username : (guest ? guest.da_user : null)} </strong>
                                    </span>
                                </div>
                            </Col>
                            </Row>
                            <Row>
                            <Col span={8}>
                                <div className="information">
                                    <Text type="secondary">{t('PHONE NUMBER')}</Text>

                                    <span>
                                        {' '}
                                        <strong>{guestprereg && guestprereg.prereg_mobile1 ? guestprereg.prereg_mobile1 : (guest ? guest.da_mobile : null)} </strong>
                                    </span>
                                </div>
                            </Col>
                            <Col span={8}>
                                <div className="information">
                                    <Text type="secondary">Emirates ID</Text>

                                    <span>
                                        {' '}
                                        <strong>{guestprereg && guestprereg.prereg_othernatid1? guestprereg.prereg_othernatid1: null} </strong>
                                    </span>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>

                {/* appoinment information start */}
                {/* Package Name */}
                <div className="guest-info card-shadow card">
                    <div>
                        <Title
                            // style={}
                            level={5}
                        >
                            {t('APPOINTMENT INFORMATION')}
                        </Title>
                    </div>
                    <div style={{ display: 'contents' }}>
                        <Row>
                            <Col span={8}>
                                <div className="information">
                                    {/* Package */}
                                    <Text type="secondary">{t('PACKAGE')}</Text>
                                    <span>
                                        {' '}
                                        <strong>{guestitem && guestitem.itm_desc ? guestitem.itm_desc : null} </strong>
                                    </span>
                                </div>
                            </Col>
                            <Col span={8}>
                                <div className="information">
                                    {/*Token Number */}
                                    <Text type="secondary">{t('TOKEN NUMBER')}</Text>
                                    <span>
                                        {' '}
                                        <strong>{guest && guest.da_tokenno ? guest.da_tokenno : null} </strong>
                                    </span>
                                </div>
                            </Col>
                            <Col span={8}>
                                <div className="information">
                                    <Text type="secondary">{t('APPOINTMENT AT')}</Text>
                                    <div>
                                        <span>
                                            <strong>
                                                {moment(guest.da_date).format('DD/MM/YYYY')} {guest.da_aptime}
                                            </strong>
                                        </span>
                                        {guest.da_billid ? null : (
                                            <Popconfirm
                                                title={t("Are you sure you want to Reschedule this appointment ? ")}
                                                onConfirm={() => rescheduleAppointment(guest)}
                                                onCancel={() => {}}
                                                okText="Yes"
                                                cancelText="No"
                                            >
                                                <Button
                                                    size="small"
                                                    type="primary"
                                                    // type="link"
                                                    // shape="round"
                                                    style={{ margin: '0px 10px' }}
                                                >
                                                    {t('RESCHEDULE')}
                                                </Button>
                                            </Popconfirm>
                                        )}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={8}>
                                <div className="information">
                                    <Text type="secondary">{t('APPOINTMENT PAID')}</Text>
                                    <span>{guest.da_paystatus === 'F' ? <Tag color="green">{t('Paid')}</Tag> : <Tag color="orange">{t('Pending')}</Tag>}</span>
                                </div>
                            </Col>
                            <Col span={8}>
                                <div className="information">
                                    <Text type="secondary">{t('SOURCE')}</Text>
                                    <span>
                                        <strong>
                                            {guest.da_sourcetype === 'NDES' ? t('STAFF') : guest.da_sourcetype === 'NWEB' ? t('WEBSITE') : null}
                                        </strong>
                                    </span>
                                </div>
                            </Col>
                            <Col span={8}>
                                <div className="information">
                                    <Text type="secondary">{t('REMARKS')}</Text>
                                    <strong>{guest.da_remarks}</strong>

                                    <span>{guest.da_paystatus === 'F' ? <Tag color="green">{t('Paid')}</Tag> : <Tag color="orange">{t('Pending')}</Tag>}</span>
                                </div>
                            </Col>
                        </Row>
                    </div>

                    {/*Registration Number */}

                    {/* <div className="detail-element-card">
                            <Row span={12}>
                                <span>Registered Status</span>
                            </Row>
                            <Row span={12}>
                                <span>{guestopreg && guestopreg.op_no ? <Tag color="green">Booked</Tag> : <Tag color="orange">Pending</Tag>}</span>
                            </Row>
                        </div> */}

                    {/*Consent Status */}
                    {/* <div className="detail-element-card">
                            <Row span={12}>
                                <span>Consent Status</span>
                            </Row>
                            <Row span={12}>
                                <span>{guest.da_consentfilled ? <Tag color="green">Submitted</Tag> : <Tag color="orange">Pending</Tag>}</span>
                            </Row>
                        </div> */}

                    {/*Payment Status */}
                    {/* <div className="detail-element-card">
                            <Row span={12}>
                                <span>Bill Paid</span>
                            </Row>
                            <Row span={12}>
                                <Tag color={opbill.opb_paidflg === 'Y' ? 'green' : 'orange'}>{paidValues[opbill.opb_paidflg]}</Tag>

                            </Row>
                        </div> */}
                </div>
                {/*Questionnaire Status */}
                <div className="card-shadow card" style={{ display: 'flex', flexDirection: 'row' }}>
                    <Title level={5}>{t('QUESTIONNAIRE STATUS')}</Title>

                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: '10px' }}>
                        <span> {guest.da_questfilled === 'F' ? <Tag color="green">{t('Filled')}</Tag> : <Tag color="orange">{t('Pending')}</Tag>}</span>

                        {/* Show the questionnaire link only once the key is loaded */}

                        <>
                            {key ? (
                                <span>
                                    {guest.da_questfilled === 'F' ? (
                                        <Button
                                            size="small"
                                            type="primary"
                                            // shape="round"
                                            // style={{ background: '#FAC141', justifyContent: ' space-between' }}
                                            onClick={() => {
                                                goQues(guest.da_id, true);
                                            }}
                                        >
                                            {t('Edit Questionnaire')}
                                        </Button>
                                    ) : (
                                        <Button
                                            size="small"
                                            type="primary"
                                            // shape="round"
                                            // style={{ }}
                                            onClick={() => {
                                                goQues(guest.da_id);
                                            }}
                                        >
                                            {t('Start Questionnaire')}
                                        </Button>
                                    )}
                                </span>
                            ) : null}
                        </>
                        <>
                            {/* <span style={{ marginLeft: '6px' }}> */}
                            {/* <Button */}
                            {/* onClick={() => { */}
                            {/* Location.navigate({ url: `/appointment/${record.appointment.id}` }); */}
                            {/* }} */}
                            {/* type="secondary" */}
                            {/* size="small" */}
                            {/* color="green" */}
                            {/* style={{ background: '#DCDFE4', color: '#626F86' }} */}
                            {/* > */}
                            {/* View */}
                            {/* {t('View')}  */}
                            {/* </Button> */}
                            {/* </span> */}
                        </>
                    </div>
                    {/* </Row>  */}
                </div>
                {/* </div> */}
                {/* <div className="information card-shadow card"> */}
                {/* <span>REGISTRATION INFORMATION</span> */}

                {/* <div className="information-right"> */}
                {/* <span> {guest.da_questfilled === 'F' ? <Tag color="green">Filled</Tag> : <Tag color="orange">Pending</Tag>}</span> */}
                {/* <span> */}
                {/* <Button */}
                {/* onClick={() => { */}
                {/* Locati on.navigate({ url: `/appointment/${record.appointment.id}` }); */}
                {/* }} */}
                {/* type="secondary" */}
                {/* size="small" */}
                {/* color="green" */}
                {/* style={{ background: '#DCDFE4', color: '#626F86' }} */}
                {/* > */}
                {/* View */}
                {/* {t('View')} */}
                {/* </Button> */}
                {/* </span> */}
                {/* </div> */}
                {/* </div> */}

                {/* Billing Information */}

                <div className="card-shadow card" style={{ display: 'flex', flexDirection: 'row' }}>
                    <Title level={5}>{t('BILLING INFORMATION')}</Title>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: '10px' }}>
                        <span> {data.billed_status === 'Billed' ? <Tag color="green">{t('Billed')}</Tag> : <Tag color="orange">{t('Pending')}</Tag>}</span>
                        {/* {guest.da_billid ? (
                            <span>
                                <Button
                                    onClick={() => {
                                        proceedBilling();
                                    }}
                                    type="secondary"
                                    size="small"
                                    color="green"
                                    style={{ background: '#DCDFE4', color: '#626F86' }}
                                >
                                    View
                                </Button>
                            </span>
                        ) : null} */}
                    </div>
                </div>

                {/* <Avatar shape="square" size={64} icon={<UserOutlined />} /> */}
            </>
        );
    } else if (guestreg) {
        // /Registration Details

        return (
            <>
                {guestdr.da_billid ? null : (
                    <div className="edit-appointment">
                        {/* <Button
                            onClick={() => {
                                Location.navigate({
                                    url: `/new-registration/guest?da_id=${guest.da_id}&da_packagetypevalue=${guest.da_packagetypevalue}&prereg_id=${guest.da_referenceid}&prereg_opno=${guest.da_newopno}&edit=true&redirect=registration`,
                                });
                            }}
                            type="ghost"
                            size={'small'}
                            shape="round"
                            style={{ background: '#FAC141', justifyContent: ' space-between"' }}
                        >
                            Edit Registration
                        </Button> */}
                    </div>
                )}

                {/*Guest Details */}
                <div className="guest-info card ">
                    <>
                    {/*Display Patient Nature */}
                    {nature ? (<DisplayNature nature={nature}/>):null}

                    </>
                    

                    <div gutter={0} className="detail-wrapper">

                        <div className="detail-element">
                            <Row span={12}>
                                <span className="details">{t('Name')}</span>

                            </Row>
                            <Row span={12}>
                                <span>
                                    <strong>
                                        {guestreg.op_fname ? guestreg.op_fname.toUpperCase() : null}{' '}
                                        {guestreg.op_lname ? guestreg.op_lname.toUpperCase() : null} ({guestreg.op_gender ? guestreg.op_gender : null}
                                        ) - {guestreg.op_age ? guestreg.op_age : null}
                                    </strong>
                                </span>
                                
                            </Row>
                        </div>

                        <div className="detail-element">
                            <Row span={12}>
                                <span className="details">{t('Address')}</span>
                            </Row>
                            <Row span={12}>
                                <span>
                                    <strong>
                                        {guestreg.op_add1 ? guestreg.op_add1 : null}
                                        <br />
                                        {guestreg.op_add2 ? guestreg.op_add2 : null}
                                        <br />
                                        {guestreg.op_place ? guestreg.op_place : null}
                                    </strong>
                                </span>
                            </Row>
                        </div>
                        <div className="detail-element">
                            <Row span={12}>
                                <span className="details">{t('Contact')}</span>
                            </Row>
                            <Row span={12}>
                                <span>
                                    <strong>{(guestreg && guestreg.op_mobile )? guestreg.op_mobile : (guest && guest.da_mobile) ? guest.da_mobile : null}</strong>
                                </span>
                            </Row>
                        </div>

                        {/* <div className="detail-element-card">
                            <Row span={12}>
                                <span className="details">OP No</span>
                            </Row>
                            <Row span={12}>
                                <span>
                                    <strong>{params.id}</strong>
                                </span>
                            </Row>
                        </div> */}
                        <div className="detail-element-card">
                            <Row span={12}>
                                <span className="details">{t('Email')}</span>
                            </Row>
                            <Row span={12}>
                                <span>
                                    <strong>{guestreg && guestreg.op_email ? guestreg.op_email : null}</strong>
                                </span>
                            </Row>
                        </div>
                        <div className="detail-element-card">
                            <Row span={12}>
                                <Text type="secondary">{t('UserName')}</Text>
                            </Row>
                            <Row span={12}>
                                <span>
                                    <strong>{guestprereg ? guestprereg.prereg_username : null}</strong>
                                </span>
                            </Row>
                        </div>
                        {/*Appointmnet Time */}
                        <div className="detail-element-card">
                            <Row span={12}>
                                <span className="details">{t('Appointment Time')}</span>
                            </Row>
                            <Row span={12}>
                                <span>
                                    <strong>
                                        {moment(guestdr.da_date).format('DD/MM/YYYY')} {guestdr.da_aptime}
                                    </strong>
                                </span>
                            </Row>
                        </div>
                        {/*Package Name */}
                        <div className="detail-element-card">
                            <Row span={12}>
                                <span className="details">{t('Package')}</span>
                            </Row>
                            <Row span={12}>
                                <span>
                                    <strong>{guestitm.itm_desc ? guestitm.itm_desc : null}</strong>
                                </span>
                            </Row>
                        </div>
                        <div className="detail-element-card">
                            <Row span={12}>
                                <span className="details">{t('Emirates ID')}</span>
                            </Row>
                            <Row span={12}>
                                <span>
                                    <strong>{guestreg && guestreg.op_othernatid1? guestreg.op_othernatid1 :  guestprereg. prereg_othernatid1 ? guestprereg.prereg_othernatid1 : null}</strong>
                                </span>
                            </Row>
                        </div>
                        {/*Questionnaire Status */}
                        <div className="guest-info card ">
                            <div className="detail-element-card">
                                <Row span={12}>
                                    <span className="details">{t('Questionnaire Status')}</span>
                                </Row>
                                {guestdr.da_questfilled === 'F' ? (
                                    <Row span={12}>
                                        <Tag color="green">{t('Filled')}</Tag>
                                    </Row>
                                ) : (
                                    <Row>
                                        <Tag color="orange">{t('Pending')}</Tag>
                                        {/* {guest.da_questfilled === 'F' ?
                                        <Button
                                            size="middle"
                                            type="ghost"
                                            shape="round"
                                            style={{ marginTop: '10px', background: '#FAC141', justifyContent: ' space-between' }}
                                            onClick={() => {
                                                goQues(guest.da_id, true);
                                            }}
                                        >
                                            Edit Questionnaire
                                        </Button>
                                    ) : (
                                        <Button
                                            size="middle"
                                            type="ghost"
                                            shape="round"
                                            style={{ marginTop: '10px', background: '#FAC141', justifyContent: ' space-between' }}
                                            onClick={() => {
                                                goQues(guest.da_id);
                                            }}
                                        >
                                            Start Questionnaire


                                        </Button>} */}
                                    </Row>
                                )}
                            </div>
                        </div>
                        {/*Consent Form Status */}
                        <div className="detail-element-card">
                            <Row span={12}>
                                <span className="details">{t('Consent Status')}</span>
                            </Row>
                            {guestdr.da_consentscannedstatus ? (
                                <Row span={12}>
                                    <Tag color="green">{t('Submitted')}</Tag>
                                </Row>
                            ) : (
                                <Row span={12}>
                                    <Tag color="orange">{t('Pending')}</Tag>
                                </Row>
                            )}
                        </div>

                        {/*Payment Status */}

                        <div className="detail-element-card">
                            <Row span={12}>
                                <span className="details">{t('Payment Status')}</span>
                            </Row>
                            {guestdr.da_billid ? (
                                <Row span={12}>
                                    <Tag color="green">{t('Paid')}</Tag>
                                </Row>
                            ) : (
                                <Row span={12}>
                                    <Tag color="orange">{t('Pending')}</Tag>
                                </Row>
                            )}
                        </div>

                        {/*Current Status */}
                        {guestevent.Subevtm_desc ? (
                            <>
                                <div className="detail-element-card">
                                    <Row span={12}>
                                        <span className="details">{t('Current Status')}</span>
                                    </Row>
                                    <Row span={12}>
                                        <span>
                                            <strong>{guestevent.Subevtm_desc}</strong>
                                        </span>
                                    </Row>
                                </div>

                                <div className="detail-element-card">
                                    <Row span={12}>
                                        <span>{t('Current Status Remarks')}</span>
                                    </Row>
                                    <Row span={12}>
                                        <span>
                                            <strong>{guestevent.Subevtm_remarks}</strong>
                                        </span>
                                    </Row>
                                </div>
                            </>
                        ) : null}
                    </div>
                </div>
                <div>
                    {guestdr.da_newopno && !guestdr.da_billid ? (
                        <>
                            <div>
                                <Row>
                                    <div>
                                        <Button
                                            className="billing"
                                            onClick={() => {
                                                proceedBilling();
                                            }}
                                            type="secondary"
                                            // size="small"
                                            color="green"
                                        >
                                            {t('Proceed for Billing')}
                                        </Button>
                                    </div>
                                </Row>
                            </div>
                        </>
                    ) : null}
                </div>

                <Button onClick={editRegistration}>{t('Edit')}</Button>
            </>
        );
    }
}
