/**
 * Component to Show Report Link
 *
 *
 *
 */
import React, { useState, useEffect } from 'react';

import { Radio } from 'antd';

import './report-link.scss';

import { useTranslation } from 'soxo-bootstrap-core';

import { SCRFilesLink } from '../../../../models';

export default function ReportLink({ billId, displayColumns, callback }) {

    const { t, i18n } = useTranslation(); // To Translate to another language

    const [selected, setSelected] = useState({
        reports: [],
    });

    //Patients list array
    const [patient, setPatient] = useState({
        opReg: {},
        report: [],
    });

    // variable for final report details
    const [finalReport, setFinalReport] = useState({});
    
    const [reportSelect, setReportSelect] = useState({});

    /* The `useEffect` hook is used to perform side effects in a functional component. In this case, it
    is used to call the `getReports` function when the component is mounted. */
    useEffect(async () => {
        await getReports();
    }, []);

    /**
     * Getting reports
     *
     */
    async function getReports() {
        //Load report with billId
        const result = await SCRFilesLink.getReportDetails(billId);
        setPatient(result.data);

        if (result.data.report && result.data.report[0] && result.data.report[0].report_details) {
            let selectedReport = {
                report_type: 'RPT',
                report_path: result.data.report[0].scrrept_filename,
                report_id: result.data.report[0].scrrept_id,
            };

            // The first report is selected here

            setFinalReport({ ...selectedReport });

            setSelected({ ...selectedReport });
            // loadPDF(result.data.report[0].report_details[0])
        }
    }

    const onChange = e => {
        setReportSelect(e.target.value);
    };
    /**
     * The onClick function sets the selected report, calls a callback function with the selected
     * report, and updates the selected report state.
     * @param selectedReport - The `selectedReport` parameter is an object that contains information
     * about a specific report. It likely has properties such as `report_id`, `report_name`,
     * `report_type`, etc. The `onClick` function is a handler function that is called when a report is
     * selected or clicked on. It updates
     */
    function onClick(selectedReport) {
        setReportSelect(selectedReport.report_id);
        callback(selectedReport);
        setSelected({ ...selectedReport });
    }

    const onSelectFinalReport = () => {

        let selectedReport = {
            report_path: finalReport.report_path,
            type: 'full',
            report_type: 'RPT',
            report_id: finalReport.report_id,
        };

        onClick(selectedReport);

    }


    return (
        <div>
            <div className="card report-link card-shadow">
                <div className="card-title" style={{ width: '100%' }}>
                    <h3>{t('REPORTS')}</h3>
                </div>

                {/* Radio Group Starts */}
                <Radio.Group value={reportSelect} onChange={onChange} >
                    <div className='report-tabs-wrapper'>

                        {/* Final Report Tab */}
                        <div className='report-tab'>
                            <div
                                className={`test-name card card-shadow ${selected.report_id === finalReport.report_id ? 'active' : null}`}
                                onClick={onSelectFinalReport}>

                                {/* manage reportlist radio button and grid style */}
                                {displayColumns ? (

                                    <Radio value={finalReport.report_id}
                                    >
                                        <p> {t('Final Report')} </p>
                                    </Radio>

                                ) : (
                                    <p> {t('Final Report')} </p>
                                )}
                            </div>
                        </div>
                        {/* Final Report Tab Ends */}

                        {/* Sub Report Starts */}
                        {patient.report && patient.report[0]
                            ? patient.report[0].report_details.map((result, index) => {
                                return (
                                    <div className='report-tab' key={index}>
                                        <div
                                            className={`test-name card card-shadow ${selected.report_id === result.scrreptd_id ? 'active' : ''}`}
                                            onClick={() => {
                                                let selectedReport = {
                                                    report_path: result.scrreptd_filename,
                                                    type: 'sub',
                                                    report_type: 'RPT',
                                                    report_id: result.scrreptd_id,
                                                };


                                                onClick(selectedReport);

                                            }}
                                            style={{ backgroundColor: selected.report_id === result.scrreptd_id ? '#e6f7ff' : '' }}
                                        >
                                            {displayColumns ? (

                                                <Radio value={result.scrreptd_id}>
                                                    {index + 1}.{result.report_master_details.srepmd_desc}
                                                </Radio>

                                            ) : (
                                                <p>
                                                    {index + 1}.{result.report_master_details.srepmd_desc}
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                );
                            })
                            : null}
                        {/* Sub Report Starts */}

                    </div>
                </Radio.Group>
                {/* Radio Group Ends */}

            </div>
        </div>
    );
}
