/**
 * Component to Show scrreporttran details
 *
 *
 * @author Sameena
 * @author Ashique Mohammed 
 * @author Jinisha 
 * 
 * The component was extended to show a modal view friendly for mobile 
 * 
 */

import React, { useEffect, useState, useContext } from 'react';

import { SCRFilesLink } from '../../../../models';

import { Skeleton, Modal } from 'antd';

import { Location, GlobalContext } from 'soxo-bootstrap-core';

import { ReportPreviewFileLoader } from './../../../../components/report-preview/report-preview';

import './report-links-viewer.scss';

import ReportLink from '../report-link/report-link';

import OpregCard from '../../../../components/opreg-card/opreg-card';
/**
 *
 * @returns
 */
export default function ReportPreview(props) {

    var params = Location.search();

    // Variable to decide if the modal is closed or not
    const [visible, setVisible] = useState(false);

    //Get logged user data
    const { user, isMobile } = useContext(GlobalContext);

    const [selected, setSelected] = useState({
        reports: [],
    });

    const [loading, setLoading] = useState(true);

    const [patient, setPatient] = useState({
        opReg: {},
        report: [],
    }); //Patiants list array

    useEffect(() => {

        initializeScreen();


    }, []);

    /**
     * 
     */
    async function initializeScreen() {

        // const opreg = await Opreg.getOpregDetails(params.opno);

        // console.log(opreg);

        // 
        await getReports();

    }

    /**
     * Getting reports
     *
     */
    async function getReports() {

        //Load report with accession no
        await SCRFilesLink.getReportDetails(params.opb_id, params.op_no).then(result => {
            setPatient(result.data);

            if (result.data.report && result.data.report[0] && result.data.report[0].report_details) {

                let selectedReport = {
                    report_type: 'RPT',
                    report_path: result.data.report[0].scrrept_filename,
                    report_id: result.data.report[0].scrrept_id,
                };

                setSelected({ ...selectedReport });

                setLoading(false);
            }
        });
    }

    const closeModal = () => {

        setVisible(false);
    }

    let { opReg = {} } = patient;

    return (
        <>
            {loading ? (
                <Skeleton />
            ) : (
                <>
                    <div className="report-preview report-links-viewer">
                        {/* <div className='title'>
                            <Title className='heading' level={7} style={{ fontSize: '24px' }}>
                                Reports
                            </Title>
                        </div> */}

                        <div className="page-header">
                            <div className="page"></div>
                            <div className="actions"></div>
                        </div>

                        <OpregCard opReg={opReg} />

                        <div className="details-section">
                            <div className="left-section">
                                <div>
                                    <ReportLink
                                        billId={params.opb_id}
                                        displayColumns={false}
                                        showRadioButtons={false}
                                        callback={values => {
                                            setSelected(values);

                                            // For Mobile open the modal
                                            if (isMobile) {
                                                setVisible(true);
                                            }
                                        }}
                                    />
                                </div>
                            </div>

                            {/* Right Section */}
                            {
                                isMobile
                                    ?
                                    null
                                    :
                                    <div className="card right-section card-shadow">
                                        <div className="tab-section">
                                            {selected && selected.report_path ? (
                                                <>
                                                    <ReportPreviewFileLoader selected={selected} config={props.reportConfig} />
                                                </>
                                            ) : null}
                                        </div>
                                    </div>
                            }
                            {/* Right Section Ends */}

                            {/* In case of Mobile */}
                            <Modal
                                onOk={null}
                                onCancel={closeModal}
                                footer={null}
                                width="100vw"
                                className="fullscreen-modal"
                                visible={visible}
                                style={{ top: 0 }}
                            // bodyStyle={{ height: '100vh' }}
                            >
                                <ReportPreviewFileLoader selected={selected} config={props.reportConfig} />
                            </Modal>
                            {/* In case of Mobile Ends */}

                        </div>
                    </div>
                </>
            )}
        </>
    );
}
