/**
 * 
 * File loader is build specific to Nura case where we need to load the file 
 * 
 * Initially we had seperate application for loading files , 
 * But that did not work well so we have to move to on premise 
 * 
 * @author Ashique Mohammed
 * @author Marvan
 * 
 */
import React, { useEffect, useState, useContext } from 'react';

import { message, Button, Skeleton } from 'antd';

import { DocDetails } from './../../models';

import { PdfViewer } from 'soxo-bootstrap-core';

import moment from 'moment-timezone';

import { useTranslation } from 'soxo-bootstrap-core';



let reportTypes = {
    'RAW': 'json',
    'IMG': 'png',
    'RPT': 'pdf',
    'pdf': 'pdf',
    'png': 'png'
}



/**
 * Component to load the report from file server
 *
 * @param {*} param0
 */
export default function FileLoader({ config = {
    requireLinuxPath: false,
    replaceBranch: false
}, url, type, ...props }) {

    // Variable to save the file url 
    const [digitalreport, setDigitalReport] = useState(null);

    const { t, i18n } = useTranslation();    // To Translate to another language


    let curretDate = moment().format("DD-MM-YYYY") + Math.round(Math.random() * Math.pow(9, 10))

    useEffect(() => {

        // Load the pdf with the url received
        loadPDF(url);
    }, [url]);


    /**
    * Replace the filename with BLR for any other branch
    * This is a custom implementation for Nura
    *
    * @param {*} fileName
    * @returns
    *
    * @author Ashique Mohammed
    */
    function replaceFileName(fileName, requireLinuxPath, replaceFileName) {

        if (replaceFileName) {

            const wordsToReplace = ['DEL', 'BOM', 'HYD'];

            // // The path has to be changed to bangalore
            const replacementString = 'BLR';

            // // To replace if its either of the strings and case insensitive
            // const regex = new RegExp(wordsToReplace.join('|'), 'i');

            // Below is temporary and will be moved to backend

            // #Note For use in mongolia below 
            // return fileName
            //     .replaceAll('\\\\', '////')
            //     .replaceAll('\\', '//')
            //     .replaceAll('tbn-his-appfs', 'TBN-HIS-APPFS')
            //     .trim();
            // return fileName.replace('\\', '/');

            // const wordsToReplace = ["DEL", "BOM"];

            // // The path has to be changed to bangalore
            // const replacementString = "BLR";

            // // To replace if its either of the strings and case insensitive
            // const regex = new RegExp(wordsToReplace.join("|"), "i");

            // const wordsToReplace = ['DEL', 'BOM'];

            // The path has to be changed to bangalore
            // const replacementString = 'BLR';

            // To replace if its either of the strings and case insensitive
            const regex = new RegExp(wordsToReplace.join('|'), 'i');

            fileName = fileName.replace(regex, replacementString);

        } else {

            fileName = fileName

        }
        // return fileName;

        // We need to get the raw path and replace it for use in linux
        // For normal we dont want that . 
        return requireLinuxPath ? String.raw`${fileName}`.replace(/\\/g, '/') : fileName;
    }


    /**
     * Load the file from the particular path
     * 
     * @param {*} report_link 
     * @returns 
     */
    async function loadPDF(report_link) {

        let filePath = report_link;

        // We have 4 cases two handle here 
        // Case 1 - Application hosted on linux and require a replacement for url - No Usecase right now 
        // Case 2 - Application hosted on linux and does not require a replacement for url - For local site - hyderabad 
        // Case 3 - Application hosted on windows an require a replacement of url  - For Remote Usage - delhi / mumbai / hyderabad
        // Case 4 - Application hosted on windows and do not require a replace of url - For Local Site Usage - Mumbai 


        // For the linux vm we require file structure to be changed
        filePath = replaceFileName(report_link, config.requireLinuxPath, config.replaceBranch);
        // if (config.requireLinuxPath) {

        //     // 
        //     filePath = replaceFileName(report_link, config.replaceBranch);

        // } else if ((config.requireLinuxPath && config.replaceBranch) == false) {

        //     filePath = filePath;

        // } else {
        //     filePath = replaceFileName(report_link, true);
        // }

        // message.loading('Loading digital report');

        const uncompressedArray = await DocDetails.loadFile(config, filePath.trim(), reportTypes[type], props);

        var fileImage = new Blob([uncompressedArray], { type: `application/${reportTypes[type]}` });

        var fileURL = URL.createObjectURL(fileImage);

        // window.open(fileURL);

        setDigitalReport(fileURL);

    }

    function downloadJSON() {

        var name = url.fileName ? url.fileName : curretDate;
        var link = document.createElement('a');
        document.body.appendChild(link);
        link.href = digitalreport;
        link.download = name;
        link.click();
        window.URL.revokeObjectURL(digitalreport);
        link.remove();

    }

    /**
    *
    */
    function reportPreview(selected) {

        switch (selected) {
            case 'pdf':
                
                return <PdfViewer url={digitalreport} />
                // return <iframe align="center" src={digitalreport} width="100%" height="700px"></iframe>

            case 'png':
                return <img src={digitalreport} style={{ width: '100%', height: '400px' }} />

            case 'json':
                return (
                    <a target="_blank" rel="noreferrer" className={`report-link vehicle-card card`}>
                        <h1>JSON</h1>
                        <Button onClick={downloadJSON} type="secondary" size={'small'}>
                            {t('Download')}
                        </Button>
                    </a>
                );

            default:
                return <PdfViewer url={digitalreport} />

        }
    }

    return (<>

        {/* {digitalreport ? <img src={digitalreport} style={{ width: '100%', height: '400px' }} /> : null} */}
        {digitalreport ? <div>{reportPreview(reportTypes[type])}</div> : <Skeleton />}

    </>)
}



