/**
 *@description
 @author hasna noufal 
 * This Component used to Record events 
 * Start event
 * End event 
 * Revert Event
 */

import { Table, Button, message, Skeleton } from 'antd';

import React, { useEffect, useState, useContext } from 'react';

import './event-list.scss';

import VisitInfo from '../emr/components/visit-info/visit-info';

import { Location, GlobalContext, useTranslation } from 'soxo-bootstrap-core';

import { EventTransactions } from '../../models';

export default function EventList({ ...props }) {

    const [page, setPage] = useState(1);

    const [limit, setLimit] = useState(10);

    const [loading, setLoading] = useState(false);

    var urlParams = Location.search();

    const [details, setDetails] = useState([]);

    const { user } = useContext(GlobalContext);

    const { t, i18n } = useTranslation();     // To Translate to another language
    
    let otherDetails;

    useEffect(() => {
        getEventTimeline(urlParams);
        
    }, []);
  
    /**
     *get all event list
     * @param {*} urlParams
     */
    function getEventTimeline(value) {
        setLoading(true);

        let disableEndButton = false;

        let disableStartButton = false;
    // get other_details from user table .
    // allow_revert , room_pointer are saved in other details
    // these fields are used for making event list user specific
        if (user.other_details) {
            otherDetails = JSON.parse(user.other_details);
        }
        EventTransactions.getEventsTimeline(value).then(result => {
            setLoading(false);
            let data = result.data.map(element => {
                /**room_pointer is saved in core users . Its used to show event list in user specific*/
                if ( otherDetails && otherDetails.room_pointer) {
                    // The room pointers are not equal . then start Button and end button want to disable for users
                    if (element.room_pointer !== otherDetails.room_pointer) {
                        disableStartButton = true;
                        disableEndButton = true;
                        element = {
                            ...element,
                            disableStartButton: disableStartButton,
                            disableEndButton: disableEndButton,
                        };
                    }
                }

                /** set disable for the end button for 'Room Out' case */
                if (element.status === 'OUT') {
                    disableEndButton = true;
                    element = {
                        ...element,
                        disableEndButton: disableEndButton,
                    };
                    return element;
                } else {
                    return element;
                }
            });
            // set values to the state
            setDetails(data);
        });
    }

    /**
     * Revert the events .Revert Button is user specific
     * @param {*} record
     */
    function eventRevertOption(record) {
        let data = {
            id: record.id,
            refrence_id: urlParams.opb_id,
        };
        EventTransactions.eventRevert(data).then(result => {
            if (result.success) {
                message.success(result.message);
                // setBtnLoading(false);
                getEventTimeline(urlParams);
            } else {
                message.error(result.message);
                getEventTimeline(urlParams);
            }
        });
    }
    /**
     * Onclick of start button
     * @param {*} record
     */
    function onClick(record) {
        let buttonLoading = false;
        /** filtering data . Check there is any ongoing event is present  */

        let filter = details.filter(values => values.status === 'IN');
        /** if there iss any on going events take place set alert to end the event  */
        // if (filter[0]) {
        //     message.error('Please close the On Going Event');
        // } else {
            /** if the there is no on going events. Then we can start next event  */
            onsetEvent({ ...record});
        // }
        /** set button loaing for each record  */
        let data = details.map(ele => {
            /** set a varible to handle loading of button  */
            if (record.location_id === ele.location_id && filter && filter.length === 0) {
                buttonLoading = true;
                return (ele = {
                    ...ele,
                    buttonLoading: buttonLoading,
                });
            } else {
                buttonLoading = false;
                return (ele = {
                    ...ele,
                    buttonLoading: buttonLoading,
                });
            }
        });
        setDetails(data);
    }
    /**
     * submit functionality
     * @param {*} event
     * @param {*} record
     */
    function onsetEvent(record) {
        /** FormBody */
        let formBody = {
             //actually location_id is passed from the  frond end as event_id.
            event_id: record.location_id,
            refrence_id: urlParams.opb_id,
            // room status true in room in case . room status is false in room out case
            // room_status: record.room_status,
        };
        /** create/update eventTran */
        EventTransactions.createEvent(formBody).then(result => {
            if (result.success) {
                message.success(result.message);
                // setBtnLoading(false);
                getEventTimeline(urlParams);
            } else {
                message.error(result.message);
                getEventTimeline(urlParams);
            }
        });
    }

    /**
     *Columns
     */
    const columns = [
        {
            title: '#',
            dataIndex: 'index',
            render: (value, item, index) => {
                return (page - 1) * limit + index + 1;
            },
        },

        {
            title: t('Event'),
            dataIndex: 'location',
            key: 'location',
        },
        {
            title: t('Status'),
            dataIndex: 'status',
            key: 'status',
        },
        {
            title: t('Start Time'),
            dataIndex: 'start_time',
            key: 'start_time',
        },
        {
            title: t('End Time'),
            dataIndex: 'end_time',
            key: 'end_time',
        },
        {
            title: t('Action'),
            fixed: 'right',

            render: record => {
                if (record.start_time) {
                    return (
                        <>
                            <div className="events-end">
                                <Button
                                    loading={record.buttonLoading}
                                    disabled={record.disableEndButton}
                                    onClick={() => {
                                        onsetEvent({ ...record});
                                    }}
                                >
                                    {t('End')}
                                </Button>
                                {/**allow_revert  is used to enable the Revert Button
                                 * And also want to check the event is End  */}
                                {record.disableEndButton &&otherDetails &&  otherDetails.allow_revert ? (
                                    <Button
                                        onClick={() => {
                                            eventRevertOption(record);
                                        }}
                                    >
                                        {t('Revert')}
                                    </Button>
                                ) : null}
                            </div>
                        </>
                    );
                } else {
                    return (
                        <Button
                            disabled={record.disableStartButton}
                            loading={record.buttonLoading}
                            onClick={() => {
                                onClick(record);
                            }}
                        >
                            {t('Start')}
                        </Button>
                    );
                }
            },
        },
    ];

    return (
        <div className="event-details">
            <div className="right">
           
                {/** Guest information Loading  */}
                <div className="event-list">
                    <VisitInfo mode={props.mode} reference_number={urlParams.opno} disableFields={props.disableFields} onReceiveGuestDetails={() => {}}  />
                </div>

                <div>
                    {loading ? (
                        <>
                            <Skeleton></Skeleton>
                        </>
                    ) : (
                        <>
                            {/** Table content  */}
                            <Table scroll={{ x: true }} dataSource={details} columns={columns}  pagination={false} />
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}
