/**
 * Component to Show ScrFile Links details
 *
 *
 * @author Sneha
 */

import React, { useEffect, useState, useContext } from 'react';

import { SCRFilesLink, Doctors, Bills } from '../../../../models';

import { message, Typography, Skeleton, Button, Modal, Form, Popconfirm } from 'antd';

import { Location, GlobalContext, useTranslation } from 'soxo-bootstrap-core';

import FileLoader from './../../../../components/file-loader/file-loader';

import { DeleteOutlined } from '@ant-design/icons';

import OpregCard from '../../../../components/opreg-card/opreg-card';

import { ReportPreviewFileLoader } from './../../../../components/report-preview/report-preview';

import './report-links-viewer.scss';

/**
 *
 * @returns
 */
export default function ReportLinksView({
    reportConfig = {
        baseUrl: 'http://localhost:3800/',
    },
    enableGeneticConsultation,
    enableGenerateReport,
    ...props
}) {
    var params = Location.search();

    const id = params.opb_id;

    let consultation = params.consultation;

    let { enableConsultation } = props;

    //Get logged user data
    const { user, isMobile } = useContext(GlobalContext);

    const [selected, setSelected] = useState({
        reports: [],
        report_links: []
    });

    const [isDoctor, setDoctor] = useState(false);

    const [loading, setLoading] = useState(false);

    //Variable for upload Modal
    const [uploadVisible, setUploadVisible] = useState(false);

    //Variable for upload Modal
    const [reportVisible, setReportVisible] = useState(false);

    // variable for final report details
    const [finalReport, setFinalReport] = useState({});

    const { t } = useTranslation(); // To Translate to another language

    const [patient, setPatient] = useState({
        scrReportmas: [],

        opReg: {},
    }); //Patiants list array


    useEffect(() => {
        getReports();

        // Get final report file name
        // getFinalReport();
        //Check the logged use is doctor or not
        if (user.staff_id) {
            getDoctorDeatils(user.staff_id);
        }
    }, []);

    /**
     * Getting reports
     *
     */

    function getReports() {
        //Load report with accession no
        SCRFilesLink.getReports(params.opb_id, params.item_ptr).then((result) => {

            setPatient(result.data);

            if (result.data.scrReportmas[0]) {
                const availableReport = result.data.scrReportmas.filter((ele) => ele.report_available === true);
                // The first report is selected here

                if (availableReport.length) {

                    setSelected(availableReport[0]);
                }
            }
        });
    }

    // Get doctor details to check logged user is doctor or not
    function getDoctorDeatils(id) {
        Doctors.getDoctorDeatils(id).then((result) => {
            if (result) {
                setDoctor(true);
            }
        });
    }

    // Get Final report Details
    // This function returns the final report file name which is used to load the file
    async function getFinalReport() {
        await Bills.getFinalReport(id).then((result) => {
            setFinalReport({ ...result });
        });
    }

    /**
     *
     * @param {*} data
     *  Load report and send its url to vr
     *
     */
    function loadReport(data) {
        // let report = data.reports[data.reports.length - 1];

        // let reportUrl = process.env.REACT_APP_FILELINK + report.report_filename;

        let imagePath = data.report_links.filter((record) => {
            return record.sflink_type === 'RPT';
        })[0];

        let url =
            `${process.env.REACT_APP_REPORT_LINK}` + `/files/read-file-pdf?type=RPT&file=${encodeURIComponent(imagePath[0].sflink_filename.trim())}`;

        // let reportUrl = report.report_filename

        // let reportData = {
        //     report_url: reportUrl,
        // };

        let reportData = {
            // report_url: fileURL,
            report_remote_path: url,
            report_remote_token: localStorage.access_token,
        };

        console.log('Report Data', reportData);

        sendMessageToCSharp(reportData);
    }

    /**
     *
     * Start the consultation
     */
    function onStartConsultation() {
        // The window.vuplex object gets created when the page starts loading,
        // so we double-check that it exists before using it here.
        // You can skip this step if you're sending a message after the page has loaded.

        let message = {
            url: window.location.href + '&consultation=true',
            op_no: id,
            branch_name: user.firm.f_desc,
            isDoctor: isDoctor,
            room: patient.bill_id + '-' + user.firm.f_code + '-room',
            display_name: patient.op_reg.op_fname + ' ' + patient.op_reg.op_lname + '-' + patient.bill_no + '-' + user.firm.f_desc,
        };

        // console.log(message);

        if (window.vuplex) {
            // The window.vuplex object already exists, so go ahead and send the message.
            sendMessageToCSharp(message);
        } else {
            // The window.vuplex object hasn't been initialized yet because the page is still
            // loading, so add an event listener to send the message once it's initialized.

            window.addEventListener('vuplexready', sendMessageToCSharp);
        }
    }

    /**
     * Send Message to VR .
     */
    function sendMessageToCSharp(message) {
        // This object passed to postMessage() automatically gets serialized as JSON
        // and is emitted via the C# MessageEmitted event. This API mimics the window.postMessage API.

        if (window.vuplex) {
            window.vuplex.postMessage(message);
        }
    }

    /**
     * delete uploaded file
     *
     */
    async function deleteFile(ele) {

        message.loading('Deleting digital report');

        await SCRFilesLink.deleteReport(ele.sflink_id);

        setSelected({
            reports: [],
        });
        // message.success('Report is Deleted');
        getReports();
    }

    /**
     *
     */
    function reportPreview(selected) {
        let location = `${process.env.REACT_APP_REPORT_PATH}/${selected.sflink_type}/` + selected.sflink_filename;

        switch (selected.sflink_type) {
            case 'RAW':
                // return <LoadPDFImg report={selected} />;

                // return (
                //     <a target="_blank" rel="noreferrer" href={selected.report_filename} className={`report-link vehicle-card card`}>
                //         <h1>PACS</h1>
                //         View PACS Image
                //     </a>
                // );
                return (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Popconfirm
                            title={t('Are you sure you want to delete the report ? ')}
                            onConfirm={() => deleteFile(selected)}
                            onCancel={() => { }}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button type="secondary" size={'small'} style={{ width: '15%', alignSelf: 'end', marginBottom: '15px' }}>
                                <DeleteOutlined /> {t('Delete')}
                            </Button>
                        </Popconfirm>
                        <FileLoader url={location} type={selected.sflink_type} />;
                    </div>
                );

                break;

            case 'RPT':
                return (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Popconfirm
                            title={t('Are you sure you want to delete the report ? ')}
                            onConfirm={() => deleteFile(selected)}
                            onCancel={() => { }}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button type="secondary" size={'small'} style={{ width: '15%', alignSelf: 'end', marginBottom: '15px' }}>
                                <DeleteOutlined /> {t('Delete')}
                            </Button>
                        </Popconfirm>
                        <FileLoader url={location} type={selected.sflink_type} />;
                    </div>
                );

                break;

            case 'IMG':
                //  return <LoadPDFImg report={selected} />;
                return (
                    <a target="_blank" rel="noreferrer" href={selected.sflink_filename} className={`report-link vehicle-card card`}>
                        <h1>{t('PACS')}</h1>
                        {t('View PACS Image')}
                    </a>
                );
                break;
        }
    }

    let { opReg = {} } = patient;
    opReg = opReg.opReg;
    let { opbilld = {} } = patient;

    /**
     * Handle Publish Report to open medicant.
     */

    const ReprtPublishfinalReport = () => {
        // Extract the 'publish_report' object from props and store it in 'values'
        let values = props.publish_report;
        // Construct the 'parameter' string by concatenating various values from 'values', 'user.name'
        let parameter =
            values.mode + ',' + values.submode + ',' + values.passcode + ',' + values.passvalue + ',' + user.name + '~' + patient.opReg.opb_opno;
        // Make the POST request to the server
        fetch('http://localhost:3800/report/report-publish', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                parameter,
            }),
        })
            .then((response) => {
                // Check if the response status is 200 (success)
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json(); // Parse the JSON data from the response
            })

            .catch((error) => {
                //Handle any errors that occur during the request
                console.error('Error:', error);
            });
    };

    /**
     * Handle Publish Report to open medicant
     */
    const publishReport = () => {
        // Make the POST request to the server to trigger the print
        fetch('http://localhost:3000/trigger-print', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                // Pass any data you want to send to the server here
                // ID,
                // patientname,
                // opno: guest.op_no
            }),
        })
            .then((response) => {
                // Check if the response status is 200 (success)
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json(); // Parse the JSON data from the response
            })
            .then((data) => {
                // console.log('Server response:', data); // Log the response data
                // setCurrentState(states.continue); // Move to the 'continue' state after receiving the response
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    };

    //Upload Modal
    function uploadModal() {
        setUploadVisible(true);
    }


    /**
     * The render part to display current status
     * of final report at each point
     *
     * @param {*} finalReport
     */
    function finalReportStatus(finalReport) {
        if (finalReport && finalReport.report_present) {
            return '';
        } else if (finalReport && !finalReport.report_present) {
            return ' - Not Available';
        } else {
            return ' - Report Available ';
        }
    }

    const selectFinalReport = () => {
        if (finalReport && finalReport.report_present) {
            loadReport(finalReport);
        }
    }

    return (
        <>
            {loading ? (
                <Skeleton />
            ) : (
                <>
                    <div className="report-links-viewer">

                        <div className="page-header">
                            {/* {opbilld && opbilld[0] && ['NMGS', 'NWGS'].indexOf(opbilld[0]) !== -1 ? */}

                            {/* : null} */}

                            <div className="page"></div>

                            <div className="actions">

                                {enableConsultation ? (
                                    <>{consultation != 'true' ? <Button onClick={onStartConsultation}>{t('Start Consultation')}</Button> : null}</>
                                ) : null}

                                <div className="publish-report">
                                    {/* <div className="report">
                                        <Button size="small" type="primary" onClick={ReprtPublishfinalReport}>
                                            {t('Create Final Report')}
                                        </Button>
                                    </div> */}


                                    <div className="report">

                                        {
                                            enableGenerateReport
                                                ?
                                                <Button size="small" type="primary" onClick={ReprtPublishfinalReport}>
                                                    {t('Create Final Report')}
                                                </Button>
                                                : null
                                        }

                                        {enableGeneticConsultation
                                            ?
                                            <Button size="small" onClick={uploadModal}>
                                                {t('Upload')}
                                            </Button>
                                            :
                                            null
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Guest Card */}
                        <OpregCard opReg={opReg} />
                        {/* Guest Card Ends */}

                        <div className="details-section">
                            <div className="left-section">
                                <div className="card card-shadow">

                                    <div className="card-title" style={{ width: '100%' }}>
                                        <h3>{t('REPORTS')}</h3>
                                    </div>

                                    <div className="left-bottom">
                                        {/**report_present varible tells us if the final report is ready, Using that we control final report  view */}
                                        {/* {finalReport && finalReport.report_present ? */}

                                        <div className='report-tabs-wrapper'>

                                            {/* Report Tabs */}
                                            <div className={`report-tab card card-shadow ${selected.item_ptr === finalReport.item_ptr ? 'active' : null}`} onClick={selectFinalReport}>
                                                {t('Final Report')} {finalReportStatus(finalReport)}
                                            </div>
                                            {/* Report Tabs */}

                                            {
                                                patient.scrReportmas.map((result, index) => {
                                                    return (
                                                        <div
                                                            key={index}
                                                            className={`report-tab test-name card card-shadow ${result.report_available
                                                                ? selected &&
                                                                    selected.opbilld &&
                                                                    selected.opbilld[0].opbd_itemptr === result.opbilld[0].opbd_itemptr
                                                                    ? 'active'
                                                                    : ''
                                                                : 'notactive'
                                                                }`}
                                                            onClick={() => {
                                                                // # Note  We have to implement a prop to control how the report needs to be displayed
                                                                // We have to call this function for the meta viewer
                                                                // reportPreview(result);

                                                                if (result && result.opbilld && result.opbilld[0].opbd_itemptr) {

                                                                    setSelected(result);

                                                                }

                                                                // For Mobile view we have to open the modal
                                                                if (isMobile) {

                                                                    setReportVisible(true)
                                                                }
                                                            }}
                                                        >
                                                            {index + 1}.{result.scrReportMas.srepmd_desc}

                                                            {/* -{' '} */}
                                                            {/* <small>{result.scrReportMas.srepmd_filetype}</small> */}
                                                            {/* <br /> */}
                                                            {/* {result.report_available ? null : (
                                                            <small style={{ color: 'red' }}>{t('Report Not Available')}</small>
                                                        )} */}
                                                        </div>
                                                    );
                                                })
                                            }

                                        </div>


                                    </div>
                                </div>
                            </div>

                            {/* Right Section */}
                            {
                                isMobile
                                    ?
                                    null
                                    :
                                    <div className="card card-shadow right-section">
                                        <div className="tab-section">
                                            {selected ? <ReportLinkPdfViewer selected={selected} reportConfig={reportConfig} /> : null}
                                        </div>
                                    </div>
                            }
                            {/* Right Section Ends */}

                        </div>


                        {/* In case of Mobile */}
                        <Modal
                            onOk={null}
                            onCancel={() => {
                                setReportVisible(false);
                            }}
                            footer={null}
                            width="100vw"
                            className="fullscreen-modal"
                            visible={reportVisible}
                            style={{ top: 0 }}
                        // bodyStyle={{ height: '100vh' }}
                        >
                            <ReportLinkPdfViewer selected={selected} reportConfig={reportConfig} />
                        </Modal>
                        {/* In case of Mobile Ends */}


                        {/* <Modal
                            className="fullscreen-modal"
                            destroyOnClose={true}
                            footer={null}
                            title={t('Report')}
                            visible={reportVisible}
                            okText="Okay"
                            onOk={() => {
                                setReportVisible(false);
                            }}
                            onCancel={() => {
                                setReportVisible(false);
                            }}
                        >

                            <ReportPreviewFileLoader
                                config={reportConfig}
                                selected={{
                                    report_type: 'RPT',
                                    report_path: selected.sflink_filename,
                                }}
                            />

                        </Modal> */}
                        <Modal
                            destroyOnClose={true}
                            footer={null}
                            title={t('Upload')}
                            visible={uploadVisible}
                            okText="Okay"
                            onOk={() => {
                                setUploadVisible(false);
                            }}
                            onCancel={() => {
                                setUploadVisible(false);
                            }}
                        >
                            <UploadFile setVisible={setUploadVisible} accession_no={params.accessionno} getReports={getReports} />
                        </Modal>
                    </div>
                </>
            )}
        </>
    );
}

/**
 * A temporary component to avoid the backend logic . 
 * 
 * Inside im always a front end dev
 * This implementation should have been in the backend to avoid all the 
 * data transfer 
 * 
 * @param {*} param0 
 * @returns 
 */
function ReportLinkPdfViewer({ selected = { report_links: [] }, reportConfig = {} }) {

    let report = null;

    // 
    let reportLink = selected.report_links.filter((fileLink) => {

        return fileLink.sflink_type === 'RPT'
    })

    // 
    if (reportLink.length) {

        report = reportLink[0]
    }

    return (report ? <ReportPreviewFileLoader
        config={reportConfig}
        selected={{
            report_type: 'RPT',
            report_path: report.sflink_filename,
        }}
    /> : null)
}


// }

//Component to uploadFiles
function UploadFile({ setVisible, accession_no, getReports }) {
    const { Title } = Typography;
    const [loading, setLoading] = useState(false);

    const { t, i18n } = useTranslation(); // To Translate to another language

    var [reportPath, setReportPath] = useState();

    var [rawReportData, setRawReportData] = useState();

    //Onsumbit of the modal, both files are send to backend

    async function submit() {
        setLoading(true);

        var formData = new FormData();

        var arr = [];

        // Api params for two api calls for upload
        let apiParams = [];

        // Api params for the pdf file
        apiParams.push({
            file_type: 'File',
            accession_no: accession_no,
            opb_bno: '',
            type: 'RPT',
            file: reportPath,
        });

        // Api params for the raw file
        apiParams.push({
            file_type: 'File',
            accession_no: accession_no,
            opb_bno: '',
            type: 'RAW',
            file: rawReportData,
        });

        // await this.uploadSelectedFiles();
        for (const payload of apiParams) {
            // console.log("PAyload",payload)
            // apiParams.map(async(ele)=>{
            await uploadFile(payload, accession_no);

            // })
        }

        setLoading(false);

        // getReports();
    }

    /**
     * Uploads the file to on premise and calls the
     * nura api to create records in scr file links
     *
     *
     * @param {*} payload
     */
    async function uploadFile(payload, accession_no) {
        let formData = new FormData();
        formData.append('file_type', payload.file_type);
        formData.append('accession_no', payload.accession_no);
        formData.append('type', payload.type);
        formData.append('reportPath', payload.file, {
            filename: `${payload.file}.pdf`,
            contentType: 'application/pdf',
        });
        // formData.append('rawReportData', payload.file, {
        //     filename: `${payload.file}.pdf`,
        //     contentType: 'application/pdf'
        // });

        // console.log('PF', payload.file);
        // console.log('PFkkkkkkk', formData);

        //Files are uploaded and path saved to scrfilelink
        const result = await SCRFilesLink.uploadReport(formData, accession_no);

        getReports();

        // console.log('here 10: ', result);

        if (result && result.status) {
            setVisible(false);
            message.success('Files uploaded successfully');
        } else {
            message.error('Please check the files');
        }
    }

    // On choosing report path file
    function handleReportPath(e) {
        // console.log("handleRepprtpath",e.target.files[0])
        let files = e.target.files[0];

        setReportPath(files);
    }

    //On choosing rawreport data file
    function handleRawRepotData(e) {
        let files = e.target.files[0];

        setRawReportData(files);
    }

    return (
        <div className="card card-shadow">
            <Form onFinish={submit}>
                <>
                    <div>
                        <form id="myform">
                            <br />
                            <Title level={5}>{t('Report Path')}</Title>
                            <label>{t('Select File')}</label>
                            <br />

                            <input type="file" name="reportPath" onChange={(e) => handleReportPath(e)} />
                            <br />
                            <br />
                            <Title level={5}>{t('Raw Report Data')}</Title>

                            <label>{t('Select File')}</label>
                            <br />

                            <input type="file" name="rawReportData" onChange={(e) => handleRawRepotData(e)} />
                            <br />
                            <br />
                        </form>
                    </div>

                    <div></div>
                    <br />
                </>

                <Button loading={loading} htmlType="submit">
                    {t('Submit')}
                </Button>
            </Form>
        </div>
    );
}

