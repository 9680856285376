/**
 * 
 * Initialize Root Application to pass down all the component and models required
 * for the application to run
 * 
 */

import React, { useEffect, useState } from 'react';

import './App.scss';

import { RootApplicationAPI } from 'soxo-bootstrap-core';

import * as CustomComponents from './modules/';

import * as CustomModels from './models/';

// Branch Switcher for switching branch
import BranchSwitcher from './modules/branches/components/branch-switcher/branch-switcher';

function App(props) {

    const [shell, setShell] = useState(false);

    useEffect(() => {

        // let string = window.location.pathname;

        // const start = string.indexOf("token=") + "token=".length;
        // const end = string.indexOf("&", start);

        // const token = string.slice(start, end);
        // if (token) {
        //     console.log('here inside shell mode',token);
        //     setShell(true);
        // }

    }, [])

    /**change in stoptb */
    let spotlightSearchConfig = [

        // {
        //     caption: "Candidates",
        //     search: (query) => {

        //         return Appointments.OpregDetail(query).then((result) => {

        //             return result.map((item) => {
        //                 return {
        //                     path: '/emr/456?op_no=STB1560',
        //                     value: item.op_no,
        //                     label: item.op_fname + ' ' + item.op_lname + ' ' + '-' + ' ' + item.op_mobile + ' ' + '-' + ' ' + item.op_no,
        //                     ...item,
        //                 };
        //             });
        //         });
        //     }
        // },


        // {
        //     caption: "Candidates",
        //     search: (query) => {

        //         return Appointments.OpregDetail(query).then((result) => {

        //             return result.map((item) => {
        //                 return {
        //                     path: '/emr/456?op_no=STB1560',
        //                     value: item.op_no,
        //                     label: item.op_fname + ' ' + item.op_lname + ' ' + '-' + ' ' + item.op_mobile + ' ' + '-' + ' ' + item.op_no,
        //                     ...item,
        //                 };
        //             });
        //         });
        //     }
        // },



        //     {
        //         caption: "OP No",
        //         search: (query) => {

        //             return Bills.getOplist(query).then((result) => {

        //                 if (result.result) {

        //                     return result.result.map((item) => {

        //                         let path = null;

        //                         if (item.visits && item.visits[0].opv_id) {
        //                             path = `/emr/${item.visits[0].opv_id}?op_no=${item.op_no}`
        //                         }

        //                         return {
        //                             path,
        //                             value: item.op_no,
        //                             label: item.op_fname + ' ' + item.op_lname + '-' + item.op_no,
        //                             ...item,
        //                         };
        //                     });
        //                 }
        //             });
        //         }
        //     }
    ]


    // console.log(process.env);

    let appSettings = {
        brandLogo: require(process.env.REACT_APP_BRAND_LOGO),
        heroImage: require(process.env.REACT_APP_HERO_IMAGE),
        footerLogo: require(process.env.REACT_APP_FOOTER_LOGO),
        // registerImage: require(process.env.REACT_APP_REGISTER_IMAGE)

        disableBranchSwitcher: (process.env.REACT_DISABLE_BRANCH_SWITCHER),

        googleMapsAPIKey: process.env.REACT_MAPS_API_KEY,
        googleMapsLibraries: process.env.REACT_MAPS_LIBRARIES,
        headers: {
            db_ptr: localStorage.db_ptr ? localStorage.db_ptr : process.env.REACT_APP_DB_PTR,
        },
        /**
         * Function Defines what auth token to be passed with every api
         * Call
         *
         * @returns
         */
        getToken: async () => {
            return new Promise((resolve) => {

                // console.log('location.pathname', window.location.search);
                // let string = window.location.search;
                // const substring = "token";

                // if (string.includes(substring)) {
                //     const tokenStartIndex = string.indexOf("token=") + "token=".length;
                //     const token = string.substring(tokenStartIndex);

                //     console.log('token', token);

                //     console.log('here inside token');
                //     resolve(token);

                // } else {

                resolve(localStorage.access_token);

                // }



            });
        },

        globalCustomerHeader: () => {

            return (
                <div>


                    {appSettings.disableBranchSwitcher ? null : <BranchSwitcher
                        callback={(values) => {

                            // value of db_ptr
                            localStorage.db_ptr = values;

                            // Passing db_ptr 
                            updateSettings(values);

                        }}
                    ></BranchSwitcher>}

                    {/** We can use this Component to switch branches from one to another */}

                </div>
            );
        },

        callback: ({ mode, params }) => {


            if (mode === 'logout') {

                // Update the 
                settings.headers.db_ptr = process.env.REACT_APP_DB_PTR;

                // Enable this if the branch switcher is creating issues
                // ApiUtils.updateSettings(settings);

                // setSettings({
                //     ...settings
                // })
            }

        }
    };

    let [settings, setSettings] = useState(appSettings);

    /**
     * Function triggered on update of branch
     * 
     * @param {*} index 
     */
    function updateSettings(index) {

        if (index) {

            settings.headers.db_ptr = index;

            setSettings({ ...settings });
        }
    }


    return (
        <div className={`app-start ${shell ? 'shell' : ''}`}>
            <RootApplicationAPI
                CustomModels={CustomModels}
                CustomComponents={CustomComponents}
                appSettings={settings}
                searchConfig={spotlightSearchConfig}
            />
        </div>

    );
}

export default App;







// useEffect(() => {
//     // SettingsUtil.setConfig([{
//     //     endpoint: '/opreg/'
//     // }]);

//     return () => { };
// }, [])