import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Button, Dropdown, Space, MenuProps, Typography, Skeleton, Popconfirm, message, Modal, Form, Input, Tabs, Tag, Row, Menu, Alert } from 'antd';

import { GlobalContext, Location, ConfirmModal, ApiUtils, Camera, useTranslation, ConsentComponent, Trans } from 'soxo-bootstrap-core';

import { Appointments, Customers, DocDetails, Items } from './../../../../models/';
import PaymentLink from '../../../common/components/payment-link/payment-link';
import StatusChange from '../status-change/status-change';
import PreviewMails from '../../../../components/preview-mails/preview-mails';
import UploadId from './../upload-id';

import moment from 'moment-timezone';

import './appointment-detail.scss';

import {
    SendOutlined,
    EyeOutlined,
    MailOutlined,
    DatabaseOutlined,
    DownOutlined,
} from '@ant-design/icons';

import OutboxList from '../../../outbox-list/outbox-list';

import GuestInfo from '../../../common/components/guest-info/guest-info';

import DocumentPreview from './../../../../components/document-preview/document-preview';
// import Payment from '../../../candidate-visit/payment';
// import SendReport from '../../../common/components/send-report/send-report';
import PrintPreview from '../../../common/components/print-preview/print-preview';
// import { func } from 'prop-types';
import PaymentList from '../../../payment-list/payment-list';

import ProfileModel from '../../../common/components/complete-profile-model/complete-profile-model';
// import StatusChange from '../status-change/status-change';
import SendReport from '../../../common/components/send-report/send-report';
import SendImageLink from '../../../common/components/send-image-link/send-image-link';
import PriceFormatter from '../../../common/components/format-price/format-price';
import { DocumentUpload } from '../../../emr/components/emr-screen/documents/documents';

// import AppointmentConsent from './../../../appointment-consent/appointment-consent';

const { Title, Text } = Typography;

const { TabPane } = Tabs;

const layout = {
    labelCol: {
        xs: { span: 8 },
        sm: { span: 8 },
        lg: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 8 },
        sm: { span: 8 },
        lg: { span: 8 },
    },
};

const { TextArea } = Input;
const { confirm } = Modal; // for confirmation

/**
 * Appointment Detail Screen
 *
 * @param {*} props
 * @returns
 */
export default function AppointmentDetail(props) {
    // Url Params
    var param = Location.search();

    const [range, setRange] = useState([
        moment.tz(param.start_time, process.env.REACT_APP_TIMEZONE).startOf('day'),
        moment.tz(param.end_time, process.env.REACT_APP_TIMEZONE).endOf('day'),
    ]);

    // Url Params
    const { user = {}, isMobile } = useContext(GlobalContext);
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);
    //Get pagination number
    const { pageno, id, appointmentMode } = props.match.params;

    const [guest, setGuest] = useState({});
    //Visibilty setting for sendmail modal
    const [sendMailModalVisible, setSendMailReportModalVisible] = useState(false);
    const [guestopreg, setGuestOpReg] = useState({});

    const [guestitem, setGuestItem] = useState({});

    const [guestprereg, setGuestPreReg] = useState({});

    const [guestopbill, setGuestOpBill] = useState();

    const [status, setStatus] = useState({}); // To record process status

    const [form] = Form.useForm();
    // const[id,setId]=useState();
    const [record, setRecord] = useState([]);
    const [visible, setVisible] = useState(false);
    const [outBoxModel, setOutBoxModel] = useState(false);
    const [reportModalVisible, setReportModalVisible] = useState(false);
    // payment link
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [paymentView, setPaymentView] = useState(false);

    const [reportSendModelVisible, setReportSendModalVisible] = useState(false);

    const [documentUpload, setdocumentUpload] = useState(false);

    // To check if the appointment is scheduled for the same date to enable registration
    const [sameDay, setSameDay] = useState(false);
    // for package
    const [selected, setSelected] = useState({});
    const [total, setTotal] = useState(null);
    const [item, setItems] = useState(null);
    const [nature, setNature] = useState(); // set otherdetails values from 'firmmas'

    const [firmItems, setFirmItems] = useState(); // set otherdetails values from 'firmmas'
    // const [upload, setUpload] = useState(false);

    const { t, i18n } = useTranslation(); // To Translate to another language

    // console.log(props.match.params);

    //PatiantData fetching function
    async function getAppointments(id) {
        setLoading(true);

        Appointments.getAppointmentRecord(id).then(async (res) => {
            var result = res.drAppntmnt;
            // If appointment date is today REgister button should be enabled
            const appointmentDate = moment(result.da_date, 'YYYY-MM-DD').valueOf();

            const currentDate = moment().startOf('day').valueOf();

            // sameDay is used to check if the apointment is on current date
            if (appointmentDate === currentDate) {
                setSameDay(true);
            }

            setGuest(result);

            setStatus(res.data);

            setGuestOpReg(res.opreg);

            setGuestItem(result.item);

            setGuestPreReg(result.preReg);
            setNature(res.nature);

            if (result.opbill) setGuestOpBill(result.opbill);
            // if the corperate selected during appoinmnets .want to check this corperate are allow to uplaod Id cards
            // if (result.da_corporateptr) await getCorperateData(result.da_corporateptr);
            setLoading(false);
        });
    }

    useEffect(() => {
        if (user) {
            getAppointments(id);
        }
    }, [user]);

    // console.log('status', status);

    function refresh() {
        getAppointments(guest.da_id);
        getSelectedPackage(guest.da_packagetypevalue);
    }

    /**
     * If the corperate selected during appoinment . we want to check the corperate allow to upload id card or not.
     * while creating the corperate we record
     *
     * @param {*} corperate
     */

    // async function getCorperateData(corperate) {
    //     let data = await Customers.getCustomerWithId(corperate);
    //     if (data.result.cc_otherdetail1 === 'Y') {
    //         setUpload(true);
    //     }
    // }

    //Cancellation of Appointment
    function onCancelAppointment() {
        form.validateFields()
            .then((values) => {
                let updateParams = {
                    cancel_flag: true,
                    da_canuser: user.email,
                    da_canuserid: user.id,
                    da_canremarks: values.remarks,
                };

                // cancel the appointment
                Appointments.cancelAppointment(id, updateParams).then(() => {
                    setVisible(false);
                    Location.navigate({ url: `/appointments` });

                    message.success('Appointment is Cancelled');
                });
            })
            .catch((info) => {
                // console.log('Validate Failed:', info);
                setVisible(false);
            });
    }
    useEffect(() => {
        getSelectedPackage(guest.da_packagetypevalue);
    }, [guest.da_packagetypevalue]);
    /**
     *Get Selected package
     */
    function getSelectedPackage(da_packagetypevalue) {
        Items.getItems(da_packagetypevalue).then(async (result) => {
            setSelected(result);
            setItems(result);

            // setGender(result.itm_gender);
            // If total has not been set
            if (total === null) setTotal(result.ir_rate);
        });
    }

    /**
     * The function editAppointment is used to edit an appointment.
     */
    var date = moment(guest.da_date).format('DD/MM/YYYY');
    // Add time to da_date, to pass selected_time in the url for edit
    date = date + ' ' + guest.da_aptime;

    function editAppointment() {
        Location.navigate({
            url: `/new-registrations/edit-guest?da_id=${guest.da_id}&da_packagetypevalue=${guest.da_packagetypevalue}&id=${guest.da_referenceid}&prereg_opno=${guest.da_newopno}&selected_time=${date}&edit=true&redirect=appointment`,
        });
    }

    /**
     *
     */

    async function sendGoogleReviewMail() {
        const result = await ApiUtils.post({ url: `opbill/google-review-mail?billId=${guest.da_billid}` });

        message.success('Mail send');
    }

    /**
     * The function `accessProfileAccount` sends a POST request to a specified endpoint with a JSON
     * payload, and if the response is successful, it opens a new window with the URL received in the
     * response.
     */
    const accessProfile = async () => {
        let formBody = {
            id: guest.da_id,
        };

        try {
            Appointments.accessProfileAccount(formBody).then(async (res) => {
                var ProfileUrl = res.data.orderUrl;
                window.open(ProfileUrl);
            });
        } catch (error) {
            console.error('Error during registration:', error);
        }
    };

    let config = {};
    // resend mail
    async function resendEmails() {
        const result = await ApiUtils.get({ url: `mail/resend-appointment-details?id=${guest.da_id}` });

        message.success('Mail/SMS Confirmation send');
    }

    function uploadDocument() {
        setdocumentUpload(true);
    }

    // send fitkit mail
    const sendFitkitMail = async () => {
        const id = guest.da_id;

        // The code is creating an object named `formBody` with properties `id`
        const formBody = {
            id,
        };

        try {
            const res = await Appointments.sentFitkitMail(formBody);
            message.success('Fitkit Mail send');
        } catch (error) {
            console.error('Error:', error);
            // Handle network errors
            message.error('Error Occurred While Sending Fitkit Mail');
        }
    };

    /**
     * View the Report
     */

    function viewReport() {
        setReportModalVisible(true);
    }

    /**
     * The function "viewOutBox" sets the "OutBoxModel" to false.
     */
    function viewOutBox() {
        setOutBoxModel(false);
    }
    /**
     * The function `paymentInformation` sets the payment view to false.
     */
    function paymentInformation() {
        setPaymentView(false);
    }
    /**
     * The `handleCloseReportModal` function sets the visibility of the report send modal to false.
     */
    const handleCloseReportModal = () => {
        setReportSendModalVisible(false);
    };

    const handleClosePaymentLinkModal = () => {
        setIsModalVisible(false);
    };

    /**
     * The handleClick function handles different actions based on the key parameter passed to it.
     */
    const handleClick = (params) => {
        switch (params.key) {
            case 'view_outbox':
                setOutBoxModel(true);
                break;
            case 'fitkit_preview':
                setReportModalVisible(true);
                break;
            case 'payment_information':
                setPaymentView(true);
                break;
            case 'resend_email':
                //confirmation
                showResendMailConfirmation();
                break;
            case 'google_review':
                sendGoogleReviewMail();
                break;
            case 'resend_email':
                setSendMailReportModalVisible(true);
                break;
            case 'send_fitkit':
                // confirmation
                showFitkitConfirmation();
                break;

            // case 'send_fitkit':
            //     sendFitkitMail();
            //     break;
            case 'document_upload':
                uploadDocument();
                break;

            default:
        }
    };

    // for getting currency symbol

    // Assuming `user` is defined and has the `firm`and f_otherdetails property
    let otherDetails = user?.firm?.f_otherdetails1;

    let firmDetails = JSON.parse(otherDetails);
    /**
     * Confirmation for sending fitkit mail
     */

    const showFitkitConfirmation = () => {
        confirm({
            // title: 'Confirm',
            content: 'Are you sure you want to send the Fitkit mail ?',
            onOk() {
                sendFitkitMail();
            },
            onCancel() {},
        });
    };

    /**
     *  confirmation for sending the Resend confirmation mail
     */

    const showResendMailConfirmation = () => {
        confirm({
            // title: 'Confirm',
            content: 'Are you sure you want to Resend confirmation mail ?',
            onOk() {
                resendEmails();
            },
            onCancel() {},
        });
    };

    const menu = (
        <Menu onClick={handleClick}>
            <Menu.Item key="view_outbox" className="item-discount" style={{ marginBottom: '8px' }} icon={<SendOutlined />}>
                {t('View Outbox')}
            </Menu.Item>
            <Menu.Item key="fitkit_preview" className="item-discount" style={{ marginBottom: '8px' }} icon={<EyeOutlined />}>
                {t('Fitkit Preview')}
            </Menu.Item>
            <Menu.Item key="payment_information" className="item-discount" style={{ marginBottom: '8px' }} icon={<DatabaseOutlined />}>
                {t('Payment Information')}
            </Menu.Item>
            {/* <Menu.Item key="resend_email" className="item-discount" style={{ marginBottom: '8px' }} icon={<MailOutlined />}>
                {t('Resend Confirmation Mail')}
            </Menu.Item>
            <Menu.Item key="send_fitkit" className="item-discount" style={{ marginBottom: '8px' }} icon={<MailOutlined />}>
                {t('Send Fitkit Mail')}
            </Menu.Item> */}
            <Menu.Item key="resend_email" className="item-discount" style={{ marginBottom: '8px' }} icon={<MailOutlined />}>
                Resend Mail
            </Menu.Item>
            <Menu.Item key="document_upload" className="item-discount" style={{ marginBottom: '8px' }} icon={<SendOutlined />}>
                {t('Document Upload')}
            </Menu.Item>

            
            {/* {guest.da_billid ? (
                <Menu.Item key="google_review" className="item-discount" style={{ marginBottom: '8px' }} icon={<GoogleOutlined />}>
                    Sent Google Review
                </Menu.Item>
            ) : null} */}
        </Menu>
    );

    // for other bill status
    const billStatus = status.bill_additional_info;

    return (
        <section className="appointment-detail">
            {/* Page Header */}

            {/* <div className="page-header"> */}
            {/* <div className="left"> */}

            {/* <Title style={{ marginBottom: '0px' }} level={3}>
                        Appointment
                    </Title> */}
            {/* </div> */}

            {/* <div className="right">
                    <Button onClick={refresh} type="secondary" size={'small'}>
                        <ReloadOutlined />
                    </Button>
                </div> */}
            {/* </div> */}
            <div className="page-header">
                <div className="left">
                    {/* <Title style={{ marginBottom: '0px' }} level={5}>
                        {t('APPOINTMENT')}
                    </Title> */}
                </div>
                <div className="right">
                    {/*New Appointment */}

                    <div className="new-appointment">
                        {/* To access profile from website */}
                        {/* <div>
                            <Button
                                style={{ borderRadius: '1px' }}
                                type="primary"
                                size="small"
                                onClick={() => {
                                    accessProfile();
                                }}
                            >
                                {t('Profile')}
                            </Button>
                        </div> */}
                        {/* send report to mail  */}
                        {status.report_published ? (
                            <div>
                                <Button
                                    // style={{ borderRadius: '1px' }}
                                    type="secondary"
                                    size="small"
                                    onClick={() => {
                                        setReportSendModalVisible(true);
                                    }}
                                >
                                    <SendOutlined />
                                    {t('Send Report / Images')}
                                </Button>
                                {/*  send Report modal starts  */}
                                <Modal
                                    destroyOnClose={true}
                                    footer={null}
                                    className="payment"
                                    visible={reportSendModelVisible}
                                    //  onOk={() => {
                                    //     setVisible(true);
                                    //      }}

                                    onCancel={handleCloseReportModal}
                                    width={'60%'}
                                    maskClosable={false}
                                >
                                    <Tabs defaultActiveKey="0">
                                        {/*  send report tab starts  */}

                                        <TabPane tab={t('SEND REPORT')} key="0">
                                            {guestprereg ? (
                                                <SendReport
                                                    props={props}
                                                    billId={guest.da_billid}
                                                    mail={guestprereg.prereg_email}
                                                    reportModalVisible={reportSendModelVisible}
                                                    callback={handleCloseReportModal}
                                                />
                                            ) : null}
                                        </TabPane>

                                        {/*  send report tab ends  */}

                                        {/*  send image tab starts  */}

                                        <TabPane tab={t('SEND IMAGE LINK')} key="1">
                                            <SendImageLink
                                                props={props}
                                                callback={handleCloseReportModal}
                                                billId={guest.da_billid}
                                                opNo={guest.da_newopno}
                                                guestPrereg={guestprereg}
                                            />
                                        </TabPane>

                                        {/*  send image tab ends  */}
                                    </Tabs>
                                </Modal>
                                {/* Send Report Model end */}
                            </div>
                        ) : null}

                        <div>
                            <Link to={'/new-registration/mode'}>
                                <Button style={{ borderRadius: '1px' }} type="primary" size="small">
                                    {t('New Appointment')}
                                </Button>
                            </Link>
                        </div>
                        {/* "Edit Appointment" button  shows if there is no da_billid against the 
                   appointment. If da_billid is present, then it renders nothing.  */}
                        {guest.da_billid ? null : (
                            <div>
                                <Button
                                    style={{ borderRadius: '1px' }}
                                    type="primary"
                                    size="small"
                                    onClick={() => {
                                        editAppointment();
                                    }}
                                >
                                    {t('Edit Appointment')}
                                </Button>
                            </div>
                        )}

                        <div className="action-dropdown">
                            <Dropdown overlay={menu} placement="bottomLeft">
                                <Button size="small" type="secondary" style={{ borderRadius: '2px solid', color: 'black' }}>
                                    {t('Actions')}
                                    <DownOutlined />
                                </Button>
                            </Dropdown>
                        </div>
                    </div>
                </div>
                {/* Outbox modal starts  */}
                <Modal
                    destroyOnClose={true}
                    visible={outBoxModel}
                    // title="Outbox"
                    footer={null}
                    className=""
                    onCancel={() => {
                        setOutBoxModel(false);
                    }}
                    width={'80%'}
                    maskClosable={false}
                >
                    <Tabs defaultActiveKey="0">
                        <TabPane tab={t('Outbox')} key="0">
                            <OutboxList filteredMail={props.filterMail} record={guest} />
                        </TabPane>
                    </Tabs>
                </Modal>

                <Modal
                    width={'80%'}
                    height={'570px'}
                    destroyOnClose={true}
                    footer={null}
                    title={t('Document Upload')}
                    visible={documentUpload}
                    cancelButtonProps={{ style: { display: 'none' } }}
                    okText="Close"
                    onOk={() => {
                        setdocumentUpload(true);
                    }}
                    onCancel={() => {
                        setdocumentUpload(false);
                    }}
                >
                    {/** op_no,bill_id */}
                    <DocumentUpload
                        baseUrl={{ baseUrl: props.baseUrl, documentType: 'report' }}
                        da_id={guest.da_id}
                        bill_id={guest.da_billid}
                        op_no={guest.da_newopno}
                        documentMode={props.documentMode}
                        documentCode={props.documentCode}
                    />

                </Modal>

                {/* Modal for Viewing Report (fitkit preview) */}
                <Modal
                    width={'80%'}
                    height={'570px'}
                    destroyOnClose={true}
                    footer={null}
                    title={t('Fitkit Preview')}
                    visible={reportModalVisible}
                    cancelButtonProps={{ style: { display: 'none' } }}
                    okText="Close"
                    onOk={() => {
                        setReportModalVisible(true);
                    }}
                    onCancel={() => {
                        setReportModalVisible(false);
                    }}
                >
                    <PrintPreview templatecode={props.templateCode} refereneceId={id} groupPointer={props.groupPointer} props={props} />
                    {/* <BillPreview billId={id} groupPointer={props.groupPointer} templatecode={props.templatecode} /> */}
                    {/* <ReportPreview billId={billId} downloadFileName={downloadFileName} groupPointer={groupPointer} /> */}
                </Modal>

                {/* Send Mail starts  */}
                <Modal
                    destroyOnClose={true}
                    visible={sendMailModalVisible}
                    // title="Outbox"
                    footer={null}
                    className=""
                    onCancel={() => {
                        setSendMailReportModalVisible(false);
                    }}
                    width={'80%'}
                    maskClosable={false}
                >
                    {/* Print Preview Button */}
                    {guestprereg ? (
                        <PreviewMails
                            templatecode={props.mailTempateCode} // Template code for message template setting as mailTempateCode in attributes
                            refereneceId={guest.da_id}
                            // groupPointer={props.mailToSend} // mailToSend for message template setting as mailToSend in attributes as an array
                            props={props}
                            config={{
                                mode: ['email', 'whatsapp', 'sms'],
                                templateMode: 'MessageTemplates',
                                recipient: {
                                    email: guestprereg.prereg_email,
                                    whatsapp: guestprereg.prereg_mobile1,
                                },
                            }}
                            sendMail={props.sendMail} // Attribute for mail textbox enable/ disableF
                        />
                    ) : null}
                    {/* Print Preview Button */}
                </Modal>
                {/*Send Mail Ends */}
                {/* view payment information */}
                <Modal
                    destroyOnClose={true}
                    visible={paymentView}
                    // title="Payment"
                    footer={null}
                    className=""
                    onCancel={() => {
                        setPaymentView(false);
                    }}
                    width={'80%'}
                    maskClosable={false}
                >
                    <Tabs defaultActiveKey="0">
                        <TabPane tab={t('Payment')} key="0">
                            <PaymentList record={guest} />
                        </TabPane>
                    </Tabs>
                </Modal>

                {/* <div className="actions">
                    {guest.da_billid ? (
                        <div>
                            <Popconfirm
                                title="Are you sure you want to trigger mail for this guest ? "
                                onConfirm={() => sendGoogleReviewMail()}
                                onCancel={() => {}}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button
                                    className="billing"
                                    // type="primary"
                                    // color="green"
                                >
                                    Send Google Review Mail
                                </Button>
                            </Popconfirm>
                        </div>
                    ) : null}
                </div> */}
            </div>

            {/* Page Header Ends */}

            {/* Content Below */}
            {loading ? (
                <>
                    <Skeleton active />
                </>
            ) : (
                <div>
                    <div className="timeline-guest">
                        <div style={{ flexGrow: '1' }}>
                            <div className="guest-detail">
                                {/*Guest Information */}
                                <GuestInfo
                                    t={t} // To Translate to another language
                                    guest={guest}
                                    data={status}
                                    guestopreg={guestopreg}
                                    guestitem={guestitem}
                                    guestprereg={guestprereg}
                                    guestopbill={guestopbill}
                                    nature={nature}
                                />

                                {/* </div> */}
                                {/* <div className='guest-details'> */}
                                <GuestRecord
                                    record={guest}
                                    cancelApp={() => {
                                        setVisible(true);
                                    }}
                                    guestprereg={guestprereg}
                                    opReg={guestopreg}
                                    Id={id}
                                    props={props}
                                    filteredMail={props.filterMail}
                                    templateCode={props.templateCode}
                                    groupPointer={props.groupPointer}
                                    appointment_mode={props.appointmentMode}
                                    sameDay={sameDay}
                                />
                            </div>
                        </div>

                        <div className="status-information">
                            {/* Package Details */}

                            <div className="card-shadow card">
                                <Title level={5}>{t('PACKAGE DETAILS')}</Title>
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                    <div className="section-label">
                                        <Text type="primary">{guestitem.itm_desc}</Text>
                                    </div>
                                    <PriceFormatter
                                        price={selected.ir_rate}
                                        locale={firmDetails.priceFormat}
                                        enablePriceFormatting={firmDetails.enablePriceFormatting}
                                    />
                                    {/* <div className="section-value">
                                        <strong>{selected.ir_rate}</strong>
                                    </div> */}
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                    <div className="section-label">
                                        <Text type="secondary">{t('SLOT')}</Text>
                                    </div>
                                    <div className="section-value">
                                        <strong> {moment(guest.da_date).format('DD/MM/YYYY')}</strong>
                                    </div>
                                </div>

                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                    <div className="section-label">
                                        <Text type="secondary">{t('DISCOUNT')}</Text>
                                    </div>
                                    {/* <div className="section-value">
                                        {guest.da_couponno ? <strong>{guest.da_couponvalue}</strong> : null}
                                        
                                     
                                    </div>   */}
                                    {guest.da_couponno ? (
                                        <PriceFormatter
                                            price={guest.da_couponvalue}
                                            locale={firmDetails.priceFormat}
                                            enablePriceFormatting={firmDetails.enablePriceFormatting}
                                        />
                                    ) : null}
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                    <div className="section-label">
                                        <Text type="secondary">{t('BILL VALUE')}</Text>
                                    </div>
                                    <PriceFormatter
                                        price={guest.da_billvalue}
                                        locale={firmDetails.priceFormat}
                                        enablePriceFormatting={firmDetails.enablePriceFormatting}
                                    />
                                    {/* <div className="section-value">
                                        <strong>{guest.da_billvalue}</strong>
                                    </div> */}
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                    <div className="section-label">
                                        <Text type="secondary">{t('BILL DISCOUNT')}</Text>
                                    </div>
                                    {/* <div className="section-value">
                                        {guestopbill && guestopbill.opb_bdisamt !== 0 ? (
                                            <strong>{guestopbill.opb_bdisamt}</strong>
                                        ) : null
                                    } 
                                    </div> */}
                                    {guestopbill && guestopbill.opb_bdisamt !== 0 ? (
                                        <PriceFormatter
                                            price={guestopbill.opb_bdisamt}
                                            locale={firmDetails.priceFormat}
                                            enablePriceFormatting={firmDetails.enablePriceFormatting}
                                        />
                                    ) : null}
                                </div>
                                {/* send payment Link start */}
                                {guest.da_paystatus === 'P' && props.showSendPaymentLink ? (
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                        <div className="section-label">
                                            <Text type="secondary">{t('PAYMENT LINK')}</Text>
                                        </div>

                                        <div className="section-value">
                                            <Button
                                                size="small"
                                                // style={{ background: 'none', border: 'none', cursor: 'pointer', color: 'blue' }}
                                                type="secondary"
                                                onClick={() => {
                                                    setIsModalVisible(true);
                                                }}
                                            >
                                                {t('Send Payment Link')}
                                            </Button>
                                            {/*  Payment Link modal starts  */}
                                            <Modal
                                                destroyOnClose={true}
                                                footer={null}
                                                className="payment"
                                                visible={isModalVisible}
                                                //  onOk={() => {
                                                //     setVisible(true);
                                                //      }}

                                                onCancel={() => {
                                                    setIsModalVisible(false);
                                                }}
                                                width={'40%'}
                                                maskClosable={false}
                                            >
                                                <Tabs defaultActiveKey="0">
                                                    <TabPane tab="SEND PAYMENT LINK" key="0">
                                                        {guestprereg ? (
                                                            <PaymentLink
                                                                callback={handleClosePaymentLinkModal}
                                                                mail={guestprereg.prereg_email}
                                                                guest={guest}
                                                                guestItem={guestitem}
                                                                discount={guest.da_couponno ? guest.da_couponvalue : null}
                                                                selected={selected}
                                                                props={props}
                                                            />
                                                        ) : null}
                                                    </TabPane>
                                                </Tabs>
                                            </Modal>
                                            {/* Payment Link Model end */}
                                        </div>
                                    </div>
                                ) : null}
                                {/* send payment Link end */}
                            </div>

                            <div className="card-shadow card">
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                    <div className="section-label">
                                        <Text type="secondary">{t('PAYMENT STATUS')}</Text>
                                    </div>
                                    <div className="section-value">
                                        {/* payment status model start */}
                                        {props.enablePaymentStatusUpdate ? (
                                            <StatusChange
                                                description={status.payment_status}
                                                callback={refresh}
                                                id={id}
                                                statusValue={status.payment_status_code}
                                                // status mode
                                                mode="PAY"
                                                // mode of status for api call
                                                apiMode="PAY"
                                                name="payment status"
                                                disabled={status.paid_status}
                                            />
                                        ) : (
                                            <div>{status.payment_status}</div>
                                        )}

                                        {/* payment status model end */}
                                    </div>
                                </div>
                            </div>

                            {/* Other Bills Start */}
                            {billStatus ? (
                                <div className="card-shadow card">
                                    <p level={5}>{t('OTHER BILL ITEMS')}</p>
                                    {billStatus.map((bill, index) => (
                                        <div
                                            // key={index}
                                            style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
                                        >
                                            <div className="section-label">
                                                <Text type="secondary">{bill.item_desc}</Text>
                                            </div>
                                            {/* <div className="section-value"> */}
                                            {/* {bill.status ?  */}
                                            {/* <strong></strong><Tag color="orange">Pending</Tag> */}
                                            {/* } */}
                                            {/* </div> */}
                                        </div>
                                    ))}
                                </div>
                            ) : null}

                            {/* Other Bills End */}

                            {/* Process Status */}

                            <>
                                {status ? (
                                    <div className="card-shadow card">
                                        <p level={5}>{t('PROCESS STATUS')}</p>
                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                            <div className="section-label">
                                                <Text type="secondary"> {t('FITKIT STATUS:')} </Text>
                                            </div>
                                            <div className="section-value">
                                                {status.fitkit_status ? (
                                                    <strong>{status.fitkit_status} </strong>
                                                ) : (
                                                    <Tag color="orange">{t('Pending')}</Tag>
                                                )}
                                            </div>
                                        </div>

                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                            <div className="section-label">
                                                <Text type="secondary"> {t('BILL PAID:')} </Text>
                                            </div>
                                            <div className="section-value">
                                                {status.billed_status === 'Billed' ? (
                                                    <Tag color="green">{status.billed_status}</Tag>
                                                ) : (
                                                    <Tag color="orange">{status.billed_status}</Tag>
                                                )}
                                            </div>
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                            <div className="section-label">
                                                <Text type="secondary"> {t('GENETIC SAMPLE STATUS')} </Text>
                                            </div>
                                            <div className="section-value">
                                                {status.sample_status ? (
                                                    <strong>{status.sample_status}</strong>
                                                ) : (
                                                    <Tag color="orange">{t('Pending')}</Tag>
                                                )}
                                            </div>
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                            <div className="section-label">
                                                <Text type="secondary"> {t('PHYSICIAN VERIFIED :')} : </Text>
                                            </div>
                                            <div className="section-value">
                                                {status.physician_verified === 'Verified' ? (
                                                    <Tag color="green">{status.physician_verified}</Tag>
                                                ) : (
                                                    <Tag color="orange">{status.physician_verified}</Tag>
                                                )}{' '}
                                            </div>
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                            <div className="section-label">
                                                <Text type="secondary">{t('REPORT PUBLISHED:')} </Text>
                                            </div>
                                            <div className="section-value">
                                                {status.report_published && status.report_published === true ? (
                                                    <Tag color="green">{t('Published')}</Tag>
                                                ) : (
                                                    <Tag color="orange">{t('Pending')}</Tag>
                                                )}
                                            </div>
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                            <div className="section-label">
                                                <Text type="secondary">{t('REPORT STATUS:')} </Text>
                                            </div>
                                            <div className="section-value">
                                                {status.report_status ? (
                                                    <strong>{status.report_status}</strong>
                                                ) : (
                                                    <Tag color="orange">{t('Pending')}</Tag>
                                                )}
                                            </div>
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                            <div className="section-label">
                                                <Text type="secondary">{t('REPORT READY MAIL SEND:')} </Text>
                                            </div>
                                            <div className="section-value">
                                                {status.reportmail_sendstatus && status.reportmail_sendstatus === 'Send' ? (
                                                    <Tag color="green">{status.reportmail_sendstatus}</Tag>
                                                ) : (
                                                    <Tag color="orange">{status.reportmail_sendstatus}</Tag>
                                                )}{' '}
                                            </div>
                                        </div>
                                    </div>
                                ) : null}
                            </>

                            {/* Process status Done */}
                            {/* Consent Status */}

                            <AppointmentConsent
                                callback={refresh}
                                mode="appointment"
                                value={guest.da_consentscannedstatus}
                                id={guest.da_id}
                                props={props}
                                onPremiseUrl={props.onPremiseUrl}
                                documentMode={props.documentMode}
                            />

                            {/* Consent Status Done*/}
                            <br></br>
                            <UploadId
                                upload_status={status.corporate_id_upload_status}
                                id={guest.da_id}
                                da_corporateptr={guest.da_corporateptr}
                                onPremiseUrl={props.onPremiseUrl}
                                // props={props}
                                props={{ reportConfig: props.reportConfig, onPremiseUrl: props.onPremiseUrl }}
                            ></UploadId>
                        </div>

                        {/* <div> */}
                        {/* <Tabs defaultActiveKey="0">
                                <TabPane tab="Outbox" key="0">
                                    <OutboxList record={guest} />
                                </TabPane>
                            </Tabs> */}
                        {/* </div> */}

                        {/*Appointment Timeline */}
                        {/* <AppointmentTimeline guest={guest} guestopreg={guestopreg} guestitem={guestitem} /> */}
                    </div>
                </div>
            )}
            {/* Modal for Cancel Appointment */}
            <Modal
                destroyOnClose={true}
                title={t('Cancel Appointment')}
                visible={visible}
                okText="Okay"
                onOk={() => {
                    setVisible(false);
                    onCancelAppointment();
                }}
                onCancel={() => {
                    setVisible(false);
                }}
            >
                <Form {...layout} form={form} name="new-record" layout="vertical">
                    <Form.Item
                        name={'remarks'}
                        label={t('Remarks')}
                        rules={[
                            {
                                required: true,
                                message: 'Please enter Remarks',
                            },
                        ]}
                    >
                        <TextArea rows={4} />
                    </Form.Item>
                </Form>
            </Modal>
        </section>
    );
}

function GuestRecord({ Id, record, cancelApp, filteredMail = [], opReg, guestprereg, sameDay, templateCode, groupPointer, props }) {
    const [visible, setVisible] = useState(false);

    const [reportModalVisible, setReportModalVisible] = useState(false);
    // This variable used to open complete profile modal

    const [profileModalVisible, setProfileModalVisible] = useState(false);
    const { t, i18n } = useTranslation(); // To Translate to another language

    /**
     *
     */
    async function resendEmails() {
        const result = await ApiUtils.get({ url: `mail/resend-appointment-details?id=${record.da_id}` });

        message.success('Mail/SMS Confirmation send');
    }
    //Appointment data fiiling for icarus
    function CompleteProfile() {
        Location.navigate({
            url: `/new-registrations/complete-profile?da_id=${record.da_id}&da_packagetypevalue=${record.da_packagetypevalue}&id=${
                record.da_referenceid
            }&prereg_opno=${record.da_newopno}&mode=call&selected_time=${moment(record.da_date).format('DD/MM/YYYY')}&complete_profile=true`,
        });
    }

    const handleCompleteProfile = () => {
        setProfileModalVisible(true);
    };
    /**
     * New Registration
     *
     *
     */

    function register() {
        Location.navigate({
            url: `/new-registrations/registration?id=${guestprereg.prereg_id}&appointmentId=${record.da_id}&redirect=appointment`,
        });
    }

    const handleCloseModal = () => {
        setProfileModalVisible(false);
    };

    /**
     * Redirect to bill if the customer is registered but not billed
     */
    function proceedBilling() {
        Location.navigate({
            url: `/multiple-candidate/bill?op_no=${opReg.op_no}&appointmentId=${record.da_id}`,
        });
    }

    /**
     * View the Report
     */

    function viewReport() {
        setReportModalVisible(true);
    }
    //Appointment data fiiling for icarus
    function CompleteProfile() {
        Location.navigate({
            url: `/new-registrations/complete-profile?da_id=${record.da_id}&da_packagetypevalue=${record.da_packagetypevalue}&id=${
                record.da_referenceid
            }&prereg_opno=${record.da_newopno}&mode=call&selected_time=${moment(record.da_date).format('DD/MM/YYYY')}&complete_profile=true`,
        });
    }

    // function proceedRegistration() {
    //     var selected_time = moment(record.da_date).format('DD/MM/YYYY') + record.da_aptime;

    //     let url = `/new-registration/guest?da_id=${record.da_id}&prereg_id=${record.da_referenceid}&da_packagetypevalue=${record.da_packagetypevalue}&selected_time=${selected_time}&token_no=${record.da_tokenno}`;

    //      Location.navigate({ url: url });
    // }

    // for icarus flow (complete profile).

    const inCompleteProfile = guestprereg?.prereg_otherdetails1;

    let profileStatus = null;
    if (inCompleteProfile) {
        try {
            const parsedInCompleteProfile = JSON.parse(inCompleteProfile);
            profileStatus = parsedInCompleteProfile.profile; // Access the value associated with the "profile" key
        } catch (error) {
            console.error('Error parsing JSON:', error);
        }
    }

    return (
        <>
            {/* Cancel Appointment, Billing, Registration */}

            {/*Billing */}

            <div className="card-shadow card" style={{ display: 'flex', flexDirection: 'column' }}>
                {record.da_referenceid == -1 && (
                    <div>
                        <Alert
                            style={{ fontSize: '10px' }}
                            message="Please complete your profile in order to complete your registration and access all services at Nura"
                            type="info"
                            showIcon
                            onClose={CompleteProfile}
                            className="custom-alert"
                            action={
                                <Button type="primary" size="small" onClick={handleCompleteProfile}>
                                    Complete Profile
                                </Button>
                            }
                        />
                    </div>
                )}
                <Modal
                    // title="MODE OF APPOINTMENT"
                    destroyOnClose={true}
                    className="payment"
                    visible={profileModalVisible}
                    onCancel={handleCloseModal}
                    width={'67%'}
                    height={'50%'}
                    maskClosable={false}
                    footer={null}
                >
                    <ProfileModel
                        props={props}
                        values={record}
                        billingDetails={record.da_otherdetails1}
                        pacakageValue={record.da_packagetypevalue}
                        tokenNo={record.da_tokenno}
                        Id={record.da_id}
                    />
                </Modal>
                {record.da_newopno && !record.da_billid ? (
                    <>
                        <div>
                            {/* <Button
                                className="billing"
                                onClick={() => {
                                    proceedBilling();
                                }}
                                type="secondary"
                                shape="round"
                                style={{ background: "#FAC141" }}
                                // size="small"
                                color="green"
                            >
                                Proceed for Billing
                            </Button> */}
                        </div>
                    </>
                ) : null}
                {/*Registration  */}
                {!record.da_newopno ? (
                    <>
                        {/* <div>
                            <Button
                                className="billing"
                                onClick={() => {
                                    proceedRegistration();
                                }}
                                type="secondary"
                                shape="round"
                                style={{ background: '#FAC141' }}
                                // size="small"
                                color="green"
                            >
                                Proceed for Registration
                            </Button>
                        </div> */}
                    </>
                ) : null}

                {/* Cancel and Reschedule Appointment */}

                {/* <ConfirmModal */}
                {/* caption={'Resend Appointment Confirmation Mails'} */}
                {/* title={t( */}
                {/* 'This action will send the Appointment Confirmation , Questionnaire and Access Credentials to the Customers Email and Phone , Are you sure ? ' */}
                {/* )} */}
                {/* callback={values => resendEmails()} */}
                {/* > */}
                {/* <Button */}
                {/* className="billing" */}
                {/* type="primary" */}
                {/* // // size="small" */}
                {/* // shape="round" */}
                {/* // style={{ background: "#FAC141" }} */}
                {/* color="green" */}
                {/* > */}
                {/* Resend Appointment Confirmation Mails */}
                {/* </Button> */}
                {/* </ConfirmModal> */}

                <div>
                    {' '}
                    <span style={{ fontSize: '12px' }}>
                        {t('Last Updated By')} - {record.da_user} for {moment(record.da_date).format('DD/MM/YYYY')},{record.da_aptime} .
                        {/* on{' '} */}
                        {t('Updated Time')} - {moment.tz(record.da_time, 'DD MM YYYY hh:mm a').format('DD/MM/YYYY, hh:mm a')}.
                    </span>
                </div>
                {/* <Popconfirm
                    title="This action will send the Appointment Confirmation , Questionnaire and Access Credentials to the Customers Email and Phone , Are you sure ? "
                    onConfirm={() => resendEmails()}
                    onCancel={() => { }}
                    okText="Yes"
                    cancelText="No"
                >

                </Popconfirm> */}
                <div className="new-appointment ">
                    {!record.da_billid ? (
                        <div style={{ display: 'flex', gap: '10px' }}>
                            {/* Cancel Appointment */}
                            <Popconfirm
                                title={t('Are you sure you want to cancel this appointment ? ')}
                                onConfirm={() => cancelApp()}
                                onCancel={() => {}}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button
                                    className="billing"
                                    // type="primary"
                                    size="small"
                                    // shape="round"
                                    // style={{ paddingBottom: '1rem' }}
                                    style={{ paddingBottom: '1rem', border: '1px solid red', color: 'red' }}
                                    color="green"
                                >
                                    {t('Cancel Appointment')}
                                </Button>
                            </Popconfirm>

                            {/* Cancel Appointment Ends */}
                        </div>
                    ) : null}
                    <div style={{ display: 'flex', gap: '10px' }}>
                        <>
                            <Button
                                type="secondary"
                                size="small"
                                disabled={opReg || sameDay === false ? true : false || record.da_referenceid == -1}
                                onClick={register}
                            >
                                Register
                            </Button>
                        </>
                        <div>
                            {' '}
                            <span style={{ fontSize: '10px' }}>
                                {t('Last Updated By')} - {record.da_user} for {moment(record.da_date).format('DD/MM/YYYY')},{record.da_aptime} .
                                {/* on{' '} */}
                                {t('Updated Time')} - {moment.tz(record.da_time, 'DD MM YYYY hh:mm a').format('DD/MM/YYYY, hh:mm a')}.
                            </span>
                        </div>
                        {(sameDay && !record.da_billid && opReg) || (!record.da_newopno && guestprereg && guestprereg.prereg_opno) ? (
                            <Button size="small" type="secondary" onClick={proceedBilling}>
                                {t('Proceed to Bill')}
                            </Button>
                        ) : null}
                    </div>
                </div>
            </div>
        </>
    );
}

/**
 * Component would show the appointment consent current status ,
 * If the value does not have value , the user can upload new consent
 *
 * @param {*} param0
 * @returns
 */
function AppointmentConsent({ id, value, callback, props, onPremiseUrl, documentMode }) {
    const [openPreview, setOpenPreview] = useState(false);

    const [openIframe, setOpenIframe] = useState(false);

    const { t, i18n } = useTranslation(); // To Translate to another language

    /**
     * Function to upload consent form
     *
     * @param {*} dataUri
     */
    const onConsentUpload = (selectedImages, baseIndex = 0) => {
        message.loading('Uploading Consent Document');

        // var formData = new FormData();
        Promise.all(
            selectedImages.map((ele, index) => {
                var formData = new FormData();

                // setting body
                // formData.append('files', ele);
                // formData.append('appointment_id', id);
                // formData.append('index', baseIndex + (index + 1));
                // formData.append('document_code', 'CST');
                // formData.append('docdet_refmode', documentMode);

                // setting body
                let formBody = {
                    files: ele,
                    appointment_id: id,
                    index: baseIndex + (index + 1),
                    document_code: 'CST',
                    docdet_refmode: documentMode,
                };

                return DocDetails.documentUpload(formBody, onPremiseUrl).then((result) => {
                    if (result.message === 'success') {
                        return result;
                    }
                });
            })
        ).then((result) => {
            // refreshing page after consent upload
            if (result[0].success) {
                message.success('File Uploaded SuccessFully');

                callback();
            } else {
                message.error('File Upload Failed');

                callback();
            }
        });
    };

    return (
        <div className="card-shadow card">
            {/* <Button
                size="middle"
                type="primary"
                onClick={() => {
                    setOpenIframe(true)
                }}
            >
                Take Picture
            </Button> */}
            {/* <Row span={12}> */}
            <Title level={5}>{t('CONSENT DOCUMENT INFORMATION')}</Title>
            {/* </Row> */}
            {/* <Row span={12}> */}
            <span>
                {value ? (
                    <div>
                        <Tag color="green">{t('Submitted')}</Tag>

                        <Button
                            size="middle"
                            type="primary"
                            onClick={() => {
                                setOpenPreview(true);
                            }}
                        >
                            {t('Preview')}
                        </Button>
                        {/* <DocumentPreview id={id} /> */}
                    </div>
                ) : (
                    <>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <div className="section-label">
                                <Text type="secondary">{t('STATUS')}</Text>
                            </div>
                            <div className="section-value">
                                <Tag color="orange">{t('Pending')}</Tag>
                            </div>
                        </div>

                        {/* Camera Component with Advanced View */}
                        <Camera multiple={true} onUpload={onConsentUpload} mode={'rear'} />
                        {/* Camera Component with Advanced View Ends */}
                    </>
                )}
{/* 
                <ConsentComponent
                    app_id={id}
                // callback={refresh}
                /> */}

                {props.enableConsentUpload ? (
                    <ConsentComponent
                        app_id={id}
                        // callback={refresh}
                    />
                ) : null}
            </span>
            {/* </Row> */}
            <Modal
                width={'100%'}
                destroyOnClose={true}
                title={t('Consent File')}
                visible={openPreview}
                okText="Okay"
                onOk={() => {
                    setOpenPreview(false);
                }}
                onCancel={() => {
                    setOpenPreview(false);
                }}
            >
                <DocumentPreview
                    id={id}
                    onDocumentUpload={onConsentUpload}
                    props={{ reportConfig: props.reportConfig, onPremiseUrl: props.onPremiseUrl, type: 'CST' }}
                />
                {/* <iframe allow="camera; microphone" align="center" src={`http://localhost:3000/document-preview/${id}?token=${localStorage.access_token}`} width="100%" height="400px"></iframe> */}
            </Modal>
            {/* <Modal
                wrapClassName="advanced-camera"
                bodyStyle={{ height: "80vh" }}
                width={'100%'}
                destroyOnClose={true}
                // title="Nura desk"
                visible={openIframe}
                okText="Okay"
                onOk={() => {
                    setOpenIframe(false);
                }}
                onCancel={() => {
                    setOpenIframe(false);
                }}
            >
                <iframe align="center" src={`http://localhost:3001/nura-desk/appointment/60271?token=${localStorage.access_token}`} width="100%" height="400px"></iframe>
                <iframe allow="camera; microphone" align="center" src={`http://localhost:3000/advanced-camera/${id}?token=${localStorage.access_token}`} width="100%" height="100%"></iframe>
            </Modal> */}
        </div>
    );
}
