/***
 *
 *
 * @description
 * @author jinshakappil
 */
import { EditOutlined } from '@ant-design/icons';

import React, { useEffect, useState } from 'react';

import { Clinicals, Opvisits, Opreg } from '../../../../models';

import { Radio, Skeleton, Modal, Input, Button } from 'antd';

import VitalInformation from '../vital-information/vital-information';

import { Location, DateUtils, useTranslation } from 'soxo-bootstrap-core';

import './clinical-information.scss';

const { TextArea } = Input;

/**
 *
 */
export default function ClinicalInformation({
    bill,
    // t,

    visit_id,
    histories,
    enableModel,
    module,
    refrence_number,
    refrence_id,

    attributes
}) {
    

    const { t, i18n } = useTranslation();    // To Translate to another language


    const [loading, setLoading] = useState(true);

    const [clinical, setClinical] = useState({});

    let [history, setHistory] = useState([]);

    let urlParams = Location.search();


    // Use the useLocation hook to get URL parameters

    useEffect(() => {
        getClinicalData(visit_id);

        getPatientHistory(urlParams.opno);
    }, []);

    /**
     * Get the patient history
     *
     * @param {*} op_number
     */
    function getPatientHistory(op_number) {
        setLoading(true);

        Opvisits.getPatientHistory(op_number).then(async (result) => {
            if (result.result.length) {
                let visits = result.result;

                await Promise.all(
                    visits.map((visit) => {
                        return Clinicals.getVitalInformation(visit.visit_id).then((response) => {
                            if (response.result.length) {
                                response.result.forEach((record) => {
                                    history.push({
                                        Cases: record.srvbcd_weight,
                                        Date: DateUtils.formatDate(visit.date),
                                    });
                                });

                                // history = [].concat(history, response.result)
                            }

                            setLoading(false);
                        });
                    })
                );

                console.log(history);

                // let format = history.map((record) => {

                //     return ({
                //         Date: '10/10/2021',
                //         Cases: record.srvbcd_weight
                //     })
                // })

                setHistory(history);
            }

            setLoading(false);
        });
    }

    /**
     * get clinical data
     */
    async function getClinicalData(visit_id) {
        setLoading(true);

        await Clinicals.getVitalInformation(visit_id).then((result) => {
            if (result.result[0]) {
                setClinical(result.result[0]);
            } else {
                setClinical({});
            }

            setLoading(false);
        });
    }
   
    const [visible, setVisible] = useState(false);

    const [formToShow, setFormToShow] = useState(null);

    /**
     *  edit for clinical information
     */
    function editClinicalinformation(formIdentifier) {
        setFormToShow(formIdentifier);
        // Location.navigate({ url: `/vital-information/${visit_id}?opvisit_id=${visit_id}&redirect=true` });
        setVisible(true);
    }

    return (
        <div className="clinical-information clinical card card-shadow">
            <div className="card-header">
                <div className="card-title">
                    <h3>{t('CLINICAL INFORMATION')}</h3>

                    <div className="card-actions">
                        {/*  */}
                        {enableModel ? null : (
                            <div style={{ display: 'flex' }}>

                                <div>

                                    {/** If the histories returns true then edit is disabled (Show the emr screen contains patient history tab) else edit enabled.*/}
                                    <Button size="small" onClick={() => { editClinicalinformation('form1') }}>
                                        {t('Height/ Weight')}
                                        {!histories ? <EditOutlined style={{ color: 'black' }} /> : null}
                                    </Button>
                                </div>

                                <div>
                                    {/** If the histories returns true then edit is disabled (Show the emr screen contains patient history tab) else edit enabled.*/}
                                    <Button size="small" onClick={(edit) => editClinicalinformation('form2')}>
                                        {t('Blood Pressure')}
                                        {!histories ? <EditOutlined style={{ color: 'black' }} /> : null}
                                    </Button>
                                </div>
                            </div>
                        )}
                    </div>

                    {enableModel ? null : (
                        <Modal
                            destroyOnClose={true}
                            title={t('Vital Information')}
                            visible={visible}
                            footer={null}
                            okText="Okay"
                            onOk={() => {
                                setVisible(false);
                                // onCancelAppointment();
                            }}
                            onCancel={() => {
                                setVisible(false);
                            }}
                        >
                            <VitalInformation
                                bill={bill}
                                information={clinical}
                                visit_id={visit_id}
                                refrence_number={refrence_number}
                                refrence_id={refrence_id}
                                module={module}
                                formToShow={formToShow}
                                // Pass the formToShow prop

                                attributes={attributes}

                                callback={() => {
                                    new Promise(async (resolve, reject) => {
                                        // Get Clinical Data
                                        const clinicalData = await getClinicalData(visit_id);

                                        resolve(clinicalData);
                                    });

                                    setVisible(false);
                                }}
                            />
                        </Modal>
                    )}
                </div>
            </div>

            {loading ? (
                <Skeleton />
            ) : (
                <>
                    <div className="card-div">
                        <div className="blood-section">
                            <div className="section">
                                <h3 className="blood-pressure">{t('BLOOD PRESSURE')}</h3>
                                <div className="blood-values-section values-section">
                                    {/* Value Card for BP Systolic */}
                                    <ValueCard label={t('Systolic')} value={clinical.srvbcd_bpsys} unit={t('mmHg')} />
                                    {/* Value Card for BP Systolic Ends */}

                                    {/* Value Card for Diastolic */}
                                    <ValueCard label={t('Diastolic')} value={clinical.srvbcd_bpdia} unit={t('mmHg')} />
                                    {/* Value Card for Diastolic Ends */}
                                </div>
                            </div>
                            {/* {enableModel ? (
                            <div className="alergic-infromation">
                                <TextArea placeholder="Alergic information" row={4} value={clinical.srvbcd_remarks}></TextArea>
                            </div>
                        ) : null} */}
                        </div>
                        <div className="measurement-section">
                            <div className="values-section">
                                <ValueCard label={t('Height')} value={clinical.srvbcd_height} unit={t('cm')} />

                                <ValueCard label={t('Weight')} value={clinical.srvbcd_weight} unit={t('kg')} />
                            </div>
                            <div className="values-section">
                                <ValueCard label={t('BMI')} value={clinical.srvbcd_bmi} unit={t('kg/m^2')} />
                                <ValueCard label={t('SpO2')} value={clinical.srvbcd_spo2} unit={t('kg/m^2')} />
                                <ValueCard label={t('Temperature')} value={clinical.srvbcd_temperature} unit={t('°F')} />
                                <ValueCard label={t('Pulse')} value={clinical.srvbcd_pulse} unit={t('bpm')} />
                            </div>
                        </div>
                    </div>
                </>
            )}

            {/* {history.length >= 2 ? (
                <div className="">
                    <LineGraph data={history} />
                </div>
            ) : null} */}
        </div>
    );
}

/**
 * Component styles and displays the label
 *
 * @param {*} param0
 * @returns
 */
function ValueCard({ label, value, unit }) {
    return (
        <div className="value-card">
            <label>{label}</label>

            <h5>
                {value}
                <small>{unit}</small>
            </h5>
        </div>
    );
}
