/***
 *
 *
 * @description
 * @author Ashique Mohammed
 * Custom  componet used for Document upload
 *
 *
 */

import React, { useState } from 'react';

import { Modal, Button } from 'antd';

import { DocumentUpload } from './../emr-screen/documents/documents';

/**
 *
 * @param {*} param0
 * @returns
 */
export default function DocumentUploadComponent({
    title = 'Documents',

    referenceId,
    referenceNumber,

    props,
    guest,
}) {
    let [visible, setVisible] = useState(false);
    return (
        <div className="head-selector">
            <Button
                className="billing"
                onClick={() => {
                    setVisible(true);
                }}
                // type="secondary"
                // shape="round"
                size="small"
                color="green"
            >
                {/* Search{' '} */}
                {title}
            </Button>

            <Modal
                destroyOnClose={true}
                visible={visible}
                // title='Advance Search'
                footer={null}
                className=""
                onCancel={() => {
                    setVisible(false);
                    //setResult(null);
                }}
                width={'80%'}
                maskClosable={false}
            >
                {/** Functionality for search is done in advancesearch component */}
                <DocumentUpload
                    referenceId={referenceId}
                    referenceNumber={referenceNumber}
                    baseUrl={{ baseUrl: props.baseUrl, documentType: props.documentType }}
                    da_id={guest.da_id}
                    bill_id={guest.bill_id}
                    op_no={guest.op_no}
                    documentMode={props.documentMode}
                    documentCode={props.documentCode}
                    isNuradesk={props.isNuradesk}

                />
            </Modal>
        </div>
    );
}
