/**
 *
 * Component for Technician Entry
 *
 * The first module of this project
 *
 * @author Ashique Mohammed
 *
 **/

import React, { useState, useEffect, useContext } from 'react';

import { Button, Select, Form, Row, message, Skeleton, Input, Alert, Tag, Modal, Progress, Checkbox, Col, Table } from 'antd';

import { PlusOutlined } from '@ant-design/icons';

import './patient-details.scss';

import { Location, GlobalContext, ReferenceSelect, useTranslation, ExtraInfoDetail } from 'soxo-bootstrap-core';

import { ModalityGroups, ServiceResultMaster, Doctors, SCRFilesLink } from './../../../../models/';

import ReportView from './../../../../components/report-preview/report-preview';

import ClinicalInformation from '../../../emr/components/clinical-information/clinical-information';

import DisplayNature from './../../../common/components/display-nature/display-nature';

import moment from 'moment-timezone';
import { Questionaire } from '../../../screening/questionaire/questionaire';
import SyncareLink from '../syncare-link-view';

const { TextArea } = Input;
const { Option } = Select;

let timeoutinterval = null;

let myTimeout = null;

export default function RadiologistEntry({
    mode = 'RAD',
    extraInfo, // To manage extra info component
    modeValue, // To mension mode for script execution 
    title,  // To mension title of extra info
    icon,// To mension icon
    match,
    history,
    dashboard,
    onSubmit,
    allowCloseEdit,
    enableResultClose, // used to enable 'Result Close' checkbox.
    enableClose,
    enableLabTrigger,
    mode_status,
    category,
    enableSyncareLink,
    // used to enable question aire.by default it is disabled
    enableQuestionnaire,
    // used to enable 'map file path' button
    allowFileMappingButton,
    // Title of remarks   (For Modalities)
    remarksLabel,
    // To show or hide remarks (For Modalities)
    showTechRemarks,
    showRadRemarks,
    disableDoctor,

    // Variable to control hiding this information if shown
    // in a card list
    hideInformation,

    reportConfig = {
        baseUrl: 'http://localhost:3800/',
    },
}) {
    const [initial, setInitial] = useState({});

    const [options, setOptions] = useState([]);

    const { user } = useContext(GlobalContext);

    const [loading, setLoading] = useState(true);

    const [nature, setNature] = useState(true);


    const [btnloading, setBtnloading] = useState(false);

    const [tech, setTech] = useState({});

    const [rad, setRad] = useState({});

    const [modalVisible, setModalVisible] = useState(false);

    const [count, setCount] = useState(0);

    const [billed, setBilled] = useState(false);

    const [validation, setValidation] = useState(true);

    const [patient, setPatient] = useState({
        opbill: {},
        opreg: {},
        modality: { item: {} },
        patientStudies: {
            study_details: [],
        },
    }); //Patiants list array

    const [otherDetails, setOtherDetails] = useState([]);

  
    const [form] = Form.useForm();

    const { opno } = match.params;

    // State for result.Initially result is N.It will become Y when the checkbox is checked
    const [resultClosed, setResultClosed] = useState('N');

    // State for checkbox.Initially checkbox is not checked
    const [checkbox, setCheckbox] = useState(false);

    // State for submit button.Initially button is enabled
    const [disableSubmit, setDisableSubmit] = useState(false);

    // State for the fields.Initially fields are enabled
    const [disableFields, setFieldsDisable] = useState(false);

    const [triggerBill, setTriggerBill] = useState(false);

    const { t, i18n } = useTranslation(); // To Translate to another language

    /**
     * Handles the insertion of an expert opinion into the form.
     *
     * This function updates the 'notes' field in the form with the expert opinion
     * extracted from the provided record.
     *
     * @param {Object} record - The record object containing the expert opinion.
     * @param {string} record.srvrs_resultdedesc - The expert opinion description to be set in the form.
     */
    const handleInsertExpertOpinion = (record) => {

        if (record) {
            // Extract the expert opinion description from the record
            const expertOpinion = record.srvrs_resultdedesc;

            // Set the initial value of the 'notes' and 'status' field in the form
            form.setFieldsValue({
                notes: expertOpinion,
                status: record.srvrs_resultptr
            });
        }
    };
    var urlParams = Location.search();

    useEffect(() => {
        initializeComponent();
    }, [match.params]);

    /**
     * Load the data and initialize the component
     */
    async function initializeComponent() {
        // Url Params
        var urlParams = Location.search();

        const dropdown = await getDropdown(category, mode_status);

        await getModalityDetails(urlParams, dropdown);

        // Dropdown options
    }

    /**
     * Setting Dropdown options based on category and mode of serviceresultmaster
     */

    function getDropdown() {
        return ServiceResultMaster.getServiceResultMaster(category, mode_status, urlParams.item_ptr).then((result) => {
            // filtering based on active status 'Y'
            var dropdown = result.data.filter((record) => record.active === 'Y');
            // setting options to state

            setOptions(dropdown);
            return dropdown;
        });
    }

    async function getModalityDetails(urlParams, dropdown) {
        setLoading(true);
        // Load the details
        await ModalityGroups.getDetails({
            op_no: opno || urlParams.opno,
            accessionno: urlParams.accessionno,
            mode: `'TECH','RAD'`,
            template_code: urlParams.item_ptr,
        }).then(async (result) => {
            const updatedServiceResultTran = result.serviceResultTran.map((ele) => {
                // Find the matching dropdown item for the current result item
                const matchedDropdownItem = dropdown.find((value) => ele.srvrs_resultptr === value.code);

                // If a match is found, return a new object with description added
                // Otherwise, return the original result item
                return {
                    ...ele,
                    description: matchedDropdownItem ? matchedDropdownItem.description : 'No description available',
                };
            });

            result = {
                ...result,
                serviceResultTran: updatedServiceResultTran,
            };

            setPatient(result);

            setRad({});

            setTech({});

            setInitial({});
            setNature(result.nature);

            var rad_entry = result.serviceResultTran.filter((record) => record.srvrs_mode === 'RAD' && record.srvrs_user == user.email);

            var tech_entry = result.serviceResultTran.filter((record) => record.srvrs_mode === 'TECH');
            let filter;
            //This condition is changed due to vitnam production issues
            if (rad_entry && rad_entry[0]) {
                // filter based on status
                filter = dropdown.filter((ele) => ele.code === rad_entry[0].srvrs_resultptr);
            }

            if (rad_entry && rad_entry[0]) {
                // set radiologist Result
                rad_entry[0]['description'] = filter[0].description;

                // If the  Status 'Pending' or   "Negative" the required attribute for 'Notes' textarea should not be mandatory
                if (filter[0].description === 'Pending' || filter[0].description === 'Negative') {
                    setValidation(false);
                }

                setRad(rad_entry[0]);
                // set radiologist Status

                if (mode === 'RAD') {
                    setInitial({
                        remarks: rad_entry[0].srvrs_resultdedesc,
                        status: rad_entry[0].srvrs_resultptr,
                        doctor: rad_entry[0].srvrs_doctorptr,
                        notes: rad_entry[0].srvrs_resultdedesc,
                    });

                    // Checking result closed or not.
                    // If srvrs_resultclosed='Y' - Closed
                    // If srvrs_resultclosed='N' - Not Closed
                    if (rad_entry[0].srvrs_resultclosed == 'Y') {
                        setDisableSubmit(true);
                        setCheckbox(true);
                        setFieldsDisable(true);
                    }
                }
            }

            if (tech_entry && tech_entry[0]) {
                setTech(tech_entry[0]);

                if (mode === 'TECH') {
                    setInitial({
                        remarks: tech_entry[0].srvrs_remarks,
                        status: tech_entry[0].srvrs_resultptr,
                        doctor: tech_entry[0].srvrs_doctorptr,
                        notes: tech_entry[0].srvrs_resultdedesc,
                    });

                    // Checking result closed or not.
                    // If srvrs_resultclosed='Y' - Closed
                    // If srvrs_resultclosed='N' - Not Closed
                    if (tech_entry[0].srvrs_resultclosed == 'Y') {
                        setDisableSubmit(true);

                        setCheckbox(true);

                        if (allowCloseEdit) {
                            setFieldsDisable(false);
                        } else {
                            setFieldsDisable(true);
                        }
                    }
                }
                //If the  Status 'Pending' or   "Negative" the required attribute for 'Notes' textarea should not be mandatory
                if (filter && filter[0] && (filter[0].description === 'Pending' || filter[0].description === 'Negative')) {
                    setValidation(false);
                }

                // if (tech_entry && tech_entry[0]) {
                //     setTech(tech_entry[0]);

                //     if (mode === 'TECH') {
                //         setInitial({
                //             remarks: tech_entry[0].srvrs_remarks,
                //             status: tech_entry[0].srvrs_resultptr,
                //             doctor: tech_entry[0].srvrs_doctorptr,
                //             notes: tech_entry[0].srvrs_resultdedesc,
                //         });

                //         // Checking result closed or not.
                //         // If srvrs_resultclosed='Y' - Closed
                //         // If srvrs_resultclosed='N' - Not Closed
                //         if (tech_entry[0].srvrs_resultclosed == 'Y') {
                //             setDisableSubmit(true);

                //             setCheckbox(true);

                //             if (allowCloseEdit) {
                //                 setFieldsDisable(false);
                //             } else {
                //                 setFieldsDisable(true);
                //             }
                //         }
                //     }
                // }

                setLoading(false);
            }
            // onChange(dropdown);
            setLoading(false);
        });
    }

    /**
     * Update the values for the selected inputs
     * @param {
     * } ele
     * @param {*} record
     */
    function onPatientInputValueChange(value, fieldname, record) {
        // input values                                                                                                                                                                                                            for table
        let forminputValues;
        if (patient.modality.modality_otherdetails) {
            forminputValues = patient.modality.modality_otherdetails;
        } else {
            forminputValues = patient.input_values;
        }

        let array = [];
        // push input values to array
        array.push({ record, value: value });

        if (array.length > 0) {
            forminputValues.forEach((values, index) => {
                let rowIndex = null;
                //
                if (values.code[0] === array[0].record.code[0]) {
                    rowIndex = index;

                    forminputValues[rowIndex][fieldname] = array[0].value;

                    setOtherDetails({ ...otherDetails, inputValues: forminputValues });
                }
            });
        }
    }

    /**
     *Update Remarks and teh status
     * @param {*} values
     */
    const onFinish = (ele) => {
        var values = form.getFieldValue();
        setBtnloading(true);
        values = {
            ...values,
            resultptr: values.status,
            resultdedesc: values.notes,
            user: user.email,
            // doctorptr: user.staff_id,
            mode: mode,
            op_no: opno || urlParams.opno,
            accessionno: urlParams.accessionno,
            slno: mode === 'TECH' ? 1 : 5,
            // Check if the mode is "RAD" If true, use the user's staff ID  If false, use the doctor's identifier from values
            doctorptr: mode === "RAD" ? user.staff_id : values.doctor,
            remarks: values.remarks,

            // visit_id: patient.opbill.opb_opid,
            resultstatus: resultClosed,
        };
        // Check if otherDetails has  inputValues
        if (otherDetails.inputValues) {
            // If it exists, merge the current values with the otherdetails key
            // and set its value to otherDetails.inputValues
            values = {
                ...values,
                otherdetails: otherDetails.inputValues,
            };
            // If otherDetails.inputValues doesn't exist, check if patient has input_values that is the default values
        } else {
            if (patient.modality && patient.modality.modality_otherdetails) {
                // If modality_otherdetails exists,then set otherdetails
                // and set  values
                values = {
                    ...values,
                    otherdetails: patient.modality.modality_otherdetails,
                };
            } else {
                // and set default values
                values = {
                    ...values,
                    otherdetails: patient.input_values,
                };
            }
        }

        //This will trigger bill for QTBG if lab test is positive
        ModalityGroups.update(values).then(async () => {
            message.success('Your update has been captured');

            setBtnloading(false);

            setModalVisible(false);

            if (dashboard) {
                onSubmit();
            } else {
                history.goBack();
            }
        });
    };

    //for clearing time out and time interval
    function stop() {
        setCount(0);
        clearInterval(timeoutinterval);
        clearTimeout(myTimeout);
    }

    /**
     *
     */
    function refresh() {
        // var urlParams = Location.search();

        // getModalityDetails(urlParams);
        initializeComponent();

        // getPatientDetails(urlParams);
    }

    let disabled = false;

    if (mode === 'RAD') {
        if (!tech.srvrs_resultptr) {
            disabled = true;
        }
    }

    // if (mode === 'TECH') {
    //     if (rad.srvrs_resultptr) {
    //         disabled = true;
    //     }
    // }

    // From the report , get the image and link

    var image_link = {};

    var report_link = {};

    if (patient.scrfiles) {


        var images = patient.scrfiles.filter((record) => record.sflink_type === 'IMG');

        if (images.length) {
            image_link = images[0];
        }

        // We have to filter only the RPT Images and the one with filename
        var reports = patient.scrfiles.filter((record) => record.sflink_type === 'RPT' && record.sflink_filename !== '');

        if (reports.length) {
            report_link = reports[reports.length - 1];
        }
    }

    // Used to update checkbox status
    const onCheckboxCheck = (e) => {
        if (e.target.checked) {
            setCheckbox(true);

            setResultClosed('Y');
        } else if (!e.target.checked && allowCloseEdit) {
            setCheckbox(false);

            setDisableSubmit(false);

            setFieldsDisable(false);

            setResultClosed('N');
        } else {
            setResultClosed('N');

            setCheckbox(false);
        }
    };

    /**
     * Trigger Bill check box
     * @param {} e
     */

    function onCheckBillTrigger(e) {
        if (e.target.checked) {
            setTriggerBill(true);
        }
    }

    /**
     * settting dataSource to patientInputvalues
     */
    let dataSource;
    if (patient.modality.modality_otherdetails) {
        dataSource = patient.modality.modality_otherdetails;
    } else {
        dataSource = patient.input_values;
    }

    /**
     * This function is used to set Validation for 'Notes' Text Area
     * If the  Status 'Pending' or   "Negative" the required attribute for 'Notes' textarea should not be mandatory
     * @param {*} values
     */
    function onChange(values) {
        if ((values && values.children === 'Pending') || values.children === 'Negative') {
            setValidation(false);
        } else {
            setValidation(true);
        }
    }

    return (
        <section className="tech-entry-screen">
            {/* Loading Indicator */}
            {loading ? (
                <div className="card-shadow card">
                    <Skeleton active />
                </div>
            ) : (
                <div className="patient-main">
                    {hideInformation ? null : (
                        <>
                            <div className="card-shadow card guest-information" style={{ position: 'relative' }}>

                                <div className="page-header">
                                    {/* <div>
                                    <Title level={3}>{mode === 'TECH' ? 'Technician Entry' : 'Radiologist Entry'}</Title>

                                    <p className="size-hint">{loading ? 'Loading data' : 'Refer below information and update feedback'}</p>
                                </div> */}
                                </div>



                                <div gutter={0} className="detail-wrapper" >

                                    {nature ? (<DisplayNature nature={nature} />) : null}

                                    <div className="detail-element">
                                        <Row span={12}>
                                            <span>{t('Name')}</span>
                                        </Row>
                                        <Row span={12}>
                                            <h3>
                                                {patient.opbill.opb_name} {patient.opbill.opb_opno}
                                            </h3>
                                        </Row>
                                    </div>

                                    <div className="detail-element">
                                        <Row>
                                            <Col span={12}>
                                                <span>{t('Gender')}</span>
                                                <h3>{patient.opbill.opb_gender}</h3>
                                            </Col>
                                            <Col span={12}>
                                                <span>{t('Age')}</span>
                                                <h3>{patient.opreg.op_age}</h3>
                                            </Col>
                                            <Col span={12}>
                                                <span>{t('Emirates ID')}</span>
                                                <h3>{patient.opreg.op_othernatid1}</h3>
                                            </Col>
                                        </Row>
                                    </div>

                                    <div className="detail-element">
                                        <Row span={12}>
                                            <span>{t('Accession No')}</span>
                                        </Row>
                                        <Row span={12}>
                                            <h3>
                                                <Tag color={'green'}>{urlParams.accessionno}</Tag>
                                            </h3>
                                        </Row>
                                    </div>

                                    <div className="detail-element">
                                        <Row span={12}>
                                            <span>{t('Modality')}</span>
                                        </Row>
                                        <Row span={12}>
                                            <h3>
                                                <Tag color={'green'}>{patient.modality.item.itm_desc}</Tag>
                                            </h3>
                                        </Row>
                                    </div>
                                    {patient.opreg.op_dob ? (
                                        <div className="detail-element">
                                            <Row span={12}>
                                                <span>{t('Date of Birth')}</span>
                                            </Row>
                                            <Row span={12}>
                                                <h3>{moment(patient.opreg.op_dob).format('DD/MM/YYYY')}</h3>
                                            </Row>
                                        </div>
                                    ) : null}

                                    {/* <div className="detail-element">
            <Row span={12}>
                <span>Mobile</span>
            </Row>
            <Row span={12}>
                <h3>{patient.opbill.opb_mobile}</h3>
            </Row>
        </div> */}
                                    {!showTechRemarks ? (
                                        <>
                                            <div className="detail-element">
                                                <Row span={12}>
                                                    <span>{remarksLabel ? remarksLabel : t('Technician Remarks')}</span>
                                                </Row>
                                                <Row span={12}>
                                                    <h3>
                                                        {tech.srvrs_resultptr ? tech.srvrs_resultdedesc : <Tag color="orange">{t('Pending')}</Tag>}
                                                    </h3>
                                                </Row>
                                            </div>
                                        </>
                                    ) : null}

                                    {/*Extra Info component start  */}
                                    {extraInfo ? (
                                        <ExtraInfoDetail
                                            {...urlParams}
                                            // record={urlParams} 
                                            modeValue={modeValue}
                                            title={title}
                                            icon={icon}
                                        />

                                    ) : null}
                                    {/*Extra Info component end  */}

                                    <>
                                        {!showRadRemarks ? (
                                            <div className="expert-opinion-header">
                                                <span>{t('Expert Doctor Opinion')}</span>
                                            </div>
                                        ) : null}
                                        <div className="expert-opinion">
                                            {patient.serviceResultTran
                                                .filter((item) => item.srvrs_mode === 'RAD')
                                                .map((item, index) =>
                                                    (!showRadRemarks && item.srvrs_resultptr) || (!showRadRemarks && item.srvrs_resultptr == null) ? (
                                                        <div key={index} className="expert card">
                                                            <strong>{item.radiology_dr_name ? item.radiology_dr_name : null}</strong>
                                                            <div className="card-header ">
                                                                <div className="updated-time">
                                                                    <span>Last update on</span>
                                                                    <span>{moment.utc(item.updttm).format('MM/DD/YYYY hh:mm A')}</span>
                                                                </div>
                                                            </div>
                                                            <div className="detail-element">
                                                                <Row span={12}>
                                                                    <span>{t('Result')}</span>
                                                                </Row>
                                                                <Row span={12}>
                                                                    <input
                                                                        class="ant-input"
                                                                        disabled="true"
                                                                        value={item.srvrs_resultptr ? item.description : 'pending'}
                                                                    ></input>
                                                                </Row>
                                                            </div>
                                                            <div className="detail-element">
                                                                <Row span={12}>
                                                                    <span>{t('Notes')}</span>
                                                                </Row>
                                                                {/* <Row span={12}> */}
                                                                <textarea
                                                                    class="ant-input"
                                                                    disabled="true"
                                                                    value={item.srvrs_resultptr ? item.srvrs_resultdedesc : 'pending'}
                                                                ></textarea>

                                                                {/* </Row> */}

                                                                <br></br>
                                                                <br></br>

                                                                <Button
                                                                    type="primary"
                                                                    size="small"
                                                                    // onClick={handleInsertExpertOpinion}
                                                                    onClick={() => handleInsertExpertOpinion(item)}
                                                                >
                                                                    <PlusOutlined />
                                                                    {t('Insert Expert Opinion')}
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    ) : null
                                                )}
                                        </div>

                                        {/* <Tag color="orange">Pending</Tag> */}
                                    </>
                                </div>
                            </div>
                        </>
                    )}

                    <div className="card-shadow rightCard card">
                        {/* <div className='page-header'>

                            <div className='page-actions'>
                                <Button
                                    onClick={() => {
                                        refresh();
                                    }}
                                    type="secondary"
                                    size={'small'}
                                >
                                    <ReloadOutlined />
                                </Button>
                            </div>

                        </div> */}

                        {/** Question Componet start  */}
                        {enableQuestionnaire ? (
                            <div className="questionaire-section">
                                <Questionaire reference_id={urlParams.opb_id} mode={'bill'} />
                            </div>
                        ) : null}
                        {/** Question Componet end */}
                        {/* The Studies Viewer only in use for StopTB right now but will be extended for Nura */}
                        {patient.patientStudies && patient.patientStudies.study_details.length && <PatientStudies patient={patient.patientStudies} />}

                        <div className="report-links">

                            {/* Report View */}

                            {report_link.sflink_filename ? (
                                <ReportView
                                    config={reportConfig}
                                    record={{
                                        report_type: 'RPT',
                                        report_path: report_link.sflink_filename,
                                    }}
                                    report_link={report_link.sflink_filename}
                                    className={`report-link vehicle-card card ${report_link.sflink_filename ? 'available' : ''}`}
                                >
                                    <h1>{t('AI')}</h1>
                                    {t('View AI Report')}
                                </ReportView>
                            ) : null}
                            {/* Report View */}

                            {enableSyncareLink ? (
                                <>
                                    <SyncareLink
                                        className={`report-link vehicle-card card ${report_link.sflink_filename ? 'available' : ''}`}
                                        op_no={opno}
                                        accession_number={urlParams.accessionno}
                                        branch_id={user.branch_id}
                                        firm_id={user.firm_id}
                                        with_op_no="true"
                                    >
                                        <h1>{t('Syncare')}</h1>
                                        {t('View')}
                                    </SyncareLink>
                                </>
                            ) : null}

                            {/* Report View Ends */}

                            {image_link.sflink_filename ? (
                                <a target="_blank" rel="noreferrer" href={image_link.sflink_filename} className={`report-link vehicle-card card`}>
                                    <h1> {t('PACS')}</h1>
                                    {t('View PACS Image')}
                                </a>
                            ) : null}

                            {/* <a
            target="_blank"
            rel="noreferrer"
            href={'http://localhost:8030/patient/1'}
            className={`report-link vehicle-card card`}
        >
            <h1>PACS</h1>
            View PACS Image
        </a>

        <div></div>
    */}
                        </div>

                        {/* {mode === 'RAD' && tech.srvrs_resultptr ? (
        <Alert
            message="Radiologist Entry"
            description={`You are about to ${rad.srvrs_resultptr ? 'update' : 'leave'} the radiologist entry for this case`}
            type="info"
        />
    ) : null} */}

                        {/** Clinical information card start */}
                        <div className="clinical-information">
                            <ClinicalInformation mode={'opreg'} visit_id={urlParams.opb_id} enableModel={true} />
                        </div>

                        {/** Clinical information card End */}
                        {mode === 'RAD' && !tech.srvrs_resultptr ? (
                            <Alert
                                message={t('Radiologist Entry')}
                                description={t(`Radiology remarks can be updated only after technician entry is completed`)}
                                type="warning"
                            />
                        ) : null}

                        {/* {mode === 'TECH' && !rad.srvrs_resultptr ? (
        <Alert
            message="Technician Entry"
            description={`You are about to ${tech.srvrs_resultptr ? 'update' : 'leave'}  the technician entry for this case`}
            type="info"
        />
    ) : null} */}

                        {/* {mode === 'TECH' && rad.srvrs_resultptr ? (
        <Alert
            message="Technician Entry"
            description={`Technician remarks cannot be updated after radiologist has updated remarks`}
            type="warning"
        />
    ) : null} */}

                        {mode === 'ALL' ? (
                            <>
                                <Alert message={t('Notes')} description={`Please choose a tab for which you want to mark entry`} type="info" />
                            </>
                        ) : (
                            <div className="ptnt_dtls_form ">
                                <Form
                                    // {...layout}
                                    form={form}
                                    name="new-record"
                                    onFinish={onFinish}
                                    layout="vertical"
                                    initialValues={initial}
                                    onValuesChange={(fields) => {
                                        var fieldname = Object.keys(fields);

                                        // Below implementation is with respect to StopTB
                                        if (fieldname[0] === 'status') {
                                            var fieldValue = Object.values(fields);

                                            if (triggerBill && mode === 'TECH') {
                                                message.warning('The pateint must proceed for Laboratory Test');
                                            }
                                        }
                                    }}
                                >
                                    {/* <Title level={5}>Remarks</Title> */}
                                    <Row gutter={20}>
                                        <Col xs={24} md={12} xl={8}>
                                            <Form.Item
                                                name={'status'}
                                                label={t('Status')}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: t('Please enter status'),
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    // defaultValue="positive"
                                                    style={{ width: 220 }}
                                                    disabled={disableFields}
                                                    onChange={(i, values) => {
                                                        onChange(values);
                                                    }}
                                                >
                                                    {options.map((option) => (
                                                        <Option value={option.code}>{option.description}</Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                    <Form.Item
                                        name={'notes'}
                                        label={t('Notes')}
                                        rules={[
                                            {
                                                required: validation,
                                                message: t('Please enter notes'),
                                            },
                                            {
                                                max: 2000,
                                                message: t('Length cannot exceed 2000 characters!'),
                                            },
                                        ]}
                                    >
                                        <TextArea
                                            defaultValue={handleInsertExpertOpinion}
                                            autoSize={{ minRows: 4, maxRows: 10 }}
                                            disabled={disableFields}
                                        />
                                    </Form.Item>
                                    {disableDoctor ? null : (
                                        <Row>
                                            <Col xs={24} md={12} xl={8}>
                                                <Form.Item
                                                    name={'doctor'}
                                                    label={t('Doctor')}
                                                    rules={[
                                                        {
                                                            required: false,
                                                            message: t('Please enter doctor'),
                                                        },
                                                    ]}
                                                >
                                                    <ReferenceSelect
                                                        config={{
                                                            queries: [
                                                                {
                                                                    field: 'do_type',

                                                                    value: 'DROTR',
                                                                },
                                                                {
                                                                    field: 'do_active',

                                                                    operator: 'EQUALS',

                                                                    value: 'Y',
                                                                },
                                                            ],
                                                        }}
                                                        disabled={disableFields}
                                                        field="do_code"
                                                        label="do_name"
                                                        mode="nura-base"
                                                        model={Doctors}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    )}

                                    {/* Patient input  value  Componenet start */}
                                    {patient.input_values ? (
                                        <>
                                            <PatientInputValues
                                                dataSource={dataSource}
                                                disableFields={disableFields}
                                                callback={(ele, key, record) => {
                                                    onPatientInputValueChange(ele, key, record);
                                                }}
                                            />
                                            <Form.Item
                                                name={'remarks'}
                                                label={t('Remarks')}
                                                rules={[
                                                    {
                                                        required: false,
                                                        message: t('Please enter remarks'),
                                                    },
                                                    {
                                                        max: 250,
                                                        message: t('Length cannot exceed 250 characters!'),
                                                    },
                                                ]}
                                            >
                                                <TextArea autoSize={{ minRows: 4, maxRows: 10 }} disabled={disableFields} />
                                            </Form.Item>
                                        </>
                                    ) : null}

                                    {/* Patient input  value  Componenet End  */}
                                    <div className="form-actions">
                                        <Button
                                            loading={btnloading}
                                            disabled={disabled || billed || disableSubmit}
                                            type="primary"
                                            htmlType="submit"
                                            className="submit-button"
                                        >
                                            {t('Save')}
                                        </Button>
                                        <div className="section-2">
                                            {/* Close Button */}
                                            {!enableClose && enableResultClose ? ( // The button will be displayed if:enableClose is false AND enableResultClose is true (or not provided)
                                                // <div className='result-close'>
                                                <Form.Item name={'resultstatus'}>
                                                    <Checkbox onChange={onCheckboxCheck} checked={checkbox} disabled={disableFields}>
                                                        {t('Result Close')}
                                                    </Checkbox>
                                                </Form.Item>
                                            ) : // </div>
                                                null}
                                            {/* Close Button Ends */}

                                            {/* Attach Files */}
                                            {/* <div className='attach-scr-file'> */}
                                            <FileLinksButton
                                                config={reportConfig}
                                                patient={patient}
                                                item_ptr={urlParams.item_ptr}
                                                accessionno={urlParams.accessionno}
                                                mode={mode}
                                                callback={refresh}
                                                allowFileMappingButton={allowFileMappingButton}
                                            />
                                            {/* </div> */}
                                        </div>
                                        {/* Attach Files Ends */}

                                        {enableLabTrigger ? (
                                            <Form.Item name={'triggerbill'}>
                                                <Checkbox onChange={onCheckBillTrigger} disabled={disableFields}>
                                                    {t('Trigger Bill')}
                                                </Checkbox>
                                            </Form.Item>
                                        ) : null}
                                    </div>
                                </Form>

                                {/* Modal for Billing Lab if xray is positive */}

                                <Modal
                                    width={'45%'}
                                    destroyOnClose={true}
                                    title={t('Alert')}
                                    visible={modalVisible}
                                    okText="Okay"
                                    cancelButtonProps={{ style: { display: 'none' } }}
                                    onOk={() => {
                                        onFinish();

                                        setModalVisible(false);
                                        // Stop the counter
                                        stop();
                                        setCount(0);
                                    }}
                                >
                                    <img src={require(`../../../../assets/icons/external-danger-security-flaticons-flat-flat-icons.png`)} />{' '}
                                    <Progress
                                        type="circle"
                                        strokeColor={{
                                            '0%': '#108ee9',
                                            '100%': '#87d068',
                                        }}
                                        percent={count}
                                    />{' '}
                                    <p>{t('As the Results of XRAY is positive, we are proceeding for Laboratory Test Billing. Please Wait ')}</p>
                                    <div className="anime">
                                        <div className="images">
                                            {/* <img className="card-icon" src="https://img.icons8.com/color/48/undefined/ct-scanner.png" /> */}
                                            <img
                                                alt={""}
                                                className="specimen-icon" src={require(`../../../../assets/icons/radiology1.png`)} />
                                            <div className="arrow">
                                                <div class="arrow-container">
                                                    <div class="arrow-down"></div>
                                                </div>
                                            </div>

                                            <img
                                                alt={""}
                                                className="card-icon"
                                                src={require(`../../../../assets/icons/external-lab-back-to-school-flat-02-chattapat-.png`)}
                                            />
                                        </div>
                                    </div>
                                </Modal>

                                {/* Modal for Billing Lab if xray is positive */}
                            </div>
                        )}
                    </div>
                </div>
            )}
        </section>
    );
}

/**
 * Button checks for file in the location and create the file links record
 *
 * @author Jinisha
 * @author Marvan
 * @author Ashique
 *
 */
function FileLinksButton({ config, mode, accessionno, item_ptr, patient, callback, allowFileMappingButton }) {
    // Disable the button if does not match
    const [btndisable, setBtnDisable] = useState(true);

    // Loading Variable
    const [loading, setLoading] = useState(false);

    const [fileLinkmas, setFileLinkMas] = useState();

    const [filePaths, setFilePaths] = useState([]);

    const [otherDetails, setOtherDetails] = useState({});

    useEffect(() => {
        // Load the master
        getscrfileMaster();
    }, []);

    /**
     *
     *  Get scrfile link master .
     * and control the button caption based on otherdeatils condition
     *
     */
    async function getscrfileMaster() {
        // get call
        SCRFilesLink.getScrfileLinksMaster(item_ptr, accessionno).then((result) => {
            //
            let data = result.data;

            // formating data
            let itemOtherDetails = JSON.parse(data.otherDetails.otherdet);

            // In  Visceral Fat Obesity we have to filter otherdetails based on itemptr
            if (itemOtherDetails && itemOtherDetails.data) {
                let filter = itemOtherDetails.data.filter((ele) => ele.itemptr == item_ptr);
                setOtherDetails(filter[0]);
            } else {
                setOtherDetails(itemOtherDetails);
            }

            // add caption condition based on otherdeatils fields
            // allowFileMappingButton Attribute used to enable 'map to file path ' Button
            if (itemOtherDetails.mapitemdetails === 'Y' && allowFileMappingButton) {
                setFileLinkMas({ caption: 'Map to File Path' });
            } else if (itemOtherDetails.mapimageto_otherstudies === 'Y') {
                setFileLinkMas({ caption: 'Map Image to other Studies' });
            } else if (itemOtherDetails.mapitemdetails === 'N') {
                setBtnDisable(false);
            }
            // set values for check file
            let array = [];
            array = data.result.map((element) => {
                let values = {
                    ...element,
                    file_path: element.sflink_filename,
                    // other_details: filter[0],
                };
                return values;
            });
            // set array to state
            setFilePaths(array);
        });
    }

    /**
     * Attaching Scrfilelink
     *
     * @param {} accessionno
     *
     */
    async function attachingScrfilelink(urlParams) {
        setLoading(true);

        let result;
        let files;
        // console.log('filepath', filePaths);
        // The function is supposed to work in different ways according to the menu configuration
        // In case , the files are present locally , we have to check local file system
        // and then create records in the db

        // If the check_existance flag is true then we have to check the file exist or not
        if (otherDetails.check_existance) {
            result = await SCRFilesLink.checkLocalFileSystem({
                endpoint: config.baseUrl + 'files/check-if-file-exist?file=',
                // filePath: patient.expected_file_path,
                // endpoint: 'http://localhost:3800/' + 'files/check-if-file-exist',
                filePath: filePaths,
            });
        }

        setLoading(false);

        // if (result.success) {
        // If check_existance flag false then no need  check the file existance .Then we have to create record on scrfile links
        if (!otherDetails.check_existance) {
            files = filePaths;
        } else {
            if (result.success) {
                files = filePaths.filter((ele) => {
                    let filter = result.file_path.find((value) => ele.sflink_refno === value.sflink_refno);

                    return filter;
                });
            } else {
                message.error(result.message);
                return;
            }
        }

        // set formbody for attach scr files
        let formBody = {
            file_paths: files,
        };
        // Create the File Links record if the file is present
        const response = await SCRFilesLink.attachScrfilelink({
            formBody,
            // file_paths: files,
            // item_ptr: item_ptr,
            accessionno: accessionno,
        });

        if (response.success) {
            message.success(response.message);
        }

        callback();
        // } else {
        //     message.error(result.message);
        // }

        // console.log("here", urlParams);
        // SCRFilesLink.attachScrfilelink(urlParams).then(result => {
        //     if (result.success) {
        //         message.success(result.message);
        //         // return;
        //         refresh();
        //     } else {
        //         message.error(result.message);
        //     }
        // });
    }

    return (
        <>
            {mode === 'TECH' || btndisable ? (
                <Button
                    loading={loading}
                    size="middle"
                    disabled={!patient.button_enable}
                    type="primary"
                    onClick={() => {
                        attachingScrfilelink();
                    }}
                >
                    {/* Attach SCR Filelink */}
                    {fileLinkmas ? fileLinkmas.caption : null}
                </Button>
            ) : null}
            {/* Attach Files Ends */}
        </>
    );
}

/**
 * Component used to render the studies as received
 * from the dicom API
 *
 * The received studies are iterated to show a link that will open the dicom viewer
 *
 *
 */
function PatientStudies({ patient }) {
    return (
        <div className="patient-studies report-links">
            {patient.study_details.map((study) => {
                return (
                    <>
                        <LinkFrame url={`http://localhost:8030/patient/${study.id}/view`} />
                    </>
                );
            })}
        </div>
    );
}

/**
 *
 *
 * @param {*} param0
 * @returns
 */
function PatientInputValues({ callback, dataSource, disableFields }) {
    const [page, setPage] = useState(1);

    /**
     * headers for the table
     */
    var headers = Object.keys(dataSource[0]);
    /**
     *  columns
     */
    let columns;

    /**
     * to map fields to column header names
     */
    let columnLabels = {
        desc: 'Particulars',
    };

    /**
     * building table columns using headers
     */
    columns = headers.map((item) => {
        let data = {
            title: columnLabels[item] || item,
            render: (ele) => {
                /**
                 * input box is for columns other than 'code' and 'desc' headers
                 */
                if (item === 'code' || item === 'desc') {
                    return ele[item];
                } else {
                    return (
                        <Input
                            disabled={disableFields}
                            defaultValue={ele[item]}
                            onChange={(event) => {
                                callback(event.target.value, item, ele);
                            }}
                        ></Input>
                    );
                }
            },
        };
        return data;
    });
    /**
     * Adding new column for the indices
     */
    columns.splice(0, 0, {
        title: '#',
        dataIndex: 'index',

        render: (value, item, index) => index + 1,
    });

    return (
        <div className="patient-inputvalues">
            <div className="patient-values">
                <Table
                    dataSource={dataSource}
                    columns={columns}
                    pagination={{
                        current: page,
                        onChange(current) {
                            setPage(current);
                        },
                    }}
                />
            </div>
        </div>
    );
}

/**
 * Component would accept the url and onClick will open the url in an iframe in a modal
 *
 * The behaviour on how to display the content would be configurable
 */
function LinkFrame({ url }) {
    const [visible, setVisible] = useState(false);

    const { t, i18n } = useTranslation(); // To Translate to another language

    const openViewer = () => {
        setVisible(true);
    };

    return (
        <>
            <a
                target="_blank"
                rel="noreferrer"
                onClick={() => {
                    openViewer();
                }}
                // href={report_link.sflink_filename}
                className={`report-link vehicle-card card`}
            >
                <h1>{t('X-RAY')}</h1>
                View Image
            </a>

            <Modal
                className="image-viewer-modal"
                width={'100%'}
                destroyOnClose={true}
                title={t('Alert')}
                header={null}
                footer={null}
                visible={visible}
                okText="Okay"
                onCancel={() => {
                    setVisible(false);
                }}
                cancelButtonProps={{ style: { display: 'none' } }}
                onOk={() => {
                    setVisible(false);
                }}
            >
                <iframe src={url} />
                {/* <PdfViewer url={url}></PdfViewer> */}
            </Modal>
        </>
    );
}